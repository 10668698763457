import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware, setIndexes } from './MiddlewareActions';

import { reset } from 'redux-form';
import {
  setAddingNewManualAlertMode,
} from './AlertsActions';
import moment from 'moment';
import {getSamples, getSamplesAction} from "./actions";

export const ACTIONS = {
  REQUEST_TASKS_ACTION: 'REQUEST_TASKS_ACTION',
  RECEIVE_TASKS_ACTION: 'RECEIVE_TASKS_ACTION',
  SET_SELECTED_TASK: 'SET_SELECTED_TASK',
  SORT_MOBILE_TASKS_LOCALLY: 'SORT_MOBILE_TASKS_LOCALLY',
  SET_MOBILE_FILTERS: 'SET_MOBILE_FILTERS',
  FILTER_MOBILE_TASKS_LOCALLY: 'FILTER_MOBILE_TASKS_LOCALLY',
  CLEAR_MOBILE_FILTERS: 'CLEAR_MOBILE_FILTERS',
};

export const getTasks = (projectId) => {
  return (dispatch, getState) => {
    const url = endPoints.END_POINT + `/mobiletasks/${projectId}`;

    dispatch(getTasksDataAction());
    return fetchMiddleware(url, {}, getState).then((json) => {
      if (json.status) {
        const tasks = json.data;
        const indexMap = setIndexes(tasks, 'task_uuid');
        dispatch(getTasksDataAction(true, tasks, indexMap));
      }
    });
  };
};

export const getTasksDataAction = (
  isReceiveData = false,
  tasks = [],
  indexMap = {}
) => {
  return {
    type: isReceiveData
      ? ACTIONS.RECEIVE_TASKS_ACTION
      : ACTIONS.REQUEST_TASKS_ACTION,
    payload: { tasks, indexMap },
  };
};

export const setSelectedTasks = (taskId, isMulti) => (dispatch, getState) => {
  let newTasksState;
  if (!isMulti) {
    newTasksState = [taskId];
  } else {
    const state = getState();
    const selectedTasks = state.mobile.tasks.selectedTask;

    if (selectedTasks.includes(taskId)) {
      newTasksState = selectedTasks.filter((tId) => tId !== taskId);
    } else {
      newTasksState = [ taskId, ...selectedTasks ];
    }
  }

  dispatch({ type: ACTIONS.SET_SELECTED_TASK, payload: newTasksState });
  dispatch(getSamples(newTasksState));
}

export const setSelectedTask = (taskId, isSingle) => (dispatch, getState) => {
  const state = getState();
  const selectedTask = state.mobile.tasks.selectedTask;

  if (isSingle) {
    dispatch({ type: ACTIONS.SET_SELECTED_TASK, payload: [taskId] });
  } else {

  }

  if (Array.isArray(taskId) && taskId.length === 0) {
    dispatch({ type: ACTIONS.SET_SELECTED_TASK, payload: [] });
    dispatch(getSamplesAction(true));
    return;
  }
  const newState = !Array.isArray(taskId)
    ? selectedTask.includes(taskId)
      ? selectedTask.filter((t) => t !== taskId)
      : [taskId, ...selectedTask]
    : taskId;
  dispatch({ type: ACTIONS.SET_SELECTED_TASK, payload: newState });
  dispatch(getSamples(newState));
};

export function sortTasksLocally(field, dir) {
  return (dispatch, getState) => {
    const {
      mobile: {
        tasks: { items },
      },
    } = getState();
    const sortedItems = items.sort((a, b) => {
      let sortResult = 0;
      if (a[field] > b[field]) {
        sortResult = dir === 'asc' ? 1 : -1;
      } else if (a[field] < b[field]) {
        sortResult = dir === 'asc' ? -1 : 1;
      }
      return sortResult;
    });

    const newIndexMap = setIndexes(sortedItems, 'task_uuid');
    dispatch(getTasksDataAction(true, sortedItems, newIndexMap));
    dispatch({ type: ACTIONS.SORT_MOBILE_TASKS_LOCALLY, field, dir });
  };
}

export const filterTasks = (filters) => {
  return (dispatch, getState) => {
    if (filters.filters.length) {
      const filteredItems = getState().mobile.tasks.items.filter((item) => {
        let found = true;
        for (const filter of filters.filters) {
          if (filter.field === 'TaskName') {
            if (filter.value.map(str => str.toLowerCase()).includes(item.task_name.toLowerCase())) {
              found = true;
            } else {
              found = false;
              break;
            }
          } else if (filter.field === 'CreationDate') {
            const fromTs = moment(filter.from, 'YYYY-MM-DD').valueOf();
            const toTs = moment(filter.to, 'YYYY-MM-DD').valueOf() || (fromTs && moment().valueOf());

            // console.log(fromTs, toTs);
            if (!fromTs && !toTs) {
              found = found && true;
            } else {
              if (item.creation_date >= fromTs && item.creation_date <= toTs) {
                found = true;
              } else {
                found = false;
                break;
              }
            }
          }
        }
        return found;
      });

      const newIndexMap = setIndexes(filteredItems, 'task_uuid');
      const nonEmptyFilters = filters.filters.filter((filter) => {
        if (filter.field === 'CreationDate') {
          return filter.from || filter.to;
        } else {
          return filter.value.length;
        }
      });
      dispatch({
        type: ACTIONS.FILTER_MOBILE_TASKS_LOCALLY,
        payload: { items: filteredItems, indexMap: newIndexMap, filters: {
          defFilters: filters.defFilters,
          sort: filters.sort,
          filters: nonEmptyFilters,
        } },
      });
    } else {
      dispatch({
        type: ACTIONS.CLEAR_MOBILE_FILTERS,
        payload: {},
      });
    }
  };
};

export const createNewTask = (data, showAddTaskForm) => {
  return (dispatch, getState) => {
    const state = getState();
    const project = state.leaksList.selectedProject;
    const url = endPoints.END_POINT + `/mobiletasks/${project}`;
    return fetchMiddleware(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: data }),
      },
      getState
    ).then((json) => {
      if (json.status === false) {
      } else {
        // close the form:
        dispatch(setAddingNewManualAlertMode(false));
        // reset the form:
        dispatch(reset('add-mobile-task'));
        // reload tasks from server:
        dispatch(getTasks(project));
        showAddTaskForm(false);
      }
    });
  };
};

export const deleteTask = (taskId) => {
  return (dispatch, getState) => {
    const state = getState();
    const project = state.leaksList.selectedProject;
    const url = endPoints.END_POINT + `/mobiletasks/${taskId}`;
    return fetchMiddleware(
      url,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
      getState
    ).then((json) => {
      if (json.status === false) {
      } else {
        dispatch(getTasks(project));
      }
    });
  };
};

/* REDUCER */

const initialState = {
  items: [],
  filteredItems: [],
  indexMap: {},
  filteredIndexMap: {},
  selectedTask: [],
  mobileTasksSort: {
    field: 'CreationDate',
    dir: 'desc',
  },
  mobileTasksFilters: { defFilters: [], filters: [] },
  activeFilter: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.REQUEST_TASKS_ACTION:
    case ACTIONS.RECEIVE_TASKS_ACTION:
      return {
        ...state,
        items: action.payload.tasks,
        indexMap: action.payload.indexMap,
      };

    case ACTIONS.SET_SELECTED_TASK:
      return { ...state, selectedTask: action.payload };
    case ACTIONS.SORT_MOBILE_TASKS_LOCALLY:
      return {
        ...state,
        mobileTasksSort: {
          field: action.field,
          dir: action.dir,
        },
      };
    case ACTIONS.FILTER_MOBILE_TASKS_LOCALLY:
      return {
        ...state,
        mobileTasksFilters: { ...action.payload.filters },
        activeFilter: action.payload.filters.filters.length > 0,
        filteredItems: action.payload.items,
        filteredIndexMap: action.payload.indexMap,
      };
    case ACTIONS.CLEAR_MOBILE_FILTERS:
      return {
        ...state,
        mobileTasksFilters: { defFilters: [], filters: [] },
        activeFilter: false,
        filteredItems: [],
        filteredIndexMap: {},
      };
    default:
      return state;
  }
};

export default reducer;

import { connect } from 'react-redux';
import shpwrite from 'shp-write-update';
import * as actionsCouples from '../../actions/CouplesActions';
import { setIQ100BReportsModalVisibility } from '../../actions/MobileStateAction';
import { downloadPressureAlertsReport, downloadReport, downloadSensorsMaintenanceReport } from '../../actions/ReportsActions';
import * as sensorsActions from '../../actions/SensorsActions';
import TableActions from '../../components/TableActions/TableActions';

const mapStateToProps = (state, ownProps) => {
  const user = state.leaksList.user;
  const path = state.routing.locationBeforeTransitions.pathname.replace('/', '');
  return { ...ownProps, user, path }
  // return Object.assign({}, {user}, ownProps);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const type = ownProps.type;
  return {
    exportProject: (downloadType) => {
      if (type === 'pressure') {
        dispatch(downloadPressureAlertsReport());
      } else {
        return dispatch(downloadReport(type, false, downloadType));
      }
    },
    exportAll: (values, props) => {
      if (type === 'pressure') {
        dispatch(downloadPressureAlertsReport(true));
      } else {
        dispatch(downloadReport(type, true));
      }
    },
    exportAllHistory: () => {
      dispatch(downloadReport('projectHistory'));
    },
    exportSensorsMaintenance: () => {
      const callback = (error, haveData) => {
        if (error) {
          if (ownProps.notify) {
            ownProps.notify(error.message, 'error');
          }
        }
        else if (haveData) {
          ownProps.notify('Start downloading...', 'info');
        } else {
          ownProps.notify('No maintenance changes found', 'info');
        }
      }
      dispatch(downloadSensorsMaintenanceReport(callback))
    },
    exportPressureAlerts: (from, to) => {
      // console.log(`from: ${from} - to: ${to}`)
      // dispatch(downloadPressureAlertsReport(from, to));
    },
    loadSensorsActivityData: () => {
      // dispatch(sensorsActions.getSensorsActivityData());
      dispatch(sensorsActions.setSensorsActivityModalVisibility(true));
    },
    loadTechnicianData: () => {
      dispatch(sensorsActions.setSensorsTechnicianModalVisibility(true));
    },
    loadIQ100BReportsData: () => {
      // dispatch(sensorsActions.getSensorsActivityData());
      dispatch(setIQ100BReportsModalVisibility(true));
    },
    exportCouplesShapeFile: () => {
      dispatch(actionsCouples.getCouplesPathsGeoJson()).then((geoData) => {
        const projectName = geoData.project.ShortName;
        const options = (type) => ({
          folder: `aqs_${projectName.toLowerCase()}_shape_${type}`,
          file: `${geoData.project.ShortName}_${type}`,
          types: {
            point: 'aqs_points',
            polygon: 'aqs_polygons',
            polyline: 'aqs_lines'
          }
        });

        // shpwrite.download(geoData.geoJSON, options);
        shpwrite.download(geoData.geos.sops, options('installation_points'));
        shpwrite.download(geoData.geos.sensors, options('sensors'));
        shpwrite.download(geoData.geos.notInstalledSenosrs, options('not_installed_sensors'));
        shpwrite.download(geoData.geos.couples, options('couples'));
      });
    }
  };
};

const CTableActions = connect(
  mapStateToProps,
  mapDispatchToProps
)(TableActions);

export default CTableActions;

import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware, setIndexes } from './MiddlewareActions';
import { setAddingNewManualAlertMode } from './AlertsActions';
import { reset } from 'redux-form';
import { getTasks } from './MobileTasksActions';

const ACTIONS = {
  REQUEST_MOBILE_ALERTS: 'REQUEST_MOBILE_ALERTS',
  RECEIVE_MOBILE_ALERTS: 'RECEIVE_MOBILE_ALERTS',
  SELECT_MOBILE_ALERT: 'SELECT_MOBILE_ALERT',
};

export const getMobileAlerts = (projectId) => (dispatch, getState) => {
  const url = `${endPoints.END_POINT}/mobilealerts/${projectId}`;

  dispatch(requestAlertsAction());
  return fetchMiddleware(url, {}, getState).then((json) => {
    if (json.status) {
      const alerts = json.data;
      const indexMap = setIndexes(alerts, 'alert_id');
      dispatch(receiveAlertsAction(alerts, indexMap));
    }
  });
};

export const createNewMobileAlert = (taskId, data) => {
  return (dispatch, getState) => {
    const state = getState();
    const project = state.leaksList.selectedProject;
    const url = endPoints.END_POINT + `/mobilealerts/${taskId}`;
    return fetchMiddleware(
      url,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data }),
      },
      getState
    ).then((json) => {
      if (json.status === false) {
      } else {
        // reload tasks from server:
        dispatch(getMobileAlerts(project));
      }
    });
  };
};

export const requestAlertsAction = () => ({
  type: ACTIONS.REQUEST_MOBILE_ALERTS,
});

export const receiveAlertsAction = (alerts, indexMap) => ({
  type: ACTIONS.RECEIVE_MOBILE_ALERTS,
  payload: { alerts, indexMap },
});

export const setSelectedMobileAlert = (alert) => ({
  type: ACTIONS.SELECT_MOBILE_ALERT,
  payload: alert.alert_id,
});

/* REDUCER */

const initialState = {
  items: [],
  indexMap: {},
  selectedAlert: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.REQUEST_MOBILE_ALERTS:
      return {
        ...state,
        items: [],
        indexMap: {},
      };

    case ACTIONS.RECEIVE_MOBILE_ALERTS:
      return {
        ...state,
        items: action.payload.alerts,
        indexMap: action.payload.indexMap,
      };

    default:
      return state;
  }
};

export default reducer;

import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware } from './MiddlewareActions';

import { downloadFileFromURl } from "./selectors";
import { getDateForExcelExports } from './Utilities/dateUtils';

// import fetch from 'isomorphic-fetch';

//--------------------------------
// General
//--------------------------------

export function downloadReport(type, all, downloadType) {

  return (dispatch, getState) => {
    const state = getState();

    let path = endPoints.REPORTS_ENDPOINT + "/" + type;
    const { user } = state.user;

    let filters = [];
    let sort = {};
    switch (type) {
      case 'alerts':
        Object.assign(filters, state.leaksList.filters.filters);
        sort = state.leaksList.filters.sort;
        break;

      case 'sensors':
        Object.assign(filters, state.leaksList.filters.sensorsFilters);
        sort = state.leaksList.filters.sensorsSort;
        break;

      case 'couples':
        filters = [...state.leaksList.filters.couples.filters];
        sort = { ...state.leaksList.filters.couples.sort };
        break;

      case 'assessments':
        filters = [{
          field: "CoupleID",
          value: [state.leaksList.leaksByProject.selectedFeature],
        }];
        break;
      case 'history': {
        const workingState = state.leaksList.leaksByProject;
        const value = workingState.selectedFeatureType == 'couple' ? workingState.selectedAssessment : workingState.selectedFeature;
        filters = [{
          field: "LeakID",
          value: [value],
        }];
        break;
      }
      case 'projectHistory':
        Object.assign(filters, state.leaksList.filters.filters);
        break;
      case 'sops':
        Object.assign(filters, state.leaksList.filters.sops.filters);
        break;

      case 'interferences':
        Object.assign(filters, state.leaksList.filters.interferencesFilters);
        Object.assign(sort, state.leaksList.filters.interferencesSort);
        break;

      default:
        break;
    }

    if (!all) {
      const projectId = state.leaksList.selectedProject;
      const projectsList = state.leaksList.projectsList;
      const projectDetails = projectsList.items[projectsList.projectIndexMap[projectId]];
      const projectFilter = { field: 'project_id', value: [projectId], cityColumn: projectDetails.IsDisplayCityColumn };
      filters.push(projectFilter);
    }
    path += `/0?filters=${JSON.stringify(filters)}&sort=${JSON.stringify(sort)}${downloadType ? `&type=${downloadType}` : ''}&time=${getDateForExcelExports()}`;

    // const token = user.refreshToken;
    // let char = "&";
    // if (path.indexOf("?") === -1) {
    //     char = "?";
    // }
    // path += char + "token=" + token;
    // downloadFileFromURl(path);
    // window.location = path;

    if (downloadType === 'json') {
      return fetchMiddleware(path, {}, getState);
    } else {
      user.getIdToken().then((token) => {
        let char = "&";
        if (path.indexOf("?") === -1) {
          char = "?";
        }
        path += char + "token=" + token;
        downloadFileFromURl(path);
        // window.location = path;
      });
    }
  };
}

export const downloadSensorsReportFromPolygon = (sensors) => (dispatch, getState) => {
  const state = getState();
  const { user } = state.user;
  const filters = [...state.leaksList.filters.sensorsFilters];
  const sort = { ...state.leaksList.filters.sensorsSort };

  const projectId = state.leaksList.selectedProject;
  const projectsList = state.leaksList.projectsList;
  const projectDetails = projectsList.items[projectsList.projectIndexMap[projectId]];
  const projectFilter = { field: 'project_id', value: [projectId], cityColumn: projectDetails.IsDisplayCityColumn };
  filters.push(projectFilter);

  filters.push({ field: 'ID', op: 'list', value: sensors.map((sensor) => sensor.id) });

  user.getIdToken().then((token) => {
    const url = `${endPoints.REPORTS_ENDPOINT}/sensors/0?filters=${JSON.stringify(filters)}&sort=${JSON.stringify(sort)}&token=${token}`;
    // console.log(url);
    downloadFileFromURl(url, 'sensors_report.xlsx');
  });
}


//--------------------------------
// Alerts related Reports
//--------------------------------
export const downloadPressureAlertsReport = (allProject) => {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state.user;

    const from = state.leaksList.pressure.transientAlerts.datesRange.from;
    const to = state.leaksList.pressure.transientAlerts.datesRange.to;
    const projectId = state.leaksList.selectedProject;

    const urlCode = allProject ? 'prsTransientResultsReport' : 'prsTransientReport'

    let url = `${endPoints.PRESSURE_ENDPOINT}/${urlCode}/${projectId}?from=${from}&to=${to}`;

    // console.log(url);
    user.getIdToken().then((token) => {
      let char = "&";
      if (url.indexOf("?") === -1) {
        char = "?";
      }
      url += char + "token=" + token;
      downloadFileFromURl(url, 'report.xlsx');
      // window.location = url;
    });
  }
}



//--------------------------------
// Sensors related Reports
//--------------------------------
export const downloadSensorsMaintenanceReport = (callback) => {
  return (dispatch, getState) => {
    const state = getState();
    const { user } = state.user;

    const projectId = state.leaksList.selectedProject;

    let url = `${endPoints.END_POINT}/sensors/maintenance/${projectId}`;
    fetchMiddleware(`${url}?type=check`, {}, getState).then((json) => {
      if (!json.status) {
        // console.log(json);
        if (callback) {
          callback(json.data);
        }
      } else {
        if (!json.data.haveData) {
          callback(null, false);
        } else {
          callback(null, true);
          user.getIdToken().then((token) => {
            url = `${url}?type=download&token=${token}`;
            downloadFileFromURl(url);
          });
        }
      }
    });
  }
}


//--------------------------------
// Couples related Reports
//--------------------------------


//--------------------------------
// Reports Menu
//--------------------------------
export function fetchReport(type, projects) {

  return (dispatch, getState) => {
    const state = getState();
    let filters = [];
    switch (type) {
      case 'alerts':
        filters = [Object.assign({}, state.leaksList.filters.filters[0])];
        break;
    }
    if (projects && projects.length > 0) {
      filters.push({
        field: "ProjectID",
        op: "=",
        value: projects,
      });
    }
    const filtersStr = JSON.stringify(filters);
    const path = endPoints.END_POINT + "/type/test/report?filters=" + filtersStr;
    dispatch(requestReport(type, filters))
    return fetchMiddleware(path, {}, getState).then(json => {
      dispatch(receiveReport(type, filters, json.data));
    });
  }
}

function requestReport(reportType, filters) {
  return {
    type: types.REQUEST_REPORT,
    reportType,
    filters
  };
}

function receiveReport(reportType, filters, report) {
  return {
    type: types.RECEIVE_REPORT,
    reportType,
    filters,
    report
  };
}

export const fetchPipeAssessmentsReport = () => {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const path = endPoints.PIPE_ASSESSMENTS_ENDPOINT + '/report/' + projectId;

    dispatch(assessmentsReportRequest());
    return fetchMiddleware(path, {}, getState).then(json => {
      let data = [];
      if (json.status) {
        data = json.data;
      }
      return dispatch(assessmentsReportReceive(data));
    });
  }
}

const assessmentsReportRequest = () => {
  return {
    type: types.ASSESSMENTS_REPORT_REQUEST,
  }
}

const assessmentsReportReceive = (records) => {
  return {
    type: types.ASSESSMENTS_REPORT_RECEIVE,
    records,
  }
}

/* eslint-disable no-unused-vars */
import { browserHistory } from 'react-router';

import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { LAST_PROJECT } from '../constants/SettingsKeys';
import * as actionsAlerts from './AlertsActions';
import actionsAlertsValves from './AlertsValveActions';
import * as actionsAssessments from './AssessmentsActions';
import * as actionsCouples from './CouplesActions';
import * as actionsCoupleCoverage from './CouplesCoverageActions';
import * as actionsCustomer from './CustomersActions';
import * as actionsCustomers from "./CustomersActions";
import * as actionsDMAs from './DmasActions';
import * as actionsIQuarius from './IQuariusSamplesActions';
import * as actionsInspect from './InspectActions';
import * as actionsInstall from './InstallActions';
import * as actionsInterferences from './InterferencesActions';
import * as actionsUsers from './ManageActions';
import { fetchMiddleware, setIndexes } from './MiddlewareActions';
import * as actionsMobileAlerts from './MobileAlertsActions';
import * as actionsMobileDevices from './MobileDevicesActions';
import { fetchProjectAlgParams } from './MobileSamplesActions';
import * as actionsMobileTasks from './MobileTasksActions';
import * as actionsNoisesAlerts from './NoiseAlertsActions';
import * as actionsNoises from './NoiseSamplesActions';
import * as actionsPrsAlerts from './PrsTransientAlertsActions';
import * as actionsSOPs from './SOPsActions';
import * as actionsSensors from './SensorsActions';
import * as actionsWaterMeter from './WaterMeterActions';
import { selectFeature } from "./selectors";
import { clearManageItem, fetchCustomDetails, setEditMode } from "./setters";

// import fetch from 'isomorphic-fetch';

export const selectProject = (project, advanced = false) => (dispatch, getState) => {
  const state = getState();
  const user = state.leaksList.user;
  const path = state.routing.locationBeforeTransitions.pathname.replace("/", "") || 'alerts';
  const projectsListObj = state.leaksList.projectsList;
  const projectObj = projectsListObj.items[projectsListObj.projectIndexMap[project]];

  browserHistory.push({
    pathname: path,
    query: { project: project },
    state: { fromMap: true }, // flag for not clear the selected item on the reducer.
  });

  // dispatch(actionsSensors.setSensorsDefFilters(Object.assign({}, { sortBy: 'id', sortByDir: 'asc', SensorStatus: ['Ready', 'Active', 'Non Active'] } , filters.sensorsDefFilters)));
  dispatch(selectFeature(project, '', ''));
  dispatch(actionsCouples.setSortDetails('id', 'asc'));
  //dispatch(actionsSensors.sortSensors('id', 'asc'));
  dispatch(actionsAssessments.displayAssessments(false));
  dispatch(selectProjectAction(project));
  dispatch(actionsCustomers.loadUsersOfProject(project));
  dispatch(fetchProjectAlgParams(project));
  dispatch(actionsAlerts.fetchLeaksIfNeeded(project));
  dispatch(actionsAlerts.fetchLeaksIdsIfNeeded(project));
  dispatch(actionsNoisesAlerts.fetchNoiseAlerts(project));
  dispatch(actionsSensors.fetchSensorsIfNeeded(project));
  dispatch(actionsSOPs.fetchSOPsIfNeeded(project));
  dispatch(actionsNoises.fetchNoiseSamplesIfNeeded(project));
  dispatch(actionsIQuarius.fetchiQuariusSamplesIfNeeded(project));
  dispatch(actionsCouples.fetchCouplesIfNeeded(project)).then(() => {
    dispatch(actionsCoupleCoverage.FetchCouplesCoveragePathsIfNeeded());
  });
  // dispatch(actionsCoupleCoverage.FetchCouplesCoveragePathsIfNeeded());

  dispatch(actionsMobileTasks.getTasks(project));
  dispatch(actionsMobileAlerts.getMobileAlerts(project));

  dispatch(actionsUsers.fetchUsersIfNeeded(project));
  dispatch(actionsInstall.fetchConfigs());
  // dispatch(actionsiQaurius.getiQuariusCustomers());
  dispatch(actionsInterferences.fetchInterferences(project));
  // @TODO: Delete.
  dispatch(actionsCustomers.loadDistributionLists(projectObj.CustomerID));
  dispatch(actionsPrsAlerts.fetchPrsTransientAlerts());


  dispatch(actionsMobileTasks.setSelectedTask([]));

  dispatch(actionsAlertsValves.fetchValveAlerts(project));

  if (user.viewWaterMeter) {
    dispatch(actionsWaterMeter.fetchMeterAlertssIfNeeded(project));
  }
  if (path == 'inspect') {
    dispatch(actionsInspect.investigateAlerts(project, 'json', true));
    dispatch(actionsInspect.investigatePipes(project));
  }

  if (projectObj != null) {
    dispatch(actionsDMAs.fetchDMAs(projectObj.CustomerID));
    dispatch(actionsCustomer.loadDistributionListsForProject(project));
    dispatch(actionsMobileDevices.getDevices());
  }

  if (advanced) {
    let path = endPoints.PROJECTS_ENDPOINT + `/please?id=${project}`;
    dispatch(requestProjectAttributes());
    return fetchMiddleware(path, {}, getState).then((json) => {
      if (json.status) {
        const projectAttr = json.data[0];
        dispatch(receiveProjectAttributes(projectAttr));
      }
    });
  }
};

const requestProjectAttributes = () => ({
  type: types.REQUEST_PROJECT_ATTR,
});
const receiveProjectAttributes = (project) => ({
  type: types.RECEIVE_PROJECT_ATTR,
  project
});

export const selectProjectAction = (project) => {
  localStorage.setItem(LAST_PROJECT, project);

  return {
    type: types.SELECT_PROJECT,
    project
  };
};

function receiveProjects(json, projectIndexMap) {
  return {
    type: types.RECEIVE_PROJECTS,
    projects: json,
    projectIndexMap
  };
}

function receiveUserData(userName) {
  return {
    type: types.RECEIVE_USER_DATA,
    userName
  };
}

function requestProjects() {
  return {
    type: types.REQUEST_PROJECTS,
  };
}

function requestTotalPipeLength() {
  return {
    type: types.REQUEST_TOTAL_PIPES_LENGTH,
  };
}

function receiveTotalPipeLength(calculatedTotalLengthTable) {
  return {
    type: types.RECEIVE_TOTAL_PIPES_LENGTH,
    calculatedTotalLengthTable
  };
}

function fetchProjects() {

  return (dispatch, getState) => {
    const { routing: { locationBeforeTransitions: location } } = getState();
    let path = endPoints.PROJECTS_ENDPOINT + '/please';

    //dispatch(receiveLeaks(0, [], [], []));
    dispatch(requestProjects());
    return fetchMiddleware(path, {}, getState).then((json) => {
      // console.log(json.data.userData);
      const projects = json.data.rows;
      const userData = json.data.userData || {};
      dispatch(receiveUserData(userData.userName));

      boundingPolygonsForProjects(projects);
      let projectsList;
      if (process.env.REACT_APP_DEMO_PROJECTS) {  // exclude projects by ID
        const demoProjects = process.env.REACT_APP_DEMO_PROJECTS.split(',');
        projectsList = projects.filter((project) => demoProjects.includes(project.ID.toString()));
      } else {
        projectsList = projects;  // include all projects
      }

      dispatch(receiveProjects(projectsList, setIndexes(projectsList, 'ID')));
      dispatch(setEditMode(userData || {}));
      if (userData.customLogo) {
        dispatch(fetchCustomDetails());
      }

      // set project from path OR localStorage:
      let projectId = (location && location.query && location.query.project) || getState().leaksList.selectedProject;
      if (!projectId || !projectsList.some((project) => project.ID == projectId)) {
        if (projectsList && projectsList.length > 0) {
          projectId = projectsList[0].ID;

          // console.log(browserHistory);
          const pathParams = {
            pathname: 'alerts',
            query: { project: projectId },
            state: { fromMap: true }, // flag for not clear the selected item on the reducer.
          };
          browserHistory.push(pathParams);
        }
      }

      if (projectId) {
        dispatch(selectProject(projectId));
      }
    });
  };
}

function boundingPolygonsForProjects(projects) {
  if (projects != null) {
    for (var i = 0; i < projects.length; i++) {
      if (projects[i].Bounding != null) {

        const bounding = projects[i].Bounding;
        var numbers = bounding.substring('POLYGON (('.length, bounding.length - '))'.length).split(', ');
        var res = numbers.map((x) => x.split(' ').map(Number));

        projects[i].Bounding = res;
      }
    }
  }
}

function shouldFetchProjects(state) {
  const projects = state.leaksList.projects;
  if (!projects) {
    return true;
  }
  if (state.leaksList.isFetching) {
    return false;
  }
  return false;
}

export function fetchProjectsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchProjects(getState())) {
      return dispatch(fetchProjects());
    }
  };
}

export const viewProjectDetails = (projectId) => (dispatch, getState) => {
  const state = getState();
  const projectsList = state.leaksList.projectsList;
  const selectedProject = projectsList.items[projectsList.projectIndexMap[projectId]];
  dispatch(viewProject(selectedProject));
};

export const setProjectCenter = (project, center, cb) => (dispatch, getState) => {
  const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/center";
  return fetchMiddleware(path, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ point: center })
  }, getState).then((json) => {
    const state = getState();
    const projects = state.leaksList.projectsList;
    const index = projects.projectIndexMap[project];
    let newProject = Object.assign({}, projects.items[index]);
    newProject.RefPointLat = center[1];
    newProject.RefPointLng = center[0];
    dispatch(setProjectCenterRes(center, index));
    cb(json.status);
  });
};

const setProjectCenterRes = (center, index) => (
  {
    type: types.SET_PROJECT_CENTER_RES,
    center,
    index,
  }
);

const viewProject = (project) => (
  {
    type: types.VIEW_PROJECT,
    project,
  }
);

export const createProject = (values, cb) => (dispatch, getState) => {
  const path = endPoints.PROJECTS_ENDPOINT;
  return fetchMiddleware(path, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ data: values })
  }, getState).then((json) => {
    cb(json);
    if (json.status) {
      dispatch(fetchProjects());
    }
  });
};

export const deleteProject = (id, cb) => (dispatch, getState) => {
  const path = endPoints.PROJECTS_ENDPOINT + "/" + id + "/projects";
  return fetchMiddleware(path, {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }, getState).then((json) => {
    if (json.status) {
      dispatch(clearManageItem());
    }
    cb(json);
  });
};

export const updateProject = (project, values, cb) => (dispatch, getState) => {
  let state = getState();
  let projectPolygon = state.manage.polygon;

  const path = endPoints.PROJECTS_ENDPOINT + "/" + project;

  dispatch(updateProjectReq(project, values));
  return fetchMiddleware(path, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ data: values, Bounding: projectPolygon })
  }, getState).then((json) => {
    cb(json);
    dispatch(updateProjectRes(project, values, json));
    dispatch(fetchProjects()).then(() => {
      dispatch(selectProject(project, true));
      dispatch(viewProjectDetails(project));
    });
  });
};

const updateProjectReq = (project, values) => (
  {
    type: types.UPDATE_PROJECT_REQ,
    project,
    values,
  }
);

const updateProjectRes = (project, result) => ({
  type: types.UPDATE_PROJECT_RES,
  project,
  result,
});

export const UpdateIquariusPipes = (projectId, cb) => (dispatch, getState) => {
  const path = endPoints.PROJECTS_ENDPOINT + '/' + projectId + '/updateIquariusPipes';
  return fetchMiddleware(path, {}, getState).then((json) => {
    cb(json.status);
  });
};

export const updateProjectPipes = (projectId, cb) => (dispatch, getState) => {
  const path = endPoints.PROJECTS_ENDPOINT + '/' + projectId + '/updatePipes';
  return fetchMiddleware(path, {}, getState).then((json) => {
    cb(json.status);
  });
};

export const sortProjects = (field, dir) => (dispatch, getState) => {
  const state = getState();
  const projectsList = Object.assign([], state.leaksList.projectsList.items);
  projectsList.sort((a, b) => {
    let nRc = 0;

    const firstObj = (dir === 'asc') ? a : b;
    const secondObj = (dir === 'asc') ? b : a;

    if (firstObj[field] > secondObj[field] || secondObj[field] == null) nRc = 1;
    if (firstObj[field] < secondObj[field] || firstObj[field] == null) nRc = -1;

    return (nRc);
  });

  const newIndexMap = setIndexes(projectsList, 'ID');

  dispatch(sortLocaly(projectsList, newIndexMap, field, dir));
};

const sortLocaly = (projects, indexMap, field, dir) => (
  {
    type: types.SORT_PROJECTS_LOCALY,
    projects,
    indexMap,
    field,
    dir
  }
);

export const calcTotalPipesLength = (projectId) => (dispatch, getState) => {
  let path = endPoints.PROJECTS_ENDPOINT + '/calcPipesLength/' + projectId;

  dispatch(requestTotalPipeLength());
  return fetchMiddleware(path, {}, getState).then((json) => {

    if (json.status) {
      const state = getState();
      const pipeMaterialEnum = state.leaksList.optionList.options.PipeMaterialEnum;

      json.data.materialsArray.forEach((material) => {
        material.Name = pipeMaterialEnum.find((type) => type.value == material.MaterialType).label;
      });
    }

    dispatch(receiveTotalPipeLength(json.data));
  });
};

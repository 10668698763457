/* eslint-disable no-unused-vars */
import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware } from './MiddlewareActions';

export function fetchProjectDefaultConfiguration() {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const url = `${endPoints.END_POINT}/g5sensors/deviceconfs/default/${projectId}`;

    return fetchMiddleware(url, {}, getState).then((json) => json);
  };
}

export function fetchDevices() {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProjectId = state.leaksList.selectedProject;

    if (!selectedProjectId || selectedProjectId === '0') {
      return null;
    } else {
      const url = `${endPoints.END_POINT}/g5sensors/devices/${selectedProjectId}`;

      dispatch({ type: types.DEVICE_CONFIGURATION.REQUEST_DEVICE_LIST });

      return fetchMiddleware(url, {}, getState).then((json) => {
        if (!json.status) {
          // console.log(json);
        } else {
          const response = json.data;
          const { devices, thresholds } = response;

          devices.forEach((item) => {
            const radioGrades = [];
            item.bestRssi = 0;
            for (let i = 1; i <= 5; i++) {
              const radioData = {
                channel: item[`FMChannel${i}_10k`],
                rssi: item[`FMChannel${i}_RSSI_dBi`],
                snr: item[`FMChannel${i}_SNR_dBi`],
              };

              radioGrades.push(radioData);

              if (item.bestRssi === undefined || item.bestRssi < radioData.rssi) {
                item.bestChannel = radioData.channel;
                item.bestRssi = radioData.rssi;
                item.bestSnr = radioData.snr;
              }

              item.radioGrades = radioGrades;

              delete item[`FMChannel${i}_10k`];
              delete item[`FMChannel${i}_RSSI_dBi`];
              delete item[`FMChannel${i}_SNR_dBi`];

              if (item.BatteryLevel) item.BatteryLevelOK = (item.BatteryLevel > thresholds.pre_battery_threshold);
              if (item.BatteryLevelPost) item.BatteryLevelPostOK = (item.BatteryLevelPost > thresholds.post_battery_threhsold);
              if (item.RSRQ_db) item.RSRQ_dbOK = (item.RSRQ_db > thresholds.cellular_RSRQ_threshold);
              if (item.RSRP_dbm) item.RSRP_dbmOK = (item.RSRP_dbm > thresholds.cellular_RSRP_threhsold);
              if (item.bestRssi) item.bestRssiOK = (item.bestRssi > thresholds.fm_radio_RSSI_threshold);
              if (item.bestSnr) item.bestSnrOK = (item.bestSnr > thresholds.fm_radio_SNR_threshold);
              if (item.NoiseIntensityMinRMS) item.NoiseIntensityMinRmsNormalize = item.NoiseIntensityMinRMS * Math.pow(4, (4 - item.NoiseIntensityGain));

              if (item.SchedulerRefTime) {
                const decaMinutes = item.SchedulerRefTime % 6;
                const hour = (item.SchedulerRefTime - decaMinutes) / 6;
                const date = Date.UTC(0, 0, 0, hour, decaMinutes * 10);
                item.sampleTime = date;
              }
              // if (item.BatteryLevel) item.BatteryLevelOK = (item.BatteryLevel > thresholds.normalized_noise_threshold);

            }
          });
          dispatch(storeG5DevicesAction(devices));
        }
      }).catch((e) => {
        // console.log(e);
      });
    }
  };
}

export const storeG5DevicesAction = (devices) => ({
  type: types.DEVICE_CONFIGURATION.RECEIVE_DEVICE_LIST,
  payload: devices
});

export const sortG5Devices = (sortByField) => ({
  type: types.DEVICE_CONFIGURATION.SORT_G5_DEVICES,
  payload: sortByField
})

export function fetchFwVersions() {
  return (dispatch, getState) => {
    const url = `${endPoints.END_POINT}/g5sensors/conf/fwVersions`;
    return fetchMiddleware(url, {}, getState).then((json) => {
      dispatch({
        type: types.DEVICE_CONFIGURATION.RECEIVE_G5_VERSIONS,
        payload: json.data || []
      });
    });
  };
}
export function fetchFwModemVersions() {
  return (dispatch, getState) => {
    const url = `${endPoints.END_POINT}/g5sensors/conf/fwModemVersions`;
    return fetchMiddleware(url, {}, getState).then((json) => {
      // console.log(json);
      dispatch({
        type: types.DEVICE_CONFIGURATION.RECEIVE_G5_MODEM_VERSIONS,
        payload: json.data || []
      });
    });
  };
}

export function selectDevices(selection) {
  return {
    type: types.DEVICE_CONFIGURATION.SET_DEVICES_SELECTION,
    payload: selection
  };
}

export function setDeviceConfiguration(messageType, params) {
  return (_dispatch, getState) => {
    const state = getState();
    const { selection } = state.devcieConfigurations;
    const data = {
      devices: selection,
      values: params
    };

    const url = `${endPoints.END_POINT}/g5sensors/conf/${messageType}`;

    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    return fetchMiddleware(url, options, getState)
    .then((response) => {
      return (response);
    });
  };
}

export function setActionCommand(actionKey) {
  return (dispatch, getState) => {
    const state = getState();
    const devices = state.devcieConfigurations.selection;
    const url = `${endPoints.END_POINT}/g5sensors/action/${actionKey}`;
    const data = {
      devices
    };
    return fetchMiddleware(url, {
      method: 'POST',
      body: JSON.stringify(data)
    }, getState).then((json) => {
      // console.log(json);
    });
  };
}

export function setProjectDefaultConfiguration(type, params) {
  return (_dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const url = `${endPoints.END_POINT}/g5sensors/deviceconfs/default/${projectId}`;
    const data = {
      type, params
    }
    const options = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    return fetchMiddleware(url, options, getState).then((json) => { return json });
  };
}

export function getProjectConfigurationsLogs(page) {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const url = `${endPoints.END_POINT}/g5sensors/logs/${projectId}?page=${page}&rows=${250}`;
    dispatch({ type: types.DEVICE_CONFIGURATION.RECEIVE_G5_CONFIGURATION_LOGS, payload: [] });
    return fetchMiddleware(url, {}, getState).then((json) => {
      if (json.status) {
        dispatch({ type: types.DEVICE_CONFIGURATION.RECEIVE_G5_CONFIGURATION_LOGS, payload: json.data });
      }
    })
  }
}

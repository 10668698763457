import React from 'react';
import PropTypes from 'prop-types';

import AppModal from '../Modals/AppModal';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import GetAppIcon from '@mui/icons-material/GetApp';
import SendIcon from '@mui/icons-material/Send';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import CWorkOrderForm from '../../containers/CWorkOrderForm/CWorkOrderForm';

//import { WorkOrderForm } from 'components';
//import { CWorkOrderMap } from '../../containers/CMap/CMainMap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

//import Map from 'components/Map/Map';

// var $ = require('jquery');

require('./WorkOrderModal.scss');

export default class WorkOrderModal extends React.Component {

  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.workOrderPDF = this.workOrderPDF.bind(this);
    this.saveAndClose = this.saveAndClose.bind(this);
    this.printWorkOrder = this.printWorkOrder.bind(this);
    this.exportToPDF = this.exportToPDF.bind(this);
    this.animateEllipsis = this.animateEllipsis.bind(this);
    this.send = this.send.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setImgPng= this.setImgPng.bind(this);

    this.sendOptions = [
      { value: 'email', label: 'Alert E-Mail' },
      { value: 'work-order', label: 'Work Order E-Mail' },
      { value: 'sms', label: 'SMS' },
    ];

    this.state = {
      open: false,
      processing: false,
      selectedOption: undefined,
      imgPng: null,
      containMapToNotification: true,
      selectedDistributionList: undefined,
      hasMapError: false,
    };
  }

  componentWillUnmount() {
    clearInterval(this.processingInterval);
  }

  openModal() {
    this.setState({ open: true });
    this.props.getMapCapture().then((url) => {
      let image = new Image();
      image.crossOrigin = 'Anonymous';
      image.src = url;

      this.setState({
        imgPng: url,
        menuAnchorEl: null,

      });
    }).catch(err => {
      this.setState({ hasMapError: true });
    });
  }

  closeModal() {
    this.setState({
      open: false,
      imgPng: null
    });
  }
  setImgPng(imgPng) {
    this.setState({ imgPng });
  }
  workOrderPDF(output) {

    const self = this;
    self.setState({ processing: true });
    this.animateEllipsis();

    html2canvas(document.getElementById('work-order-body'), {
      useCORS: true,
      allowTaint: false,
      logging: true,
    }).then((canvas) => {

      // Test html2canvas output
      // var contentDataURL = canvas.toDataURL('image/jpeg');
      // window.open(contentDataURL, '_blank')

      let pdf = new jsPDF({
        orientation: 'landscape'
      });
      //pdf.addImage(require('../../../../images/system/' + SYSTEM_RESOURCE +'/logo_left.png'), 'PNG', 8, 8)
      pdf.addImage(canvas, 'JPEG', 8, 30)

      switch (output) {
        case 'export':
          // Local save to PDF
          pdf.save('work-order.pdf');
          break;
        case 'print':
          // Print PDF
          pdf.autoPrint();
          pdf.output('dataurlnewwindow');
          break;
        case 'save':
          // ToDo: save to
          break;
        default:
          pdf.output('dataurlnewwindow');
          self.setState({ open: false })
          break;
      }
      // See http://rawgit.com/MrRio/jsPDF/master/docs/jspdf.js.html#line981
      // for output types.

      self.setState({ processing: false });
      clearInterval(this.processingInterval);

    });
  }

  saveAndClose() {
    // ToDo
    this.workOrderPDF('save');
  }

  // sendToMail() {
  //   var mapNode = document.getElementById('workOrderMap');
  //   this.props.notifyByEmail(this.state.inputTextEMail, mapNode.src);
  // }

  // sendToSms() {
  //   this.props.notifyBySms(this.state.inputTextSMS);
  // }

  onIncludeMapChange = (event) => {
    this.setState({
      containMapToNotification: event.target.checked
    });
  }

  send() {
    const selectedListId = this.state.selectedDistributionList.value;
    let image;
    if (this.state.containMapToNotification) {
      image = this.state.imgPng;
    }

    this.props.sendNotification(selectedListId, image);
  }

  printWorkOrder() {
    this.workOrderPDF('print');
  }

  exportToPDF() {
    this.workOrderPDF('export');
  }

  animateEllipsis() {
    setTimeout(() => {
      const ellipsis = this.ellipsis;
      this.processingInterval = setInterval(function () {
        if (ellipsis.innerHTML.length > 3)
          ellipsis.innerHTML = "";
        else
          ellipsis.innerHTML += ".";
      }, 500);
    });
  }

  handleChange(selectedOption) {
    this.setState({
      selectedOption: selectedOption
    });
  }

  handleOpenMenu = (event) => {
    this.setState({
      menuAnchorEl: event.currentTarget
    });
  }

  handleMenuItemClick = (event, index) => {
    const selectedOption = this.props.distributionLists[index];
    this.setState({
      selectedDistributionList: selectedOption,
      menuAnchorEl: null
    });
  }

  handleCloseMenu = () => {
    this.setState({
      menuAnchorEl: null
    });
  }
  //<CWorkOrderMap showLayerManager={false} showLegend={false} showSearchBox={false} />

  render() {
    return (
      <div>
        <button
          className="btn btn-primary btn-order"
          onClick={this.openModal}
          type="button"
        >
          {this.context.t('mail_sms_notification')}
        </button>

        <AppModal
          closeIcon
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          title={this.context.t('alert_alertId', { alertId: this.props.alertId })}
          content={(
            <div id="work-order-body">
              <CWorkOrderForm
                hasMapError={this.state.hasMapError}
                imgSrc={this.state.imgPng}
                setImgPng={this.setImgPng}
              />
            </div>
          )}
          actions={(
            <div>
              <Button
                variant="contained"
                onClick={this.exportToPDF}
                endIcon={<GetAppIcon />}
                color="primary"
              >
               {this.context.t('export2')}
              </Button>

              <div style={{ float: 'left', display: 'flex' }}
                className="send-area">
                <div style={{ display: 'inherit' }}>
                  <label style={{ minInlineSize: 'max-content', margin: 'auto 7px', fontSize: 'larger' }}>
                    {this.context.t('distribution_list')}
                  </label>

                  <Button
                    variant="contained"
                    aria-controls="disribution-list-menu"
                    aria-haspopup="true"
                    onClick={this.handleOpenMenu}
                    endIcon={<ExpandMoreIcon />}
                  >
                    {this.state.selectedDistributionList === undefined ? this.context.t('select2') : this.state.selectedDistributionList.label}
                  </Button>
                  <Menu
                    id="disribution-list-menu"
                    anchorEl={this.state.menuAnchorEl}
                    keepMounted
                    open={Boolean(this.state.menuAnchorEl)}
                    onClose={this.handleCloseMenu}
                  >
                    { this.props.distributionLists
                    && this.props.distributionLists.length > 0
                    && this.props.distributionLists.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={this.state.selectedDistributionList && option.value === this.state.selectedDistributionList.value}
                        onClick={(event) => this.handleMenuItemClick(event, index)}
                      >
                        {option.label}
                      </MenuItem>
                    ))
                    }
                    { this.props.distributionLists
                    && this.props.distributionLists.length === 0
                    && (
                      <MenuItem
                        onClick={this.handleCloseMenu} >
                        {this.context.t('no_lists')}
                      </MenuItem>
                    )

                    }
                  </Menu>

                  <Button
                    disabled={!this.state.selectedDistributionList}
                    variant="contained"
                    color="primary"
                    onClick={this.send}
                    endIcon={<SendIcon />}
                  >
                    {this.context.t('send')}
                  </Button>
                  <label>
                    <Checkbox
                      checked={this.state.containMapToNotification}
                      onChange={this.onIncludeMapChange}
                      name="checkedIncludeMap"
                      color="primary"
                    />
                    {this.context.t('include_map')}
                  </label>
                </div>
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}

////@TODO write props types...
//WorkOrderModal.propTypes = {
//  handleRowClick: PropTypes.func.isRequired
//};

WorkOrderModal.contextTypes = {
  t: PropTypes.func.isRequired
};

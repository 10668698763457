import React from 'react';
import PropTypes from 'prop-types';
import UIDateFormater, { DateType } from '../../containers/UIhelper/UIDateFormater';
import Plot from 'react-plotly.js';

import '../SampleInfo/SampleInfo.scss'
import './mobile.scss';
import MobileSampleForm from './MobileSampleForm';
import { convertIntensityToDb } from '../../actions/MobileSamplesActions';
import { useSelector } from 'react-redux';
import AppModal from '../Modals/AppModal';
import ImagesTab from '../AlertsTabs/ImagesTab/ImagesTab';
import { Button } from '@mui/material';

const MAX_WAV_VALUE = 32768; // (2^16 / 2) = 32768

const MobileSampleDetailsStrip = ({ sample, local, selectMobileSample, downloadFile, notify }, context) => {
  const mobileSamples = useSelector((state) => state.mobile.samples);

  const mobileAlgParams = mobileSamples.algParams;
  const mobilePictures = mobileSamples.samplePictures;

  const [intensities, setIntensities] = React.useState([]);
  const [intensitiesHz, setIntensitiesHz] = React.useState([]);
  const [galleryOpen, setGalleryOpen] = React.useState(false);

  React.useEffect(() => {
    if (sample && sample.engine_nc3_big_bands_str) {
      const intensitiesVal = sample.engine_nc3_big_bands_str.split(',').map(Number);
      const intensitiesInHz = (!sample.audio_frequency)
      ? intensitiesVal.map((_val, index) => `${index + 1}`)
      : intensitiesVal.map((_val, index) => `${(index + 1) * ((sample.audio_frequency / 8 / 2) / 20)}`)

      setIntensities(intensitiesVal || []);
      setIntensitiesHz(intensitiesInHz);
    }
  }, [sample]);

  const closePanel = () => {
    selectMobileSample({});
  }

  const plotLayout = {
    autosize: false,
    width: 475,
    height: 225,
    margin: { l: 50, r: 10, b: 35, t: 30 },
    xaxis: {
      tickmode: 'array',
      tickvals: intensitiesHz,
      // title: {
      //   text: context.t('Hz'),
      //   font: {
      //     color: '#8d8d8d'
      //   }
      // }
    },
    yaxis: {
      title: {
        text: context.t('intensity_raw'),
        font: {
          color: '#8d8d8d'
        }
      }
    },
    xaxis: {
      title: {
        text: '[Hz]'
      }
    }
  };

  return (
    <div className='sample-info' style={{ opacity: 0.8 }}>
      {mobilePictures.length > 0 && <Button variant='contained' onClick={() => setGalleryOpen(true)}>{context.t('pictures')}</Button>}
      <AppModal
        closeIcon
        open={galleryOpen}
        onClose={() => setGalleryOpen(false)}
        content={
          <ImagesTab
            images={mobilePictures}
          />
        }
      />

      <i className="icon-close" onClick={closePanel} />
      <div className='mobile-sample-details'>
        <div className='sample-details-container'>
          <WavDisplay
            displayWavform
            bucket={sample.customer_s3_bucket}
            filePath={sample.s3_key}
            cleanFilePath={sample.s3_key_clean}
            downloadFile={downloadFile}
          />
          <MobileSampleForm sample={sample} notify={notify} />
        </div>

        <div>
          <div style={{ margin: 0, display: 'grid', gridTemplateColumns: 'auto auto' }}>
            <table>
              <tbody>
                <tr>
                  <th>{context.t('gain')}</th>
                  <td>{sample.gain}</td>
                </tr>
                <tr>
                  <th>{context.t('frequency')}</th>
                  <td>{sample.audio_frequency / 8} [Hz]</td>
                </tr>
                <tr>
                  <th>{context.t('duration')}</th>
                  <td>{sample.audio_duration} {context.t('sec')}</td>
                </tr>
                <tr>
                  <th>{context.t('user_name')}</th>
                  <td>{sample.user_name}</td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <th>{context.t('intensity')}</th>
                  <td>{convertIntensityToDb(sample.mobile_intensity, mobileAlgParams.noise_ref_delta_db, mobileAlgParams.engine_calibration)} [dB]</td>
                </tr>
                <tr>
                  <th>{context.t('low_intensity')}</th>
                  <td>{convertIntensityToDb(sample.engine_low_intensity, mobileAlgParams.noise_ref_delta_db, mobileAlgParams.engine_calibration)} [dB]</td>
                </tr>
                <tr>
                  <th>{context.t('quality')}</th>
                  <td>{sample.mobile_quality ? (Math.max(sample.mobile_quality, 0.2) * 100).toFixed() : ''}</td>
                </tr>
                {/* <tr>
                  <th>{context.t('Water Meter')}</th>
                  <td>{sample.engine_water_meter}</td>
                </tr> */}
              </tbody>
            </table>
          </div>
          <Plot
            data={[
              {
                type: 'bar',
                x: intensitiesHz,
                y: intensities,
              }
            ]}
            layout={plotLayout}
          />
        </div>
      </div>
    </div>
  )
}

const WavDisplay = ({ bucket, filePath, cleanFilePath, downloadFile, displayWavform }, context) => {

  const [audioUrl, setAudioUrl] = React.useState('');
  const [cleanAudioUrl, setCleanAudioUrl] = React.useState('');
  const [wavForm, setWavform] = React.useState([]);


  React.useEffect(() => {
    setWavform([]);

    const getSignedUrl = (bucket, key) => new Promise((resolve) => {
      downloadFile(bucket, key, (data) => resolve(data.data))
    });

    const getAudioUrls = async () => {
      setAudioUrl('');
      setCleanAudioUrl('');

      const audioUrl = await getSignedUrl(bucket, filePath);
      setAudioUrl(audioUrl);

      const cleanAudioUrl = await getSignedUrl(bucket, cleanFilePath);
      setCleanAudioUrl(cleanAudioUrl);
    }

    if (bucket && (filePath || cleanFilePath)) {
      getAudioUrls();
    }
  }, [bucket, filePath, cleanFilePath, setWavform]);

  React.useEffect(() => {
    const getWavBytes = (url) => new Promise((resolve, reject) => {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            return reject(response.statusText);
          } else {
            return response.arrayBuffer();
          }
        }).catch(() => { })
        .then((buffer) => {
          // console.log({ buffer });
          const data = Array.from(new Int16Array(buffer, 44));
          resolve(data);
        })
        .catch((error) => {
          // console.log('getWavBytes', error);
        })
    })

    if (displayWavform && audioUrl) {
      getWavBytes(audioUrl).then((audioData) => {
        setWavform(audioData);
      });
    }
  }, [audioUrl, displayWavform, setWavform]);

  return (
    <div className='sample-audio'>
      {displayWavform && <Plot
        data={[
          {
            x: wavForm.map((x, i) => i),
            y: wavForm.map((x) => x / MAX_WAV_VALUE),
            mode: 'lines',
            type: 'scatter',
            line: {
              width: 1
            }
          }
        ]}
        layout={
          {
            height: 125,
            width: 450,
            margin: { t: 0, b: 0, l: 0, r: 0 },
            yaxis: {
              range: [-0.1, 1.1]
            }
          }
        }
        config={{}}
      />}
      <div className='player'>
        <div><span>{context.t('raw')}</span><audio src={audioUrl} controls /></div>
        <div><span>{context.t('clean')}</span><audio src={cleanAudioUrl} controls /></div>
      </div>
    </div>
  )
}

WavDisplay.contextTypes = {
  t: PropTypes.func.isRequired,
};

MobileSampleDetailsStrip.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MobileSampleDetailsStrip;

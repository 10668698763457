import React from 'react';
import PropTypes from 'prop-types';

const MobileMenu = (props, context) => {

  //equal to (for example) const addNewInstallItem = props.addNewInstallItem
  return (
    <div className="manage-menu mobile">

      {/* Mobile Tasks button */}
      <button
        className="btn-menu mobile-tasks"
        title={context.t('survey')}
        onClick={() => {
          props.onChange('Tasks')
        }}
      ></button>

      {/* Mobile Alerts button */}
      <button
        className="btn-menu mobile-alerts"
        title={context.t('alerts')}
        onClick={() => {
          // console.log(props);
          props.onChange('Alerts')
        }}
      >
      </button>

      {/* Mobile devices */}
      <button
        className="btn-menu mobile-devices"
        title={context.t('devices')}
        onClick={() => {
          props.onChange('Devices')
        }}
      ></button>

    </div>
  );
}

MobileMenu.contextTypes = {
  t: PropTypes.func.isRequired
};

export default MobileMenu;

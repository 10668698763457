import { END_POINT } from "../constants/EndPoints";

import { fetchMiddleware, setIndexes } from "./MiddlewareActions";

const actionsTypes = {

  REQUEST_VALVE_ALERTS: 'REQUEST_VALVE_ALERTS',
  RECEIVE_VALVE_ALERTS: 'RECEIVE_VALVE_ALERTS',
  SELECT_VALVE_ALERT: 'SELECT_VALVE_ALERT',
  SELECT_VALVE_ALERT_SAMPLE: 'SELECT_VALVE_ALERT_SAMPLE',
  REQUEST_VALVE_ALERT_SENSOR_DATA: 'REQUEST_VALVE_ALERT_SENSOR_DATA',
  RECEIVE_VALVE_ALERT_SENSOR_DATA: 'RECEIVE_VALVE_ALERT_SENSOR_DATA',
  UPDATE_VALVE_ALERT_STATUS: 'UPDATE_VALVE_ALERT_STATUS',
  SET_VALVE_ALERTS_FILTERS: 'SET_VALVE_ALERTS_FILTERS',
  SET_VALVE_ALERTS_DEF_FILTERS: 'SET_VALVE_ALERTS_DEF_FILTERS',
  VALVE_ALERTS_SET_SORT_PROPS: 'VALVE_ALERTS_SET_SORT_PROPS',
  REQUEST_VALVE_ALERTS_HISTORY: 'REQUEST_VALVE_ALERTS_HISTORY',
  RECEIVE_VALVE_ALERTS_HISTORY: 'RECEIVE_VALVE_ALERTS_HISTORY',
};

export const initialState = {

  valveAlerts: [],
  alertsIndexMap: {},
  alertSensorData: [],
  selectedAlert: undefined,
  selectedAlertSample: undefined,
  filters: [{ field: 'alert_state', value: [1, 2, 3, 4] }],
  defFilters: { alert_state: [1, 2, 3, 4] },
  history: [],
};

export function setFilters(filters) {
  return {
    type: actionsTypes.SET_VALVE_ALERTS_FILTERS,
    filters,
  }
}

export function setDefFilters(filters) {
  return {
    type: actionsTypes.SET_VALVE_ALERTS_DEF_FILTERS,
    filters,
  }
}

const requestValveAlerts = (projectID) => ({
  type: actionsTypes.REQUEST_VALVE_ALERTS,
  project: projectID,
});
const receiveValveAlerts = (projectID, payload) => ({
  type: actionsTypes.RECEIVE_VALVE_ALERTS,
  project: projectID,
  payload,
});

const fetchAlertHistory = (sensorId) => {

  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const url = `${END_POINT}/valvealerts/history/${projectId}/${sensorId}`;
    dispatch(requestValveAlertHistory());
    return fetchMiddleware(url, {}, getState).then((json) => {
      if (json.status) {
        dispatch(receiveValveAlertHistory(json.data));
      }
    });
  };
}

const requestValveAlertHistory = () => {
  return { type: actionsTypes.REQUEST_VALVE_ALERTS_HISTORY };
}

const receiveValveAlertHistory = (history) => {
  return { type: actionsTypes.RECEIVE_VALVE_ALERTS_HISTORY, payload: history };
}



const slice = {

  actions: {

    fetchValveAlerts: (projectID) =>
      (dispatch, getState) => {
        const state = getState();
        const filters = state.valve.filters;
        // console.log('filters in fetchValveAlerts', filters);

        const url = `${END_POINT}/valvealerts/${projectID}`;

        const filtersQuery =
          filters.length > 0 ? `?filters=${JSON.stringify(filters)}` : '';
        dispatch(requestValveAlerts(projectID));
        return fetchMiddleware(url + filtersQuery, {}, getState).then((json) => {
          if (!json.status) {
            return false;
          } else {
            const valveAlertStatesOptions =
              state.leaksList.optionList.options.AlertStateNoise;
            const valveAlerts = json.data;
            if (valveAlertStatesOptions) {
              valveAlerts.forEach((valveAlert) => {
                const alertState = valveAlertStatesOptions.find(
                  (opt) => opt.value === valveAlert.alert_state
                );
                const name = alertState ? alertState.DisplayName : '--';
                valveAlert.AlertStateName = name;
              });
            }
            const indexMap = setIndexes(valveAlerts, 'ID');
            const payload = {
              items: valveAlerts,
              indexMap,
            };
            return dispatch(receiveValveAlerts(projectID, payload));
          }
        });
      },

    sortValveAlerts: (field, dir) => {
      return (dispatch, getState) => {
        const state = getState();
        const projectID = state.leaksList.selectedProject;
        const valveAlerts = state.valve.valveAlerts;

        const sortedItems = valveAlerts.sort((first, second) => {

          let sortResult = 0;

          if (first[field] > second[field]) {
            sortResult = dir === 'asc' ? 1 : -1;
          } else if (first[field] < second[field]) {
            sortResult = dir === 'asc' ? -1 : 1;
          }
          return sortResult;
        });
        const newIndexMap = setIndexes(sortedItems, 'id');
        const payload = {
          items: sortedItems,
          indexMap: newIndexMap,
        };

        dispatch(receiveValveAlerts(projectID, payload));
        dispatch(slice.actions.setSortDetails(field, dir));
      };
    },

    setSortDetails: (field, dir) => {
      return {
        type: actionsTypes.VALVE_ALERTS_SET_SORT_PROPS,
        payload: { field, dir },
      };
    },

    selectValveAlert: (alert) => (dispatch, getState) => {

      const state = getState();

      const projectId = state.leaksList.selectedProject;

      dispatch({ type: actionsTypes.SELECT_VALVE_ALERT, payload: alert });

      if (!alert) {

        return dispatch({ type: actionsTypes.SELECT_VALVE_ALERT_SAMPLE, payload: undefined });

      }

      dispatch({ type: actionsTypes.REQUEST_VALVE_ALERT_SENSOR_DATA });

      if (alert !== null) {
        console.log('alert', alert);

        dispatch(fetchAlertHistory(alert.sensor_id));
      }

      const url = `${END_POINT}/valvealerts/${projectId}/${alert.sensor_id}`;

      return fetchMiddleware(url, {}, getState).then((json) => {

        return dispatch({

          type: actionsTypes.RECEIVE_VALVE_ALERT_SENSOR_DATA,

          payload: json.status ? json.data : [],

        });

      });

    },

    updateValveAlert: (valveAlert) => (dispatch, getState) => {

      const state = getState();

      const projectId = state.leaksList.selectedProject;

      const valveAlertId = valveAlert.ID;
      const alertSensorId = valveAlert.sensor_id;
      console.log('alertSensorId', alertSensorId);


      const url = `${END_POINT}/valvealerts/${projectId}/${valveAlertId}`;

      return fetchMiddleware(

        url,

        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(valveAlert),
        }, getState).then((json) => {
          if (json.status) {
            dispatch
              ({
                type
                  : actionsTypes.UPDATE_VALVE_ALERT_STATUS,
                payload
                  : valveAlert,
              });

            dispatch(slice.actions.fetchValveAlerts(projectId)).then((data) => {
              if (data.payload && data.payload.indexMap) {
                const { items, indexMap } = data.payload;
                const vAlert = items[indexMap[valveAlert.ID]];
                if (vAlert) {
                  dispatch(slice.actions.selectValveAlert(vAlert));
                }
              }
            });

            dispatch(fetchAlertHistory(alertSensorId));
          }
        });

    },

    getSignedUrlForFiles: (sample, cb) => (dispatch, getState) => {

      const state = getState();

      const url = `${END_POINT}/valvealerts/sample/audio/${sample.id}`;

      return fetchMiddleware(url, {}, getState).then((json) => {

        cb(json.data);

      });

    },

    selectValveAlertSample: (sample) => {
      return { type: actionsTypes.SELECT_VALVE_ALERT_SAMPLE, payload: sample };
    },

  },

  reducer: (state = initialState, action) => {

    switch (action.type) {

      case actionsTypes.REQUEST_VALVE_ALERTS:

        return { ...state, valveAlerts: [] };

      case actionsTypes.RECEIVE_VALVE_ALERTS:

        return {

          ...state,

          valveAlerts: action.payload.items,

          alertsIndexMap: action.payload.indexMap,

        };

      case actionsTypes.SELECT_VALVE_ALERT:

        return { ...state, selectedAlert: action.payload };

      case actionsTypes.REQUEST_VALVE_ALERT_SENSOR_DATA:

        return { ...state, alertSensorData: [] };

      case actionsTypes.RECEIVE_VALVE_ALERT_SENSOR_DATA:

        return { ...state, alertSensorData: action.payload };

      case actionsTypes.SELECT_VALVE_ALERT_SAMPLE:

        return { ...state, selectedAlertSample: action.payload };

      case actionsTypes.UPDATE_VALVE_ALERT_STATUS:

        return { ...state, valveAlerts: action.payload };

      case actionsTypes.SET_VALVE_ALERTS_FILTERS:
        return { ...state, filters: action.filters };

      case actionsTypes.REQUEST_VALVE_ALERTS_HISTORY:
        return { ...state, history: [] };

      case actionsTypes.RECEIVE_VALVE_ALERTS_HISTORY:
        return { ...state, history: action.payload };

      case actionsTypes.VALVE_ALERTS_SET_SORT_PROPS:
        return {
          ...state,
          sortBy: {
            field: action.payload.field,
            dir: action.payload.dir
          }
        };

      default:

        return state;

    }

  },

};

export default slice.actions;

export { slice };


import * as dotenv from 'dotenv';

dotenv.config();

export const END_POINT = process.env.REACT_APP_API_URL; // END_POINTS[ENV];
export const MAPS_END_POINT = process.env.REACT_APP_MAP_URL; // MAPS_END_POINTS[ENV];
export const SYSTEM_RESOURCE = process.env.REACT_APP_SYS_NAME; // SYSTEM_RESOURCES[ENV];

export const INSTALL_ENDPOINT = END_POINT + '/install';

export const DISTRIBUTION_LISTS_ENDPOINT = END_POINT + '/dlist';
// @todo: get from yair
export const PROJECT_DISTRIBUTION_LISTS_ENDPOINT = END_POINT + '/project-distributions';
export const CUSTOMERS_ENDPOINT = END_POINT + '/customers';
export const PROJECTS_ENDPOINT = END_POINT + '/projects';
export const REPORTS_ENDPOINT = END_POINT + '/reports';
export const OPTIONS_ENDPOINT = END_POINT + '/options';
export const USERS_ENDPOINT = END_POINT + '/users';
export const ALERTS_ENDPOINT = END_POINT + '/alerts';
export const DMAS_ENDPOINT = END_POINT + '/dmas';
export const PIPE_ASSESSMENTS_ENDPOINT = END_POINT + '/pipeassessments';
export const PRESSURE_ENDPOINT = END_POINT + '/prs';
export const PRESSURE_TRANSIENT_ALERTS_ENDPOINT = END_POINT + '/prsTransAlerts';
export const INTERFERENCES_ENDPOINT = END_POINT + '/interferences';
export const IQUARIUS_ENDPOINT = END_POINT + '/iQuarius';
export const LEAKS_ENDPOINT = 'leaks';
export const DEVICES_ENDPOINT = 'devices';
export const SENSORS_ENDPOINT = 'sensors';
export const SOPS_ENDPOINT = 'sops';
export const NOISE_SAMPLES_ENDPOINT = 'noiseSamples';
export const IQUARIUS_SAMPLES_ENDPOINT = 'iQuariusSamples';
export const COUPLES_ENDPOINT = 'couples';
export const TENSORS_ENDPOINT = END_POINT + '/tensors';
export const COUPLES_COVERAGE_PATHS_ENDPOINT = 'couplesCoveragePaths';
export const PROJECT_BOUNDARIES_ENDPOINT = 'projectBoundaries';
export const SENSOR_PRS_ENDPOINT = END_POINT + '/prs';

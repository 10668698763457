import * as types from "../constants/ActionTypes";
import * as endPoints from "../constants/EndPoints";
import {fetchMiddleware} from "./MiddlewareActions";

export function selectFeature(
  selectedProject,
  selectedFeature,
  featureType,
  leak
) {
  return {
    type: types.SELECT_LEAK,
    selectedFeature,
    featureType,
    selectedProject,
    leak,
  };
}

export const downloadFileFromURl = (url, fileName) => {
  // script that download file from url:
  const tempElement = document.createElement('a');
  tempElement.href = url;
  tempElement.target = '_blank';
  tempElement.download = fileName || url.substring((url.lastIndexOf('/') + 1), url.lastIndexOf('?'));
  document.body.appendChild(tempElement);
  setTimeout(() => {
    tempElement.click();
    document.body.removeChild(tempElement);
  }, 100); // Adjust the delay as needed
}

export function sortSamples(field, dir) {
    return {
        type: types.SORT_SAMPLES,
        field,
        dir
    }
}

//Updated
export const fetchSensorDetailsBySOP = (sopID) => {
    return (dispatch, getState) => {
        const path = endPoints.INSTALL_ENDPOINT + "/sop/" + sopID;
        dispatch(requestSensorDetailsBySopID(sopID));
        return fetchMiddleware(path, {}, getState).then(json => {
            let details = null;
            if (json.status && json.data != null) {
                // const state = getState();
                details = json.data[0];
            }
            dispatch(receiveSensorDetailsBySopID(sopID, details));
        });
    }
}
const requestSensorDetailsBySopID = (sopID) => {
    return {
        type: types.REQUEST_SONSOR_ON_SOP_DETAILS,
        sopID,
    };
};
const receiveSensorDetailsBySopID = (sopID, data) => {
    return {
        type: types.RECEIVE_SONSOR_ON_SOP_DETAILS,
        sopID,
        data,
    };
};

export function fetchSuspiciousAlerts(project, mode) {
    var path = endPoints.ALERTS_ENDPOINT + "/suspicious/" + project;

    return (dispatch, getState) => {
        //@TODO: Check errors.
        return fetchMiddleware(path, {}, getState)
            .then((json) => {
                dispatch(receiveSuspiciousAlertsIds(project, mode, json.data));
            });
    }
}

const receiveSuspiciousAlertsIds = (project, mode, alertsIds = []) => {
    return {
        type: types.RECEIVE_SUSPICIOUS_ALERTS_IDS,
        project,
        mode,
        alertsIds
    }
}

import fetch from 'isomorphic-fetch';

export function fetchMiddleware(path, options, getState, retryNum = 0) {
  const { user } = getState().user;
  if (user == null) {
    return Promise.reject(new Error('user is not provided'));
  } else {
    // if (!user.isValid()) {
    //   AWS.config.credentials.refresh(false);
    // }
    // console.log('isValid', user.isValid());
    // const token = user.getIdToken().jwtToken;
    return user.getIdToken().then((token) => {
      if (retryNum === 0) {
        if (options.headers == null) {
          options.headers = {
            token: token,
          };
        } else {
          options.headers.token = token;
        }

        options.headers['x-do-compression'] = true;
      }

      // options.headers['Access-Control-Allow-Origin'] = '*';

      return fetch(path, options).then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 403) {
          // Forbidden
          return {
            status: false,
            err: {
              name: 'Forbidden',
              code: 403,
              message: 'You are not authorized to perform the requsted operation.',
            },
            data: [],
          };
        }
        else {
          if (retryNum < 3) {
            return fetchMiddleware(path, options, getState, retryNum + 1);
          }
          else {
            return { status: false, err: 'err', data: [] };
          }
        }
      }).catch((err) => {
        if (retryNum < 3) {
          return fetchMiddleware(path, options, getState, retryNum + 1);
        }
        else {
          // console.log(err);
          return { status: false, err: err, data: [] };
        }
      });
    });
  }
}

//
export function getIconFeatures(type, items, statuses) {
  let iconFeatures = {};
  if (!items) {
    return (iconFeatures);
  }

  items.forEach((item) => {
    switch (type) {
      case 'assessments':
      case 'leaks': {
        const featureType = (type == 'leaks') ? 'alert' : 'assessment';
        iconFeatures[item.id] = {
          type: featureType,
          id: item.id,
          intensity: item.Intensity,
          position: [item.FixLongitude || item.Longitude, item.FixLatitude || item.Latitude],
          priority: item.Priority ? item.Priority.toLowerCase() : 'c',
          state: [5, 6].includes(item.AlertState) ? 'closed' : 'open',
          stateNum: item.AlertState,
          alertTypeNum: item.AlertType,
          isManual: item.ManualLeakProjectID > 0,
          LeakBoss: item.LeakBoss,
          closureDateMillis: item.ClosureDateTime,
          fixDate: item.fixDate,
          comment: item.Comment,
          detectedAt: item.DetectedAt,
          probability: item.Probability,
          noiseAlert: item.noise_alert,
          BossLatitude: item.BossLatitude,
          BossLongitude: item.BossLongitude,
        };
        break;
      }
      case 'sensors':
        iconFeatures[item.id] = {
          type: 'sensor',
          id: item.id,
          deviceID: item.DeviceID,
          deviceGenerationValue: item.DeviceGenerationValue,
          position: [item.Longitude, item.Latitude],
          sopState: item.SopState,
          status: item.SensorStatus,
          isUnderGround: item.isUnderground ? 'under' : 'above',
          sensorType: item.SensorType == 'Accelerometer' ? 'accmtr' : 'hdrpn',
          syncType: item.SyncMethod == 'Radio' ? 'radio' : 'gps',
          assetID: item.AssetID,
          address: item.StreetAddress,
          deviceInstallationDate: item.DeviceInstallationDate,
          deviceType: item.DeviceType,
          sampleTime1: item.SampleTime1,
          sampleTime2: item.SampleTime2,
          sampleTime3: item.SampleTime3,
          radioFrequency: item.RadioFrequency,
          regularNoise: item.regularNoiseValue,
          isPressure: item.IsPressure,
          generationType: item.DeviceGenerationValue,
          resetResently: item.resetResently,
          bitResetCount: item.BITResetsCount,
          bitResetDate: item.BITResetDate,
          autoResetCount: item.AutoResetCount,
          autoResetDate: item.AutoResetDate,
          fragmented: item.Fragmented,
          lastUninstallReason: item.LastUninstallReason,
          isMini: item.isMini,
          isValveMonitor: item.isValveMonitor
        };
        break;

      case 'sops': {
        let tStatusMeaning;
        for (let i = 0; i < statuses.length; i++) {
          if (statuses[i].value == item.State) {
            tStatusMeaning = statuses[i].label;
          }
        }
        iconFeatures[item.id] = {
          id: item.id,
          pointID: item.PointID,
          position: [item.Longitude, item.Latitude],
          avizar: item.AvizarCode,
          State: item.State,
          StateMeaning: tStatusMeaning,
          type: item.Type,
          isUnderGround: item.isUnderGround ? 'under' : 'above',
          address: item.StreetAddress,
          meaning: item.Meaning,
          stolen: item.Stolen,
          assetID: item.AssetID,
          comment: item.Comment,
          model: item.Model,
        };
        break;
      }

      case 'interferences':
        iconFeatures[item.id] = {
          typeName: item.typeName,
          id: item.id,
          Type: item.typeName,
          StartTime: item.StartTime,
          StartFrequency: item.StartFrequency,
          SopID: item.SopID,
          EndTime: item.EndTime,
          EndFrequency: item.EndFrequency,
          Comment: item.Comment,
          Boundary: item.Boundary,
          GeometryType: item.GeometryType,
          is_fill: item.is_fill,
          // IMPLEMENT HERE
        };
        break;

      case 'noiseSamples':
        iconFeatures[item.id] = {
          type: 'noiseSamples',
          deviceID: item.DeviceID,
          sensorID: item.id,
          position: [item.Longitude, item.Latitude],
          Noise: item.Noise,
          Nc1: item.Nc1,
          Nc3: item.Nc3,
          colorValue: item.colorValue,
          colorNc1: item.colorNc1,
          colorNc3: item.colorNc3,
          NoiseAlertID: item.AlertID,
          NoiseAlertDate: item.AlertCreateDate,
        };
        break;
      case 'iQuariusSamples':
        iconFeatures[item.ID] = {
          type: 'iQuariusSamples',
          id: item.ID,
          taskName: item.TaskName,
          sampleTime: item.SampleTime,
          position: [item.Longitude, item.Latitude],
          userName1: item.UserName1,
          userName2: item.UserName2,
          GPSQuality: item.GPSQuality,
          pipeType: item.PipeType,
          comments: item.Comments,
          intensity: item.Intensity,
          quality: item.Quality,
          probability: item.Probability,
          customerMobileName: item.CustomerMobileName,
        };
        break;
      case 'waterMeterAlerts':
        iconFeatures[item.Id] = {
          type: 'waterMeterAlerts',
          id: item.Id,
          position: [item.Lng, item.Lat],
          status: item.Status,
          customer: item.IdofCustomer,
          consumption: item.Consumption,
          sampleTime: item.ReadDate,
          consumptionDate: item.ConsumptionDate,
        };
        break;
      default:
        break;
    }
  });
  return iconFeatures;
}

export function setIndexes(arr, idField) {
  var indexMap = {};
  if (arr != null) {
    arr.forEach((item, index) => {
      indexMap[item[idField]] = index;
    });
  }
  return indexMap;
}

import _ from 'lodash';
import { connect } from 'react-redux';

import { NOISE_ALERTS_COUNT } from '../../constants/Misc';

import * as actionsDevices from '../../actions/DevicesActions';
import * as actionsFiles from '../../actions/FilesActions';
import { copyComment, editComment } from '../../actions/GlobalsActions';
import * as actionsMobileSamples from '../../actions/MobileSamplesActions';
import * as actionsNoiseAlerts from '../../actions/NoiseAlertsActions';
import * as actionsPressure from '../../actions/PrsTransientAlertsActions';
import * as actionsSamples from '../../actions/SamplesActions';
import * as actionsSensors from '../../actions/SensorsActions';

import TabsSwitch from '../../components/TabsSwitch/TabsSwitch';

const mapStateToProps = (state, ownProps) => {
  const { path } = ownProps;

  let tabProps;
  const { filters, user } = state.leaksList;
  const { selectedProject, selectedFeature } = ownProps;
  const { units, factor, timeZone } = state.local;
  const distanceFactor = factor[units];
  const alertsContextMenu = state.leaksList.pressure.context;
  const options = state.leaksList.optionList.options;
  const distributionListsCustomers = process.env.REACT_APP_DISTRIBUTION_LISTS_CUSTOMERS === 'true';
  const distributionLists = distributionListsCustomers ? state.distributionLists.lists : state.distributionLists.projectsDLists || [];
  const distributionListsOptions = distributionLists.map((listItem) => ({
    label: distributionListsCustomers ? listItem.Name : listItem.name,
    value: distributionListsCustomers ? listItem.ID : listItem.id,
  }));

  const generalProps = {
    user,
    filters,
    selectedProject,
    selectedFeature,
    units,
    factor,
    timeZone,
    distanceFactor,
    alertsContextMenu,
  };

  const projectData = state.leaksList.leaksByProject[selectedProject] || {};

  switch (path) {
    case 'alerts': {
      const alertsContextMenu = state.leaksList.pressure.context;
      const selectedSample = state.samples.selectedSample;

      switch (alertsContextMenu) {
        case 'alerts': {
          const { history, files, images, samples, leakGrowth, items } =
            projectData;
          const { AlertType, AlertState } = options;

          let details = {};
          let alertItem = {};
          if (selectedFeature) {
            alertItem = items[projectData.indexMap[selectedFeature]];
            if (projectData.details[selectedFeature]) {
              details = projectData.details[selectedFeature].data;
            }
          }
          let samplesPage = 0;
          if (samples && samples[selectedFeature]) {
            samplesPage = samples[selectedFeature].pageIndex;
          }
          tabProps = {
            units,
            distanceFactor,
            details,
            timeZone,
            alertItem,
            history,
            files,
            images,
            samples,
            selectedSample,
            samplesPage,
            filters,
            alertStatesOpitons: AlertState,
            alertTypesOpitons: AlertType,
            algParams: options.AlgParams,
            leakGrowth,
          };
          break;
        }
        case 'prsAlerts': {
          const alertsData = state.leaksList.pressure.transientAlerts;
          const selectedFeatureDetails =
            alertsData.alerts[alertsData.indexMap[selectedFeature]] || {};
          const alertSamples = alertsData.alertSamples;
          const selectedSample = alertsData.selectedAlertSample;
          tabProps = {
            selectedFeature,
            selectedFeatureDetails,
            alertSamples,
            selectedSample,
          };
          break;
        }
        case 'noise': {
          const { AlertStateNoise } = options;
          const details = projectData.sensors.details.details || [];
          generalProps.selectedFeature = state.noiseAlerts.selected.ID;
          tabProps = {
            items: state.noiseAlerts.items,
            selectedItem: state.noiseAlerts.selected,
            alertStateOptions: options.AlertStateNoise,
            sensorNoiseSamples: details,
            noiseAlertHistory: state.noiseAlerts.history,
            noiseAlertStatusOptions: AlertStateNoise,
            distributionListsOptions: distributionListsOptions,
          };
          break;
        }

        case 'valve': {
          // let options = state.valve.valveAlerts.alert_state
          const { AlertStateNoise } = options;
          tabProps = {
            items: state.valve.valveAlerts,
            selectedItem: state.valve.selectedAlert,
            // alertStateOptions: options,
            alertStateOptions: AlertStateNoise,
            valveAlertsSamples: state.valve.alertSensorData,
            selectedAlertSample: state.valve.selectedAlertSample,
            valveAlertHistory: state.valve.history,
            // valveAlertStatusOptions: AlertStateNoise,
          };
          break;
        }
        default:
          break;
      }
      break;
    }
    case 'sensors': {
      const { sensors: sensorsBundle } = projectData;
      if (!sensorsBundle) {
        break;
      }
      const {
        bits,
        details,
        g5Details,
        g5NoiseMsgs,
        g5SelectedNoiseMsg,
        history = [],
        indexMap,
        logs,
        sensors,
        totalBitsCount,
        totalLogsCount,
      } = sensorsBundle;
      const sensorItem = sensors[indexMap[selectedFeature]];

      const noiseAlertCount =
        sensorsBundle && sensorsBundle.noiseAlertCount
          ? sensorsBundle.noiseAlertCount
          : NOISE_ALERTS_COUNT;

      tabProps = {
        sensorsHistory: history,
        sensorsLogs: logs,
        sensorsLogsCount: totalLogsCount,
        sensorsBits: bits,
        sensorsBitsCount: totalBitsCount,
        sensorNoiseSamples: details.details || {},
        noiseSamplesRequestedCount: noiseAlertCount,
        sensorItem,
        g5Details,
        g5NoiseMsgs,
        g5SelectedNoiseMsg,
      };
      break;
    }
    case 'couples': {
      const { AlertType, AlertState } = options;
      const selectedSample = state.samples.selectedSample;
      const couplesState = projectData.couples;
      let coupleItem = {};

      if (selectedFeature && !_.isEmpty(couplesState)) {
        coupleItem =
          couplesState.couples[couplesState.indexMap[selectedFeature]];
      }

      tabProps = {
        alertStatesOpitons: AlertState,
        alertTypesOpitons: AlertType,
        selectedSample: selectedSample,
        algParams: options.AlgParams,
        coupleItem: coupleItem || {},
      };
      break;
    }
    case 'install': {
      const installContext = state.install.installContext;
      switch (installContext) {
        case 'Sensors': {
          const { sensors: sensorsBundle } = projectData;
          const {
            history = [],
            logs,
            totalLogsCount,
            bits,
            totalBitsCount,
            details,
            sensors,
            indexMap,
            g5Details,
            g5BitMsgs,
            g5BitSampleMsgs,
            g5SelectedBitMsg,
            g5SelectedBitSampleMsg,
          } = sensorsBundle;
          const sensorItem = sensors[indexMap[selectedFeature]];

          const noiseAlertCount =
            sensorsBundle && sensorsBundle.noiseAlertCount
              ? sensorsBundle.noiseAlertCount
              : NOISE_ALERTS_COUNT;

          tabProps = {
            sensorsHistory: history,
            sensorsLogs: logs,
            sensorsLogsCount: totalLogsCount,
            sensorsBits: bits,
            sensorsBitsCount: totalBitsCount,
            sensorNoiseSamples: details.details || {},
            noiseSamplesRequestedCount: noiseAlertCount,
            sensorItem,
            g5Details,
            g5BitMsgs,
            g5BitSampleMsgs,
            g5SelectedBitMsg,
            g5SelectedBitSampleMsg,
          };
          break;
        }
      }
      break;
    }
    case 'mobile': {
      const {
        AqsCode,
        SampleState,
        AudioClassificationEnum,
        AlertStateNoise,
        AlertType,
      } = options;
      tabProps = {
        modeState: state.mobile.modeState.mode,
        samples: state.mobile.samples.items,
        samplesIndexMap: state.mobile.samples.indexMap,
        selectedSample: state.mobile.samples.selectedSampleId,
        options: {
          AqsCode,
          SampleState,
          AudioClassificationEnum,
          AlertStateNoise,
          AlertType,
        },
        checkedSamples: state.mobile.samples.checkedSamples,
        selectedDeviceId: state.mobile.devices.selectedDeviceId,
        selectedDeviceBist: state.mobile.devices.selectedDeviceBist,
        displayItems: state.mobile.samples.displayItems,
      };
      break;
    }
    default:
      break;
  }

  return {
    ...ownProps, ...generalProps, ...tabProps, history: projectData.history
  }

  // return Object.assign(generalProps, ownProps, tabProps, {
  //   history: projectData.history,
  // }); // TODO: remove 'history' when complete the switch.
};

const mapDispatchToProps = (dispatch, ownProps) => {
  let tabProps;
  const { path, selectedProject, selectedFeature } = ownProps;

  function onEdit(formName, fieldName) {
    dispatch(editComment(formName, fieldName));
    return true;
  }
  function onCopy(formName, fieldName) {
    dispatch(copyComment(formName, fieldName));
    return true;
  }

  switch (path) {
    default:
    case 'alerts':
      switch (ownProps.alertsContextMenu) {
        case 'noise':
          tabProps = {
            updateNoiseAlert: (values, dispatch, props) => {
              // console.log('UPDATE NOISE ALERT', values);
              const valuesItem = {
                ID: values.ID,
                Comment: values.Comment,
                CreateDate: values.CreateDate,
              };

              dispatch(actionsNoiseAlerts.updateNoiseAlert(valuesItem));
            },
            onEdit,
            onCopy,
            sendNotification: (distributionListId, image) => {
              dispatch(actionsNoiseAlerts.notify(distributionListId, image, (err) => {
                if (err) {
                  // console.log(err);
                } else {
                  const level = 'success';
                  const subject = (distributionListId == 'sms' ? 'SMS' : 'EMail');
                  const message = `${subject} sent seccessfully`;
                  ownProps.notify(message, level);
                }
              }));
            },
            getMapCapture: () => ownProps.getMapCapture()
          };

          break;

        default:
          tabProps = {
            loadFiles: () => {
              dispatch(
                actionsFiles.fetchFilesIfNeeded(
                  selectedProject,
                  selectedFeature,
                  true
                )
              );
            },
            uploadFile: (file, cb) => {
              // const fileName = file.name.replace(/[^\w\d_\-.]+/ig, '_');
              // const fileName = encodeURI(file.name);

              dispatch(
                actionsFiles.getUrlForDownloadFile(
                  selectedProject,
                  file.name,
                  'upload',
                  selectedFeature,
                  cb
                )
              );
            },
            uploadImage: (image, cb) => {
              // console.log('upload');
              dispatch(
                actionsFiles.getUrlForDownloadFile(
                  selectedProject,
                  image.name,
                  'image',
                  selectedFeature,
                  cb
                )
              );
            },
            deleteFile: (file) => {
              dispatch(actionsFiles.deleteFile(file));
            },
            selectSample: (sample) => {
              dispatch(
                actionsSamples.selectSample(
                  selectedProject,
                  selectedFeature,
                  sample
                )
              );
            },
            getMapCapture: () => {
              return ownProps.getMapCapture();
            },
            exportSamples: (alert) => {
              dispatch(
                actionsPressure.fetchPrsTransientAlertResults(alert, true)
              );
            },
            selectTransientAlertSamples: (sample) => {
              dispatch(actionsPressure.selectTransientAlertSample(sample));
            },
          };
          break;
      }
      break;

    case 'sensors':
      tabProps = {
        selectSensorSample: (selectedSensorSample) => {
          dispatch(
            actionsSensors.selectSensorSample(
              selectedProject,
              selectedSensorSample
            )
          );
        },
        selectSensorLog: (selectedSensorLog) => {
          dispatch(actionsSensors.selectSensorLog(selectedSensorLog));
          // if (!_.isEmpty(selectedSensorBit)) {
          //   this.selectSensorBit({});
          // }
        },
        selectSensorBit: (selectedSensorBit) => {
          dispatch(actionsSensors.selectSensorBit(selectedSensorBit));
          // if (!_.isEmpty(this.props.selectedSensorLog)) {
          //   this.selectSensorLog({});
          // }
        },
        selectNoiseMessage: (msg) => {
          dispatch(actionsSensors.selectSensorNoiseMsg(selectedProject, msg));
        },
        getG5DeviceStatusMessagesReport: (deviceId) => {
          dispatch(actionsDevices.getG5DeviceStatusMessagesReport(deviceId));
        },
      };
      break;

    case 'couples':
      tabProps = {
        onEdit,
        onCopy,
      };
      break;

    case 'install':
      tabProps = {
        handleSelectMessage: (item) => {
          dispatch(actionsSensors.selectG5Message(selectedProject, item));
        },
        handleSelectBitSampleMessage: (item) => {
          dispatch(actionsSensors.selectG5BitSampleMessage(selectedProject, item));
        },
        getG5DeviceStatusMessagesReport: (deviceId) => {
          dispatch(actionsDevices.getG5DeviceStatusMessagesReport(deviceId));
        },
      };
      break;

    case 'mobile': {
      tabProps = {
        selectMobileSample: (sample) => {
          dispatch(actionsMobileSamples.setSelectedSample(sample));
        },
        setCheckedSamples: (sampleIds) => {
          dispatch(actionsMobileSamples.setCheckedSamples(sampleIds));
        },
        setDisplayItems: (samples) => {
          dispatch(actionsMobileSamples.setDisplayItems(samples));
        },
      };
      break;
    }
  }

  return tabProps;
};

export const CTabsSwitch = connect(
  mapStateToProps,
  mapDispatchToProps
)(TabsSwitch);

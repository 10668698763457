import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware } from './MiddlewareActions';
import stats from 'stats-lite';

const Regression = require('ml-regression');


//--------------------------------
// Alert Details
//--------------------------------
function requestLeakDetails(project, leak) {
    return {
        type: types.REQUEST_LEAK_DETAILS,
        project,
        leak
    };
}



function receiveLeakDetails(project, leak, details) {
    //@TODO: move to the right place
    //details.data = formatObjectsDate(details.data);
    return {
        type: types.RECEIVE_LEAK_DETAILS,
        project,
        leak,
        details
    }
}

//--------------------------------
// Alert Details - Fetch
//--------------------------------

export function fetchDetailsIfNeeded(project, leak) {
    return (dispatch, getState) => {

        if (shouldFetchDetails(getState(), project, leak)) {
            return dispatch(fetchDetails(project, leak))
        }

    }
}

function shouldFetchDetails(state, project, leak) {
    const details = state.leaksList.leaksByProject[project].details[leak];
    if (!details) {
        return true
    }
    else if (details.isFetching) {
        return false;
    }
    else if (details.data) {
        return false;
    }
    return true;
}

export function fetchDetails(project, leak) {
    var path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/" + endPoints.LEAKS_ENDPOINT + "/" + leak;

    return (dispatch, getState) => {
        //@TODO: Check errors.
        dispatch(requestLeakDetails(project, leak))
        return fetchMiddleware(path, {}, getState).then((json) => {
            //Set status.
            if (json.data && json.data[0].LeakStatus != 1) {
                json.data[0].X = json.data[0].FixX;
                json.data[0].Y = json.data[0].FixY;
            }

            dispatch(receiveLeakDetails(project, leak, json))
        })
    }
}


//--------------------------------
// Alert History
//--------------------------------


function requestLeakHistory(project, leak) {
  return {
    type: types.REQUEST_LEAK_HISTORY,
    project,
    leak
  };
}

function receiveLeakHistory(project, leak, history) {
    return {
        type: types.RECEIVE_LEAK_HISTORY,
        project,
        leak,
        history
    }
}

export function fetchHistoryIfNeeded(project, leak) {
    return (dispatch, getState) => {
        if (shouldFetchHistory(getState(), project, leak)) {
            return dispatch(fetchHistory(project, leak))
        }
    }
}

function shouldFetchHistory(state, project, leak) {
    const history = state.leaksList.leaksByProject[project].history[leak]
    if (!history) {
        return true
    }
    else if (history.isFetching) {
        return false;
    }
    else if (history.data.length) {
        return false;
    }
    return true;
}

export function fetchHistory(project, leak) {
    var path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/" + endPoints.LEAKS_ENDPOINT + "/" + leak + "/history";

    return (dispatch, getState) => {
        //@TODO: Check errors.
        dispatch(requestLeakHistory(project, leak))
        return fetchMiddleware(path, {}, getState).then((json) => {
            dispatch(receiveLeakHistory(project, leak, json));
        });
    }
}

export const fetchLeakGrowth = (project, leakId) => {
  return (dispatch, getState) => {
    const path = `${endPoints.ALERTS_ENDPOINT}/leakGrowth/${leakId}`;
    dispatch(leakGrowthRequest(project, leakId));
    return fetchMiddleware(path, {}, getState).then((json) => {
      let regressionData = null;
      let regressionObj = null;
      if (json.status) {
        const sampleIntensitiesData = json.data;
        if (sampleIntensitiesData != null && sampleIntensitiesData.length > 1) {
          let earliestSample = sampleIntensitiesData[sampleIntensitiesData.length - 1];
          let earliestSampleTime = earliestSample.SampleDateAndTime;
          const dayInMillis = 1000 * 60 * 60 * 24;

          const x = [];
          const y = [];

          sampleIntensitiesData.forEach((sample) => {
            let daysDiff = (sample.SampleDateAndTime - earliestSampleTime) / dayInMillis;

            x.push(daysDiff);
            y.push(sample.IntensityA);
          });

          try {
            const linearTrend = new Regression.SLR(x, y);

            // const degree = 3; // setup the maximum degree of the polynomial
            // regressionData = new PolynomialRegression(x, y, degree);

            const score = linearTrend.score(x, y);
            // const score = 0;
            let points = [];
            for (let index = 0; index < sampleIntensitiesData.length; index++) {
              const xVal = x[(x.length - 1) - index];
              points.unshift(linearTrend.predict(xVal));
            }
            linearTrend.points = points;
            const std = stats.stdev(points);

            regressionObj = {
              string: linearTrend.toString(3),
              points: linearTrend.points,
              slope: linearTrend.slope,
              std: std,
              r2: score.r2,
            }

          } catch (e) {
            debugger;
          }
        }


        dispatch(leakGrowthReceive(project, leakId, sampleIntensitiesData, regressionObj));
        // if(sampleIntensitiesData != null) {
        // 	let str = sampleIntensitiesData.map((sample) => sample.IntensityI);
        // 	console.log(str);
        // }
        // console.log(regressionData);
      }
    });
  }
};

const leakGrowthRequest = (project, leakId) => {
    return {
        type: types.REQUEST_LEAK_GROWTH,
        project,
        leakId
    }
}

const leakGrowthReceive = (project, leakId, data, regressionData) => {
	return {
		type: types.RECEIVE_LEAK_GROWTH,
		project,
		leakId,
		data,
		regressionData
	}
}






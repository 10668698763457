import React from 'react';

import { Cell, Column, Table } from 'fixed-data-table-2';

import SortableHeaderCell from '../../components/SortableHeaderCell/SortableHeaderCell';
import TableMixin from '../../components/TableMixin/TableMixin';
import TextCell from '../../components/TextCell/TextCell';

import ReactPaginate from 'react-paginate';
import { ALERT_SAMPLES_COUNT_FOR_PAGE } from '../../actions/SamplesActions';
import CAddTask from '../../containers/CMobileForms/CAddTask';
import CMobileTasksSettings from '../../containers/CMobileTasksSettings/CMobileTasksSettings';
import CTableActions from '../../containers/CTableActions/CTableActions';
import { DateType } from '../../containers/UIhelper/UIDateFormater';
import CheckBoxCell from '../CheckBoxCell/CheckBoxCell';

const TasksTable = (props) => {
  const {
    items,
    indexMap,
    selectedFeature,
    pHeight,
    pWidth,
    localSortProp,
    goToRow,
    t,
  } = props;

  const [currentPage, setCurrentPage] = React.useState(1);
  const [showAddTaskForm, setShowAddTaskForm] = React.useState(false);

  const handleShowAddTaskForm = (isShown) => {
    if (showAddTaskForm !== isShown) {
      setShowAddTaskForm(isShown);
    }
  };

  // const pageWidth = 0.99 * pWidth;

  const totalItemsStyle = {
    float: 'right',
    padding: '2px 8px',
  };
  const handleRowClassNameGetter = (rowIndex) => {
    if ((!rowIndex && rowIndex !== 0) || !indexMap || !selectedFeature.length) {
      return;
    }
    const ids = selectedFeature.map((id) => indexMap[id]);
    return ids.includes(rowIndex) ? 'active-row' : '';
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  const toggleSampleChecked = (value, checked) => {
    props.selectTask(value, true);
  };

  const myHandleRowClick = (event, rowIndex) => {
    if (event.target.type === undefined || event.target.type !== 'checkbox') {
      const task = items[rowIndex];
      props.selectTask(task, false);
    }
  };

  return (
    <div>
      <CAddTask
        isOpen={showAddTaskForm}
        showAddTaskForm={handleShowAddTaskForm}
      />
      <Table
        maxHeight={pHeight - 188} // 715 - 620
        width={pWidth}
        rowsCount={items.length}
        rowHeight={30}
        headerHeight={30}
        onRowClick={myHandleRowClick}
        rowClassNameGetter={handleRowClassNameGetter}
        scrollToRow={goToRow}
      >
        <Column
          columnKey='Selected'
          header={<Cell>{}</Cell>}
          cell={
            <CheckBoxCell
              data={items}
              onChange={toggleSampleChecked}
              isChecked={(value) => selectedFeature.includes(value.task_uuid)}
            />
          }
          width={20}
          flexGrow={1}
        />

        {/* <Column columnKey="Delete" width={20} flexGrow={1} cell={<ButtonCell onClick={(item) => {
            props.deleteTask(item.task_uuid);
          }} title={t('Delete')} data={items}/>  } /> */}

        <Column
          columnKey='TaskName'
          width={120}
          flexGrow={2}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field='task_name' />}
        />
        <Column
          columnKey='CreationDate'
          width={80}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={
            <TextCell
              data={items}
              field='creation_date'
              dateType={DateType.DATE}
            />
          }
        />
        <Column
          columnKey='SamplesCount'
          width={115}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field='samples_count' />}
        />
      </Table>
      <div className='center'>
        {/*<Pagination*/}
        {/*  prevPageText={t('prev')}*/}
        {/*  nextPageText={t('next')}*/}
        {/*  firstPageText={t('first')}*/}
        {/*  lastPageText={t('last')}*/}
        {/*  activePage={currentPage}*/}
        {/*  itemsCountPerPage={ALERT_SAMPLES_COUNT_FOR_PAGE}*/}
        {/*  totalItemsCount={items.length}*/}
        {/*  pageRangeDisplayed={5}*/}
        {/*  onChange={handlePageClick}*/}
        {/*/>*/}
        <ReactPaginate
          previousLabel={t('prev')}
          nextLabel={t('next')}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={Math.ceil(items.length / ALERT_SAMPLES_COUNT_FOR_PAGE)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          onPageActive={currentPage}
        />
      </div>
      <button
        type='button'
        className='btn btn-success btn-assessment create-item'
        onClick={() => {
          handleShowAddTaskForm(true);
        }}
      >
        <i title={t('create_task')} />
      </button>
      {items && items.length > 0 && (
        <CTableActions type='mobile' notify={props.notify} />
      )}

      {items && items.length > 0 && (
        <span style={totalItemsStyle}>
          {t('Selected: {selectedIndex}. Total: {total}', {
            selectedIndex: indexMap[selectedFeature] + 1 || 0,
            total: items.length,
          })}
        </span>
      )}

      {/* <ToggleButtonGroup
        exclusive
        className="mobile-context-buttons"
        value={displayMode}
        onChange={(_event, value) => {
          onChangeMobileDisplayMode(value);
        }}
      >
        <ToggleButton value="Alerts">{t('Alerts')}</ToggleButton>
        <ToggleButton value="Tasks">{t('Survey')}</ToggleButton>
      </ToggleButtonGroup> */}
    </div>
  );
};

export default TableMixin(TasksTable, CMobileTasksSettings);

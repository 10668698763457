import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import CommentField from '../../components/CommentField/CommentField';
import AppModal from '../Modals/AppModal';
import Select from './../Select/Select';

import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
require('./StatusModalSettings.scss');

const classNames = require('classnames');

class StatusModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };

    // Methods delaration
    this.onClose = this.onClose.bind(this);
    this.onSaveData = this.onSaveData.bind(this);
  }

  // Handling the close dialog action
  onClose() {
    this.props.setOpenStatusDialog(false);
    this.setState({ open: false });
    // console.log("onClose function opened");
  }

  // Handling the save dialog data action
  onSaveData(values, dispatch, props) {

    // check Required fields:
    if (this.props.nextState == 'Shadow') {
      if (values['source'] == null || values['source'] === '') {
        throw new SubmissionError({
          ['source']: 'Required',
          _error: 'source' + ' is Required'
        });
      }
    }

    if (this.state.open) {
      const data = [{ field: props.field, value: props.value }];

      if (props.values.comment) {
        data.push({ field: 'Comment', value: props.values.comment });
      }
      if (props.values.source) {
        data.push({ field: 'LeakBoss', value: props.values.source });
      }
      this.setState({ open: false });
      // Update changes
      this.props.updateLeak(data);
      // Open the status dialog
      this.props.setOpenStatusDialog(false);
      // Clear form fields
      this.props.resetForm();
    }
  }

  // Receiving the props and check if isOpen true or false, according sets state
  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen != this.state.open) {
      this.setState({ open: nextProps.isOpen });
    }
  }

  render() {
    const { selectedFeature, leaksIds, handleSubmit, id, stateName, prevState, nextState, rowIndex, field, onCopy, onEdit, alertsContextMenu } = this.props;

    const formClass = 'form-group auto-center';
    const labelsClass = 'col-xs-3 control-label';
    const fieldsClass = 'col-xs-8 control-input';
    // const panelClasses = classNames('add-status-form', { 'show': true });


    const getAlertType = (alertsContextMenu) => {
      switch (alertsContextMenu) {
        case 'alerts':
          return 'Alert';
        case 'noise':
          return 'Noise Alert';
        case 'valve':
          return 'Valve Alert';
        case 'prsAlerts':
          return 'Pressure Alert';
        default:
          return 'Unknown Alert';
      }
    };
    const alertType = getAlertType(alertsContextMenu);


    return (
      <AppModal
        open={this.state.open}
        onClose={this.onClose}
        title={this.context.t('change_statename_of_alert_type_from_prevstate_to_nextstate', { alertType, stateName, id, prevState, nextState })}
        content={(
          <form
            className="form-horizontal sticky-actions"
            onSubmit={handleSubmit((data, d, p) => {
              this.onSaveData(data, d, p);
            })}>

            <div style={{ width: '100%' }}>

              {/* Comment Field */}
              <CommentField
                placeholder={this.context.t('comment')}
                fieldName="comment"
                featureID={selectedFeature}
                onEdit={onEdit}
                onCopy={onCopy}
                disabled={!selectedFeature}
              />

              {/* Shadow ('Leak Boss') Field */}
              {(nextState == 'Shadow' || nextState == 'Duplicate') &&
                <div className={formClass}>
                  <label className={labelsClass}>{this.context.t('source_alert')}</label>
                  <div className={fieldsClass}>
                    <Field
                      name="source"
                      component={(props) =>
                      (<Select
                        {...props}
                        options={leaksIds.filter(x => (x.label && x.value != selectedFeature))}
                      />)}
                    />
                  </div>
                </div>}
            </div>

            <Button style={{ margin: '0 5px' }} variant="contained" color="primary" endIcon={<SendIcon />} type='submit'>{this.context.t('apply_changes')}</Button>
            <Button style={{ margin: '0 5px' }} variant="contained" color="secondary" endIcon={<CancelIcon />} onClick={this.onClose}>{this.context.t('cancel')}</Button>
          </form>
        )
        }
      />
      // <Modal
      //     className="delete-dialog"
      //     style={{ display: 'block' }}
      //     show={this.state.open}
      //     onHide={this.onClose}
      //     aria-labelledby="ModalHeader">
      //     <div className={panelClasses}>
      //         <form
      //             className="form-horizontal sticky-actions"
      //             onSubmit={handleSubmit((data, d, p) => {
      //                 this.onSaveData(data, d, p);
      //             })}>

      //             {/* Modal Header */}
      //             <Modal.Header closeButton>
      //                 <Modal.Title id='ModalHeader'>
      //                     {this.context.t('Change {stateName} of leak {id} from {prevState} to {nextState}', {stateName, id, prevState, nextState})}
      //                 </Modal.Title>
      //             </Modal.Header>

      //             {/* Modal Body */}
      //             <Modal.Body>
      //                 <div style={{ width: '100%' }}>

      //                     {/* Comment Field */}
      //                     <CommentField
      //                         placeholder={this.context.t('Comment')}
      //                         fieldName="comment"
      //                         featureID={selectedFeature}
      //                         onEdit={onEdit}
      //                         onCopy={onCopy}
      //                         disabled={!selectedFeature}
      //                     />

      //                     {/* Shadow ('Leak Boss') Field */}
      //                     {(nextState == 'Shadow' || nextState == 'Duplicate') &&
      //                         <div className={formClass}>
      //                             <label className={labelsClass}>{this.context.t('Source Alert')}</label>
      //                             <div className={fieldsClass}>
      //                                 <Field
      //                                     name="source"
      //                                     component={props =>
      //                                         <Select
      //                                             {...props}
      //                                             options={leaksIds.filter(x => (x.label && x.value != selectedFeature))}
      //                                         />}
      //                                 />
      //                             </div>
      //                         </div>}
      //                 </div>
      //             </Modal.Body>

      //             {/* Modal Footer */}
      //             <Modal.Footer>

      //                 <button
      //                     className='btn btn-success'
      //                     type="submit"
      //                 >
      //                     {this.context.t('Apply changes')}
      //                 </button>

      //                 <button
      //                     className='btn btn-danger'
      //                     onClick={this.onClose}
      //                 >
      //                     {this.context.t('Cancel')}
      //                 </button>

      //             </Modal.Footer>
      //         </form>
      //     </div>
      // </Modal>
    );
  }
}

const validate = (values) => {
  const errors = {};

  // check Required fields:
  if (values['source'] == null || values['source'] === '') {
    errors['source'] = 'Required';
  }

  return (errors);
};

// StatusModal.propTypes = {
//     onValueChange: PropTypes.func.isRequired,
//     options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
// };

StatusModal.contextTypes = {
  t: PropTypes.func.isRequired
};

const statusModal = reduxForm({
  form: 'change-status', // a unique name for this form
  enableReinitialize: true,
  validate,
})(StatusModal);

export default statusModal;

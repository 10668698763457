import React from 'react';
import PropTypes from 'prop-types';
import { routes } from '../../constants/Misc';

import AlertsTable from '../AlertsTable/AlertsTable';
import AlertsTableNoise from '../AlertsTableNoise/AlertsTableNoise';
import PressureAlertsTable from '../AlertsTable/PressureAlertsTable';
import ValveAlertsTable from '../AlertsTable/ValveAlertsTable';
import SensorsTable from '../SensorsTable/SensorsTable';
import CouplesTable from '../CouplesTable/CouplesTable';
import AssessmentsTable from '../CouplesTable/AssessmentsTable';
import ProjectsTable from '../Tables/ProjectsTable';
import UsersTable from '../Tables/UsersTable';
import CustomersTable from '../Tables/CustomersTable';
import InterferencesTable from '../Interferences/InterferencesTable';
import TasksTable from '../Mobile/TasksTable';
import MobileAlertsTable from '../Mobile/MobileAlertsTable';
import MobileDevicesTable from '../Mobile/MobileDevicesTable';
import SOPsTable from '../SOP/SOPsTable';
import CInspectDataTabs from '../../containers/CInspectTab/CInspectDataTabs';

require('fixed-data-table-2/dist/fixed-data-table.css');
require('./Tables.scss');

const TableSwitch = (props, context) => {

  let Table;
  const { path, items } = props;
  switch (path) {
    case routes.ALERTS:
      switch (props.alertsContextMenu) {
        case 'alerts':
          Table = AlertsTable;
          break;

        case 'prsAlerts':
          Table = PressureAlertsTable;
          break;

        case 'noise':
          Table = AlertsTableNoise;
          break;

        case 'valve':
          Table = ValveAlertsTable;
          break;

        default:
          break;
      }
      break;
    case routes.SENSORS:
      Table = SensorsTable;
      break;
    case routes.COUPLES:
      if (props.displayAssessments) {
        Table = AssessmentsTable;
      } else {
        Table = CouplesTable;
      }
      break;
    case routes.MANAGE:
      switch (props.managementContext) {
        case 'Customers':
          Table = CustomersTable;
          break;
        case 'Projects':
          Table = ProjectsTable;
          break;
        case 'Users':
          Table = UsersTable
          break;
      }
      break;
    case routes.INSTALL:
      if (props.installContext == 'SOPs') {
        Table = SOPsTable;
      }
      else if (props.installContext == 'Sensors') {
        Table = SensorsTable;
      }
      else if (props.installContext == 'Interferences') {
        Table = InterferencesTable;
      }
      break;
    case routes.MOBILE:
      switch (props.mobileDisplayMode) {
        case 'Tasks':
          Table = TasksTable;
          break;
        case 'Alerts':
          Table = MobileAlertsTable;
          break;
        case 'Devices':
          Table = MobileDevicesTable;
          break;
        default:
          Table = null;
          break;
      }
      break;

    case routes.INPECT:
      Table = CInspectDataTabs;
      break;

    default:
      break;
  }

  return (
    <Table
      items={items}
      {...props}
      {...context}
    />
  );
}


//@TODO write props types...
//TableSwitch.propTypes = {
//  handleRowClick: PropTypes.func.isRequired
//};
TableSwitch.contextTypes = {
  t: PropTypes.func.isRequired
}

export default TableSwitch;

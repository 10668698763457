import GeoJSON from 'geojson';

import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';

import { fetchHistoryIfNeeded } from './AlertsDetailsActions';
import * as actionsAssessments from './AssessmentsActions';
import * as actionsSamplesOutput from './SamplesOutputDataActions';
import { fetchMiddleware, setIndexes } from './MiddlewareActions';
import { COUPLE_SAMPLES_COUNT_FOR_PAGE } from '../constants/Misc';
import { processData } from './CouplesCoverageActions';
import { selectFeature } from "./selectors";
import { clearCouplePath, createNewCouple } from "./setters";

const _ = require('lodash');

export const WARNINGS = {
  INACTIVE_NO_SAMPLES: -10,
  INACTIVE_COUPLE: -9,
  HAVE_TO_UNINSTALL_SENSORS: -8,
  HAVE_TO_INSTALL_SENSORS: -7,
  RM_PROBLEM: -6
};

export const selectCoupleOrAssessment = (item) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProject = state.leaksList.selectedProject;
    const displayAssessments = state.leaksList.leaksByProject.displayAssessments;
    const itemID = item.id;

    if (displayAssessments) {
      dispatch(fetchHistoryIfNeeded(selectedProject, itemID));
      dispatch(actionsAssessments.fetchCoupleSamplesForAlertIfNeeded(selectedProject, itemID));
      dispatch(actionsAssessments.selectAssessment(selectedProject, itemID));
    } else {
      dispatch(selectCoupleSample(selectedProject, undefined));
      dispatch(fetchCoupleSamplesIfNeeded(selectedProject, itemID, 0));
      dispatch(fetchCouplePathIdNeeded(selectedProject, itemID));
      dispatch(fetchLeakCoupleIfNeeded(selectedProject, '', itemID));
      dispatch(actionsAssessments.fetchCoupleAlertsIfNeeded(selectedProject, itemID));
      dispatch(clearCouplePath());
      dispatch(createNewCouple(false));
      dispatch(selectFeature(selectedProject, itemID, 'couple'));
    }

  };
};

function requestCouples(project) {
  return {
    type: types.REQUEST_COUPLES,
    project
  };
}

function receiveCouples(project, couples, indexMap) {
  return {
    type: types.RECEIVE_COUPLES,
    project,
    couples,
    indexMap
  };
}

export function fetchCouplesIfNeeded(project, force = false) {
  return (dispatch, getState) => {
    if (force || shouldFetchCouples(getState(), project)) {
      const filterParams = getState().leaksList.filters.couples;
      const sort = filterParams.sort === false ? '' : JSON.stringify(filterParams.sort);
      const filters = filterParams.filters === false ? '' : JSON.stringify(filterParams.filters);
      return dispatch(fetchCouples(project, filters, sort));
    }
  };
}

function shouldFetchCouples(state, project) {
  const couples = state.leaksList.leaksByProject[project].couples;
  if (!couples) {
    return true;
  }
  return !couples.isFetching;

}

function fetchCouples(project, filters, sort) {
  const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/" + endPoints.COUPLES_ENDPOINT + "?filters=" + filters;

  return (dispatch, getState) => {
    dispatch(requestCouples(project));
    return fetchMiddleware(path, {}, getState)
      .then((json) => {
        if (!json.status) {
          return [[], {}];
        } else {
          const data = json.data;
          const state = getState();
          const projectsBundle = state.leaksList.projectsList;
          const selectedProject = projectsBundle.items[projectsBundle.projectIndexMap[project]];

          const oneDayInMillis = 1000 * 60 * 60 * 24;
          // const DAYS = selectedProject.DaysCorrelationIncative;
          const now = new Date().getTime();
          // const sleepTime = 1000 * 60 * 60 * 24 * DAYS;
          data.forEach((couple) => {
            if (_.isEmpty(couple.DeviceID1) || _.isEmpty(couple.DeviceID2)) {
              if (couple.LastDaemonActivity == null || couple.LastDaemonActivity <= 0) {
                couple.warningEnum = WARNINGS.HAVE_TO_INSTALL_SENSORS;
              } else {
                couple.warningEnum = WARNINGS.HAVE_TO_UNINSTALL_SENSORS;
              }
            } else {
              // const activityRatioDaysBack = (selectedProject) ? selectedProject.SensorActivityRatioDaysBack : 5; //@Todo: remove this. its crash the app when selectedProject is undefined
              // const correlationIncative = (selectedProject) ? selectedProject.DaysCorrelationIncative : 5; //@Todo: remove this. its crash the app when selectedProject is undefined
              const activityRatioDaysBack = selectedProject.SensorActivityRatioDaysBack;
              const correlationIncative = selectedProject.DaysCorrelationIncative;
              if (now - couple.LastActivity1 > oneDayInMillis * activityRatioDaysBack ||
                now - couple.LastActivity2 > oneDayInMillis * activityRatioDaysBack) {
                couple.warningEnum = WARNINGS.INACTIVE_COUPLE;
              } else if (couple.LastSampleDateAndTime != null && (now - couple.LastSampleDateAndTime > oneDayInMillis * correlationIncative)) {
                couple.warningEnum = WARNINGS.INACTIVE_NO_SAMPLES;
              }

              if (couple.RmStatus === 'bad') {
                couple.warningEnum = Math.min(couple.warningEnum, WARNINGS.RM_PROBLEM);
              }
            }
          });
          const couples = [data, setIndexes(data, 'id')];
          return couples;
        }
      })
      .then((args) => {
        // const couples = args[0];
        // const indexMap = args[1];
        if (getState().user.isAQS) {
          dispatch(FetchCouplesRm(project));
        }
        return dispatch(receiveCouples(project, ...args));
      });
  };
}

export function setCouplesFilters(filters) {
  return {
    type: types.SET_COUPLES_FILTERS,
    filters
  };
}

export function setCouplesDefFilters(filters) {
  return {
    type: types.SET_COUPLES_DEF_FILTERS,
    filters
  };
}

export const sortCouples = (field, dir) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProject = state.leaksList.selectedProject;

    // sort localy:
    const couples = [...state.leaksList.leaksByProject[selectedProject].couples.couples];
    const sortedCouples = couples.sort((a, b) => {

      const firstItem = (dir == 'asc') ? a : b;
      const secondItem = (dir == 'asc') ? b : a;

      if (firstItem[field] > secondItem[field]) {
        return 1;
      } else if (secondItem[field] > firstItem[field]) {
        return -1;
      } else {
        return 0;
      }

    });

    const indexMap = setIndexes(sortedCouples, 'id');

    dispatch(setSortDetails(field, dir));
    dispatch(receiveCouples(selectedProject, sortedCouples, indexMap))
    // dispatch(sortLocaly(selectedProject, sortedCouples, indexMap, field, dir));
  };
}

const sortLocaly = (project, couples, indexMap, field, dir) => {
  return {
    type: types.SORT_COUPLES_LOCALY,
    project,
    couples,
    indexMap,
    field,
    dir
  };
};

export const setSortDetails = (field, dir) => {
  return {
    type: types.SORT_COUPLES,
    field,
    dir
  };
};

//--------------------------------
// Couples RMs
//--------------------------------
function FetchCouplesRm(project) {
  const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/rm";

  return (dispatch, getState) => {
    return fetchMiddleware(path, {}, getState)
      .then((json) => {
        const couples = getState().leaksList.leaksByProject[project].couples.couples;
        const rms = json.data;
        const rmsIndex = setIndexes(rms, 'ID');
        couples.forEach((couple) => {
          const rm = rms[rmsIndex[couple.id]];
          if (rm != null) {
            couple.RM = rm.RM;

            if (rm.hasProblem) {
              couple.warningEnum = 2;
            }
          }
        });
        //const rms = [json.data, setIndexes(json.data, 'id')];
        return [couples];
      })
      .then((args) => {
        dispatch(receiveCouplesRM(project, ...args));
      });
  };
}

function receiveCouplesRM(project, couples) {
  return {
    type: types.RECEIVE_COUPLES_RM,
    project,
    couples,
  };
}

//--------------------------------
// Couple Path
//--------------------------------
export function fetchCouplePathIdNeeded(projectID, coupleID) {
  return (dispatch) => dispatch(FetchCouplePath(projectID, coupleID));
}

// function shouldFetchCouplePath(state, projectID, coupleID) {
//   // TODO: check if needed.
//   // const sensors = state.leaksList.leaksByProject[projectID].sensors.sensors;
//   // const sensorID = state.leaksList.leaksByProject[projectID].selectedFeature;
//   //
//   // sensors.forEach(sensor => {
//   //   if (sensor.id === sensorID) {
//   //     return false;
//   //   }
//   // });

//   return true;
// }

function FetchCouplePath(project, coupleID) {
  const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/couplePath/" + coupleID;

  return (dispatch, getState) => {
    dispatch(requestCouplePath(project, coupleID));
    return fetchMiddleware(path, {}, getState)
      .then((json) => {
        let data = [];
        if (json.status) {
          const state = getState();
          const projectsBundle = state.leaksList.projectsList;
          const selectedProject = projectsBundle.items[projectsBundle.projectIndexMap[project]];

          data = json.data.map((x, i) => ({ ...x, index: i }));
          processData(data, selectedProject);
        }
        dispatch(receiveCouplePath(project, coupleID, data));
      });
  };
}

function requestCouplePath(project, coupleID) {
  return {
    type: types.REQUEST_COPULE_PATH,
    project,
    coupleID
  };
}

function receiveCouplePath(project, coupleID, data) {
  return {
    type: types.RECEIVE_COPULE_PATH,
    project,
    coupleID,
    data
  };
}

//--------------------------------
// Sensor Couple Path
//--------------------------------
export function fetchLinkedCouplesIdNeeded(projectID, sopID) {
  return (dispatch) => dispatch(FetchLinkedCouples(projectID, sopID));
}

// function shouldFetchLinkedCouples(state, projectID, sopID) {
//   // TODO: check if needed.
//   // const sensors = state.leaksList.leaksByProject[projectID].sensors.sensors;
//   // const sensorID = state.leaksList.leaksByProject[projectID].selectedFeature;
//   //
//   // sensors.forEach(sensor => {
//   //   if (sensor.id === sensorID) {
//   //     return false;
//   //   }
//   // });

//   return true;
// }

function FetchLinkedCouples(project, sopID) {
  const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/sopId/" + sopID + "/sensorLinkedCouples";

  return (dispatch, getState) => {
    dispatch(requestSensorLinkedCouples(project, sopID));
    return fetchMiddleware(path, {}, getState)
      .then((json) => {
        let data = [];
        if (json.status) {
          const state = getState();
          const projectsBundle = state.leaksList.projectsList;
          const selectedProject = projectsBundle.items[projectsBundle.projectIndexMap[project]];

          data = json.data;

          processData(data, selectedProject);

        }
        dispatch(receiveLinkedCouples(project, sopID, data));
      });
  };
}

function requestSensorLinkedCouples(project, sopId) {
  return {
    type: types.REQUEST_SENSOR_LINED_COUPLES,
    project,
    sopId
  };
}

function receiveLinkedCouples(project, sopID, data) {
  return {
    type: types.RECEIVE_SENSOR_LINED_COUPLES,
    project,
    sopID,
    data
  };
}

//--------------------------------
// Leak Couple (Sensors)
//--------------------------------
function requestLeakCouples(project, coupleId) {
  return {
    type: types.REQUEST_LEAK_COUPLES,
    project,
    coupleId
  };
}

function receiveLeakCouples(project, coupleId, coupleSensors) {
  return {
    type: types.RECEIVE_LEAK_COUPLES,
    project,
    coupleId,
    coupleSensors
  };
}

export function fetchLeakCoupleIfNeeded(project, leak, coupleId) {
  return (dispatch, getState) => {
    if (shouldFetchLeakCouple(getState(), project, leak)) {
      return dispatch(fetchLeakCouple(project, coupleId));
    }
  };
}

function shouldFetchLeakCouple(state, project, leak) {
  const couples = state.leaksList.leaksByProject[project].leakCouple[leak];

  if (!couples) {
    return true;
  }
  return !couples.isFetching;
}

function fetchLeakCouple(project, coupleId) {
  var path;
  if (coupleId != null && coupleId != '') {
    path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/couple/" + coupleId + "/coupleSensors";
    return (dispatch, getState) => {
      //@TODO: Check errors.
      dispatch(requestLeakCouples(project, coupleId));
      return fetchMiddleware(path, {}, getState)
        .then((json) => dispatch(receiveLeakCouples(project, coupleId, json)));
    };
  }
}

//--------------------------------
// Couple outputs
//--------------------------------
export function fetchCoupleSamplesIfNeeded(projectID, coupleID, page) {
  return (dispatch, getState) => {
    const sort = getState().leaksList.filters.coupleSamplesSort;
    // if (shouldFetchCoupleSamples(getState(), projectID, coupleID)) {
    return dispatch(fetchCoupleSamples(projectID, coupleID, page, sort));
    // }
  };
}

// function shouldFetchCoupleSamples(state, projectID, coupleID) {
//   let bRc = true;
//   //const couplesOutput = state.leaksList.leaksByProject[projectID].couples.couples[couplesID].output;
//   //if (couplesOutput) {
//   //    bRc = false;
//   //}

//   return (bRc);
// }

function fetchCoupleSamples(project, coupleID, page, sort) {
  let sortObj;
  if (!sort) {
    sortObj = {
      orderBy: 'SampleDateAndTime',
      dir: 'DESC'
    };
  } else {
    sortObj = {
      orderBy: sort.field,
      dir: sort.dir
    };
  }

  const path = `${endPoints.END_POINT}/couple/${coupleID}?page=${page}&rows=${COUPLE_SAMPLES_COUNT_FOR_PAGE}`;
  // const path = `${endPoints.END_POINT}/couple/${coupleID}?page=${page}&rows=${COUPLE_SAMPLES_COUNT_FOR_PAGE}&sort=${JSON.stringify(sortObj)}`;

  return (dispatch, getState) => {
    // const state = getState();
    // const projectInfo = state.leaksList.projectsList.items.find(element => element.ID.toString() == project);
    dispatch(requestCoupleSamples(project, coupleID));
    return fetchMiddleware(path, {}, getState)
      .then((json) => {
        const state = getState();
        const selectedFeatureType = state.leaksList.leaksByProject.selectedFeatureType;
        const { data } = json;
        let isExtendedInfo = true;

        let groupData;
        if (selectedFeatureType !== 'alert') {
          groupData = data.result;
        } else {
          const selectedAlertId = (selectedFeatureType === 'alert') ? state.leaksList.leaksByProject.selectedFeature : false;
          groupData = buildCoupleSamplesData(data.result, selectedAlertId);
        }

        dispatch(receiveCoupleSamples(project, coupleID, page, data, groupData, isExtendedInfo));
      });
  };
}

function buildCoupleSamplesData(data, selectedAlertId) {
  if (!data) {
    return [];
  } else {
    if (selectedAlertId) {
      // check if the alert is the main item in the data:
      data.forEach((item, index, arr) => {
        if (item.LeakID != selectedAlertId) {
          // have to check if exist in the additional items:
          if (item.multi) {
            const alertRow = item.multi.find((x) => x.LeakID == selectedAlertId);
            if (alertRow) {
              const currentBosItem = { ...item };
              const newMoreAttr = item.multi.filter((x) => x.LeakID !== selectedAlertId);

              delete currentBosItem.multi;
              newMoreAttr.unshift(currentBosItem);

              const newBossItem = {
                ...{ ...currentBosItem, ...alertRow },
                multi: newMoreAttr
              };

              data[index] = newBossItem;
            }
          }
        }
      });
    }
    return data;
  }
}

function requestCoupleSamples(project, coupleID) {
  return {
    type: types.REQUEST_COUPLE_SAMPLES,
    project,
    coupleID
  };
}

function receiveCoupleSamples(project, coupleID, pageIndex, data, groupData, isExtendedInfo) {
  return {
    type: types.RECEIVE_COUPLE_SAMPLES,
    project,
    coupleID,
    pageIndex,
    data,
    groupData,
    isExtendedInfo
  };
}

//--------------------------------
// Couples Tabs Actions
//--------------------------------
export function selectCoupleSample(project, selectedSample) {
  return (dispatch) => {
    dispatch(setSelectedCoupleSample(selectedSample));
    if (selectedSample != null) {
      dispatch(actionsSamplesOutput.getSampleOutputData(selectedSample));
      dispatch(actionsSamplesOutput.getDeviceMessageData(selectedSample.ID));
      dispatch(actionsSamplesOutput.getTensorProbabilityData(selectedSample.ID));
      dispatch(actionsSamplesOutput.getTensorData(selectedSample));
    }
  };
}

export function setSelectedCoupleSample(selectedCoupleSample) {
  return {
    type: types.SELECT_COUPLE_SAMPLE,
    selectedCoupleSample
  };
}

export function sortCoupleSamples(field, dir) {
  return {
    type: types.SORT_COUPLE_SAMPLES,
    field,
    dir
  };
}

//--------------------------------
// Delete Couple
//--------------------------------
export function deleteCouple(project, coupleId, cb) {
  const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/couple/" + coupleId;

  return (dispatch, getState) => {
    const fetchOptions = {
      method: 'DELETE'
    };
    dispatch(requestDeleteCouple(coupleId));
    dispatch(clearCouplePath());
    return fetchMiddleware(path, fetchOptions, getState)
      .then(json => {
        const status = json.status;
        dispatch(receiveDeleteCouple(coupleId, status));
        cb(status, json.err);
      });
  };
}

function requestDeleteCouple(coupleId) {
  return {
    type: types.REQUEST_DELETE_COUPLE,
    coupleId
  };
}

function receiveDeleteCouple(coupleId, status) {
  return {
    type: types.RECEIVE_DELETE_COUPLE,
    coupleId,
    status
  };
}

export function getCouplesPathsGeoJson() {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const state = getState();
    const selectedProjectId = state.leaksList.selectedProject;
    const projectsList = state.leaksList.projectsList.items;
    const projectsIndexMap = state.leaksList.projectsList.projectIndexMap;
    const selectedProjectObj = projectsList[projectsIndexMap[selectedProjectId]];
    const couplesList = state.leaksList.leaksByProject[selectedProjectId].couplesCoveragePaths.paths;
    const sensorsList = state.leaksList.leaksByProject[selectedProjectId].sensors.sensors;
    const sopsList = state.leaksList.leaksByProject[selectedProjectId].sops.sops;

    if (!couplesList) {
      reject('have to reload');
    } else {
      const couplesDataArray = couplesList
        .filter((couple) => couple.pathPoints !== undefined)
        .map((couple) => ({
          "Couple_id": couple.CoupleID,
          "Length_m": couple.SectionLength,
          "Last_active": couple.LastSampleTime ? new Date(couple.LastSampleTime).toISOString() : '--:--',
          "pathPoints": couple.pathPoints
        }));
      const sensorsDataArray = sensorsList
        .filter((sensor) => sensor.Latitude !== undefined && sensor.Longitude !== undefined && sensor.SensorStatus !== 'Not Installed')
        .map((sensor) => ({
          "Sensor_id": sensor.id,
          "DMA_id": sensor.DmaID,
          "Asset_id": sensor.AssetID,
          "Address": sensor.StreetAddress,
          "Device_id": sensor.DeviceID,
          "Sensor_Status": sensor.SensorStatus,
          "Sensor_Type": sensor.SensorType,
          "Sensor_Sync_Method": sensor.SyncMethod,
          "Sensor_Under-Over": (sensor.isUnderground === 1) ? 'Underground' : 'Overground',
          "Comment": sensor.SopComment,
          "Latitude": sensor.Latitude,
          "Longitude": sensor.Longitude,
        }));
      const sensorsNotInstalledDataArray = sensorsList
        .filter((sensor) => sensor.Latitude !== undefined && sensor.Longitude !== undefined && sensor.SensorStatus === 'Not Installed')
        .map((sensor) => ({
          "Sensor_id": sensor.id,
          "DMA_id": sensor.DmaID,
          "Asset_id": sensor.AssetID,
          "Address": sensor.StreetAddress,
          "Device_id": sensor.DeviceID,
          "Sensor_Status": sensor.SensorStatus,
          "Sensor_Type": sensor.SensorType,
          "Sensor_Sync_Method": sensor.SyncMethod,
          "Sensor_Under-Over": (sensor.isUnderground === 1) ? 'Underground' : 'Overground',
          "Comment": sensor.SopComment,
          "Latitude": sensor.Latitude,
          "Longitude": sensor.Longitude,
        }));
      const sopsDataArray = sopsList
        .filter((sop) => sop.Latitude !== undefined && sop.Longitude !== undefined)
        .map((sop) => ({
          "Latitude": sop.Latitude,
          "Longitude": sop.Longitude,
          "Address": sop.StreetAddress,
          "Type": sop.Meaning,
          "InstallPoint_id": sop.ID
        }))
      const couplePathGeoJson = GeoJSON.parse([...couplesDataArray, ...sensorsDataArray, ...sopsDataArray], {
        LineString: 'pathPoints',
        Point: ['Latitude', 'Longitude']
      });

      resolve({
        geoJSON: couplePathGeoJson,
        project: selectedProjectObj,
        geos: {
          sops: GeoJSON.parse(sopsDataArray, { Point: ['Latitude', 'Longitude'] }),
          sensors: GeoJSON.parse(sensorsDataArray, { Point: ['Latitude', 'Longitude'] }),
          notInstalledSenosrs: GeoJSON.parse(sensorsNotInstalledDataArray, { Point: ['Latitude', 'Longitude'] }),
          couples: GeoJSON.parse(couplesDataArray, { LineString: 'pathPoints' })
        }
      });
    }
  });
}

export const requestRerunCouplesSamples = (coupleId, timeInput) => {
  return async (dispatch, getState) => {
    const path = `${endPoints.END_POINT}/couple/rerun/${coupleId}/`;
    const { user } = getState().user;
    const token = await user.getIdToken();

    try {
      const response = await fetch(path, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': token
        },
        body: JSON.stringify({ data: timeInput })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const resData = await response.json();
      return resData;

    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  }
}


import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Input from '../../components/Input/Input';


require('./AddressField.scss');

export default class AddressField extends React.Component {

  constructor(props) {
    super(props);

    this.getAddressClick = this.getAddressClick.bind(this);
  }

  getAddressClick() {
    this.props.onGetAddress();
  }

  render() {
    const { user, fieldName, className, fieldClassName = 'col-xs-10' } = this.props;
    return (
      <div className={className}>
        <div className="address-area">
          <label className="col-xs-2 control-label">{this.context.t('address')}</label>
          <div className={fieldClassName}>
            <Field name={fieldName} component={Input} type="text" className="form-control" disabled={this.props.disabled || !user.editMode} />
            { this.props.onGetAddress &&
              <img
                src={require('./../../../images/getAddress.png')}
                className="getAddress-button"
                onClick={this.getAddressClick}
                disabled={this.props.disabled || !user.editMode}
              />
            }
          </div>
        </div>
      </div>
    );
  }
}

AddressField.contextTypes = {
  t: PropTypes.func.isRequired
}


import { Box, Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Plot from 'react-plotly.js';
import { useSelector } from 'react-redux';
import IntensityNoiseGraph from '../IntensityNoiseGraph';

import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import IconButton from '@mui/material/IconButton'

const markerSize = 7;

const createTrace = (x, y, name, color) => ({
  x,
  y,
  name,
  type: 'scatter',
  mode: 'lines+markers',
  line: {
    color,
  },
  marker: {
    color: 'rgba(255, 255, 255, 100)',
    size: markerSize,
    line: {
      color,
      width: 1
    }
  }
})

const plotLayout = {
  height: 210,
  width: 180,
  legend: {
    orientation: 'h'
  },
  margin: { t: 10, b: 5, l: 40, r: 5 },
  yaxis: {
    rangemode: 'tozero',
  },
}

const SensorInfoAdvanced = ({ project }) => {
  const timeZone = useSelector((state) => state.local.timeZone);
  const sensors = useSelector((state) => state.leaksList.leaksByProject[project].sensors);
  // const noiseMessages = sensors.g5NoiseMsgs;
  const noiseGraphData = sensors.g5NoiseGraphData;
  const samplesCount = sensors.noiseAlertCount;

  const [isOpen, setIsOpen] = React.useState(false);
  const [plotStdAGC, setPlotStdAGC] = React.useState([]);
  const [plotTotalPowerNormalize, setPlotTotalPowerNormalize] = React.useState([]);
  const [ncFromAudio, setNcFromAudio] = React.useState([]);

  const classes = useStyle();

  React.useEffect(() => {
    const messages = sensors.g5NoiseMsgs.slice(0, samplesCount);

    const timesArray = messages.map((sample) => new Date(sample.TimeStamp));

    const stdAgc = messages.map((sample) => sample['stdAGC'])
    const stdAgcLast = messages.map((sample) => sample['stdAGC_Last'])

    const totalPowerNorm = messages.map((sample) => sample['TotalPowerNorm'])
    const totalPowerNormLast = messages.map((sample) => sample['TotalPowerNorm_Last'])

    const nc1FromAudio = messages.map((sample) => sample.calc_nc1_from_audio)
    const nc2FromAudio = messages.map((sample) => sample.calc_nc2_from_audio)

      setPlotStdAGC([
      createTrace(timesArray, stdAgc, 'Nc1 AGC', 'blue'),
      createTrace(timesArray, stdAgcLast, 'Nc2 AGC', 'red'),
    ])
    setPlotTotalPowerNormalize([
      createTrace(timesArray, totalPowerNorm, 'Nc1 Sensor', 'blue'),
      createTrace(timesArray, totalPowerNormLast, 'Nc2 Sensor', 'red'),
    ])
    setNcFromAudio([
      createTrace(timesArray, nc1FromAudio, 'Nc1 AE', 'blue'),
      createTrace(timesArray, nc2FromAudio, 'Nc2 AE', 'red'),
    ])
  }, [sensors.g5NoiseMsgs, samplesCount]);

  return (
    <>
      <IconButton
        sx={{
          position: 'absolute',
          top: '-15px',
          left: '50%',
          backgroundColor: 'white',
          padding: '3px 10px',
          borderRadius: '5px',
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 180)',
         },
        }}
        // className={classes.button}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <ArrowDownIcon/> : <ArrowUpIcon/>}
      </IconButton>

      <Collapse in={isOpen}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around'
          }}
        >
          <Plot
            data={plotStdAGC}
            layout={plotLayout}
          />
          <Plot
            data={plotTotalPowerNormalize}
            layout={plotLayout}
          />
          <Plot
            data={ncFromAudio}
            layout={{...plotLayout, width: 330 }}
          />
          <IntensityNoiseGraph
            data={noiseGraphData}
            sampleTime={sensors.g5NoiseMsgs[0] && sensors.g5NoiseMsgs[0].TimeStamp}
            timeZone={timeZone}
          />
        </Box>
      </Collapse>
    </>
  );
}

const useStyle = makeStyles({
  button: {
    position: 'absolute',
    top: '-24px',
    left: '47%',
    backgroundColor: 'white',
    padding: '3px',
    borderRadius: '5px',
  }
})

export default SensorInfoAdvanced;

/* eslint-disable react/no-multi-comp */
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';

import ConfCorrelation from '../Operations/ConfCorrelation';
import ConfSetAPN from '../Operations/ConfSetAPN';
import ConfSetLTE from '../Operations/ConfSetLTE';
import ConfUpdateVersion from '../Operations/ConfUpdateVersion';
import ConfigurationForm from '../Operations/ConfigurationForm';
import ControlButtons from './ControlButtons';

import { types } from '../../../constants/G5MessagesTypes';

import _ from 'lodash';
import UIDateFormater, {
  DateType,
} from '../../../containers/UIhelper/UIDateFormater';
import ConfSetServer from '../Operations/ConfSetServer';

import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { DEVICE_CONFIGURATION } from '../../../constants/ActionTypes';
import DigitalClock from '../../Custom/DigitalClock';

const MIN_FM_CHANNEL = 0;
const MAX_FM_CHANNEL = 10800;
const DEFAULT_FM_CHANNEL = 8800;
const useStyles = makeStyles({
  root: {
    // display: 'flex',
    flexGrow: 1,
    // flexWrap: 'nowrap'
  },
  section: {
    backgroundColor: '#fafafa',
    padding: '4px',
    border: '1px solid black',
    borderRadius: '5px',
    margin: '2px',
    // maxHeight: '82vh',
  },
});

function createFieldDefaultValue(defaults, defaultKey, field) {
  let result;
  if (defaults[defaultKey] != null) {
    if (defaultKey !== 'SchedulerRefTime') {
      result = {
        ...field,
        attr: { ...field.attr, defaultValue: defaults[defaultKey] },
      };
    } else {
      const refTimeMinutes = defaults[defaultKey] * 10 * 60 * 1000 || 1;
      const refTimeSeconds =
        (defaults.SchedulerRef5SecResolutionTime || 0) * (5 * 1000);
      const displayValue = UIDateFormater(
        refTimeMinutes + refTimeSeconds,
        DateType.TIME,
        'gmt'
      );
      result = { ...field, attr: { defaultValue: displayValue } };
    }
  } else {
    result = { ...field };
  }
  return result;
}

const tcpIpFlags = [
  { optionKey: 'MSG_DONTROUTE', label: 'DONTROUTE' },
  { optionKey: 'MSG_DONTWAIT', label: 'DONTWAIT' },
  { optionKey: 'MSG_WAITFORONE', label: 'WAITFORONE' },
  { optionKey: 'MSG_BATCH', label: 'BATCH' },
  { optionKey: 'MSG_FASTOPEN', label: 'FASTOPEN' },
];

const lteTimeoutOptions = [
  { optionKey: 45, value: 45, label: '45' },
  { optionKey: 60, value: 60, label: '60' },
  { optionKey: 90, value: 90, label: '90' },
  { optionKey: 120, value: 120, label: '120' },
  { optionKey: 150, value: 150, label: '150' },
  { optionKey: 180, value: 180, label: '180' },
  { optionKey: 210, value: 210, label: '210' },
  { optionKey: 360, value: 360, label: '360' },
];

const networkPacketSizeOptions = [
  { optionKey: 512, value: 512, label: '512' },
  { optionKey: 1024, value: 1024, label: '1024' },
  { optionKey: 2048, value: 2048, label: '2048' },
  { optionKey: 3072, value: 3072, label: '3072' },
  { optionKey: 4000, value: 4000, label: '4000' },
];

const correlationFieldsDisabled = false;

export default function CommandsLayout(props, context) {
  // console.log('fm2ConfigOptions', props.fm2ConfigOptions);
  const g5Servers = useSelector(
    (state) => state.leaksList.optionList.options.g5Servers
  );

  const initialFields = {
    Scheduler: [
      {
        id: 'refTime',
        title: context.t('ref_time'),
        type: 'time',
        attr: {
          defaultValue: '00:00',
          timeformat: '24h',
        },
      },
      {
        id: 'corrNumSamples',
        title: context.t('corr_num_samples'),
        type: 'number',
        attr: {
          defaultValue: 5,
        },
      },
      {
        id: 'corrInterval',
        title: context.t('corr_interval'),
        type: 'number',
      },
      {
        id: 'noiseNumSamples',
        title: context.t('noise_num_samples'),
        type: 'number',
      },
      {
        id: 'noiseInterval',
        title: context.t('noise_interval'),
        type: 'number',
      },
      {
        id: 'noiseTxTime',
        title: context.t('noise_tx_time'),
        type: 'number',
      },
      {
        id: 'wakeUpBeforRadio',
        title: context.t('wake_up_before_radio'),
        type: 'number',
      },
      {
        id: 'randomDelay',
        title: context.t('random_delay'),
        type: 'number',
        attr: {
          defaultValue: 0,
          min: 0,
          max: 20,
        },
      },
    ],
    Audio: [
      {
        id: 'samplingFreq',
        title: context.t('sampling_freq'),
        type: 'select',
        options: [
          { optionKey: 2000, label: '2000', disable: false },
          { optionKey: 4000, label: '4000', disable: false },
        ],
        attr: {
          defaultValue: 2000,
        },
      },
      {
        id: 'sampleDuration',
        title: context.t('sample_duration'),
        type: 'number',
      },
      // {
      //   id: 'reserved',
      //   title: context.t('Reserved'),
      //   type: 'number'
      // },
      {
        id: 'notchFilter',
        title: context.t('notch_filter'),
        type: 'number',
      },
      {
        id: 'compressEnabled',
        title: context.t('compress_enabled'),
        type: 'number',
      },
      {
        id: 'audioEnable',
        title: context.t('audio_enable'),
        type: 'number',
      },
    ],
    FmReceiver: [
      {
        id: 'channel10k',
        title: context.t('channel_10k'),
        type: 'number',
        attr: {
          defaultValue: DEFAULT_FM_CHANNEL,
          min: MIN_FM_CHANNEL,
          max: MAX_FM_CHANNEL,
        },
      },
      // {
      //   id: 'reginalTime',
      //   title: context.t('Reginal Time'),
      //   type: 'number'
      // },
      // {
      //   id: 'muteThreshold',
      //   title: context.t('Mute Threshold'),
      //   type: 'number'
      // },
      // {
      //   id: 'compressMode',
      //   title: context.t('Compress Mode'),
      //   type: 'number'
      // },
    ],
    FmReceiver2: [
      {
        id: 'channel10k',
        title: context.t('channel_10k'),
        type: 'number',
        attr: {
          defaultValue: DEFAULT_FM_CHANNEL,
          min: MIN_FM_CHANNEL,
          max: MAX_FM_CHANNEL,
        },
      },
      {
        id: 'conf',
        title: context.t('conf'),
        type: 'select',
        options: props.fm2ConfigOptions.map((opt) => ({
          optionKey: opt.code,
          label: opt.name,
        })),
      },
    ],
    Correlation: [
      {
        id: 'audioDuration',
        title: context.t('audio_duration'),
        type: 'number',
      },
      {
        id: 'radioDuration',
        title: context.t('radio_duration'),
        type: 'number',
      },
      {
        id: 'audioFS2N',
        title: context.t('audio_fs'),
        type: 'select',
        options: [
          { optionKey: 5, label: '5' },
          { optionKey: 10, label: '10' },
          { optionKey: 20, label: '20' },
          { optionKey: 40, label: '40' },
        ],
      },
      {
        id: 'samplingWindow',
        title: context.t('sampling_window'),
        type: 'number',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        },
      },
      {
        id: 'audioNumBands',
        title: context.t('audio_num_bands'),
        type: 'number',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        },
      },
      {
        id: 'audioNumCoff',
        title: context.t('audio_num_coff'),
        type: 'number',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        },
      },
      {
        id: 'audioBandMask1',
        title: context.t('audio_band_mask_1'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        },
      },
      {
        id: 'audioBandMask2',
        title: context.t('audio_band_mask_2'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        },
      },
      {
        id: 'audioBandMask3',
        title: context.t('audio_band_mask_3'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        },
      },
      {
        id: 'audioBandMask4',
        title: context.t('audio_band_mask_4'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        },
      },
      {
        id: 'radioNumBands',
        title: context.t('radio_num_bands'),
        type: 'number',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        },
      },
      {
        id: 'radioNumCoff',
        title: context.t('radio_num_coff'),
        type: 'number',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        },
      },
      {
        id: 'radioBandMask1',
        title: context.t('radio_band_mask_1'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        },
      },
      {
        id: 'radioBandMask2',
        title: context.t('radio_band_mask_2'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        },
      },
      {
        id: 'radioBandMask3',
        title: context.t('radio_band_mask_3'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        },
      },
      {
        id: 'radioBandMask4',
        title: context.t('radio_band_mask_4'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        },
      },
      {
        id: 'compressEnabled',
        title: context.t('compress_enabled'),
        type: 'number',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 0,
        },
      },
    ],
    FmScan: [1, 2, 3, 4, 5].map((x) => ({
      id: `chnl-${x}`,
      title: context.t('channel_num', { num: x }),
      type: 'number',
      attr: {
        defaultValue: DEFAULT_FM_CHANNEL,
        min: MIN_FM_CHANNEL,
        max: MAX_FM_CHANNEL,
      },
    })),
    UpdateVersion: [
      {
        id: 'fwVersion',
        title: context.t('version'),
        type: 'select',
        disabled: props.user.g5AqsAdmin,
        options: [],
      },
    ],
    UpdateModemVersion: [
      {
        id: 'fwVersion',
        title: context.t('version'),
        type: 'select',
        disabled: props.user.g5AqsAdmin,
        options: [],
      },
    ],
    LteMode: [
      {
        id: 'lteMode',
        title: context.t('mode'),
        type: 'select',
        disabled: props.user.g5AqsAdmin,
        options: [
          { optionKey: 0, label: context.t('cat_m_preferred'), disable: false },
          {
            optionKey: 1,
            label: context.t('nb_iot_preferred'),
            disable: false,
          },
          {
            optionKey: 2,
            label: context.t('cat_m_only'),
            disable: !props.advancedOptions,
          },
          {
            optionKey: 3,
            label: context.t('nb_iot_only'),
            disable: !props.advancedOptions,
          },
        ],
      },
    ],
    TimeZone: [
      {
        id: 'timeZone',
        title: context.t('zone'),
        type: 'select',
        options: [
          {
            optionKey: 'Dateline Standard Time',
            label: ' (UTC-12:00) International Date Line West',
          },
          {
            optionKey: 'UTC-11',
            label: ' (UTC-11:00) Coordinated Universal Time-11',
          },
          { optionKey: 'Hawaiian Standard Time', label: ' (UTC-10:00) Hawaii' },
          { optionKey: 'Alaskan Standard Time', label: ' (UTC-09:00) Alaska' },
          {
            optionKey: 'Pacific Standard Time (Mexico)',
            label: ' (UTC-08:00) Baja California',
          },
          {
            optionKey: 'Pacific Standard Time',
            label: ' (UTC-08:00) Pacific Time (US & Canada)',
          },
          {
            optionKey: 'US Mountain Standard Time',
            label: ' (UTC-07:00) Arizona',
          },
          {
            optionKey: 'Mountain Standard Time (Mexico)',
            label: ' (UTC-07:00) Chihuahua, La Paz, Mazatlan',
          },
          {
            optionKey: 'Mountain Standard Time',
            label: ' (UTC-07:00) Mountain Time (US & Canada)',
          },
          {
            optionKey: 'Central America Standard Time',
            label: ' (UTC-06:00) Central America',
          },
          {
            optionKey: 'Central Standard Time',
            label: ' (UTC-06:00) Central Time (US & Canada)',
          },
          {
            optionKey: 'Central Standard Time (Mexico)',
            label: ' (UTC-06:00) Guadalajara, Mexico City, Monterrey',
          },
          {
            optionKey: 'Canada Central Standard Time',
            label: ' (UTC-06:00) Saskatchewan',
          },
          {
            optionKey: 'SA Pacific Standard Time',
            label: ' (UTC-05:00) Bogota, Lima, Quito, Rio Branco',
          },
          {
            optionKey: 'Eastern Standard Time (Mexico)',
            label: ' (UTC-05:00) Chetumal',
          },
          {
            optionKey: 'Eastern Standard Time',
            label: ' (UTC-05:00) Eastern Time (US & Canada)',
          },
          {
            optionKey: 'US Eastern Standard Time',
            label: ' (UTC-05:00) Indiana (East)',
          },
          {
            optionKey: 'Venezuela Standard Time',
            label: ' (UTC-04:30) Caracas',
          },
          {
            optionKey: 'Paraguay Standard Time',
            label: ' (UTC-04:00) Asuncion',
          },
          {
            optionKey: 'Atlantic Standard Time',
            label: ' (UTC-04:00) Atlantic Time (Canada)',
          },
          {
            optionKey: 'Central Brazilian Standard Time',
            label: ' (UTC-04:00) Cuiaba',
          },
          {
            optionKey: 'SA Western Standard Time',
            label: ' (UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
          },
          {
            optionKey: 'Newfoundland Standard Time',
            label: ' (UTC-03:30) Newfoundland',
          },
          {
            optionKey: 'E. South America Standard Time',
            label: ' (UTC-03:00) Brasilia',
          },
          {
            optionKey: 'SA Eastern Standard Time',
            label: ' (UTC-03:00) Cayenne, Fortaleza',
          },
          {
            optionKey: 'Argentina Standard Time',
            label: ' (UTC-03:00) City of Buenos Aires',
          },
          {
            optionKey: 'Greenland Standard Time',
            label: ' (UTC-03:00) Greenland',
          },
          {
            optionKey: 'Montevideo Standard Time',
            label: ' (UTC-03:00) Montevideo',
          },
          { optionKey: 'Bahia Standard Time', label: ' (UTC-03:00) Salvador' },
          {
            optionKey: 'Pacific SA Standard Time',
            label: ' (UTC-03:00) Santiago',
          },
          {
            optionKey: 'UTC-02',
            label: ' (UTC-02:00) Coordinated Universal Time-02',
          },
          {
            optionKey: 'Mid-Atlantic Standard Time',
            label: ' (UTC-02:00) Mid-Atlantic - Old',
          },
          { optionKey: 'Azores Standard Time', label: ' (UTC-01:00) Azores' },
          {
            optionKey: 'Cape Verde Standard Time',
            label: ' (UTC-01:00) Cabo Verde Is.',
          },
          { optionKey: 'Morocco Standard Time', label: ' (UTC) Casablanca' },
          { optionKey: 'UTC', label: ' (UTC) Coordinated Universal Time' },
          {
            optionKey: 'GMT Standard Time',
            label: ' (UTC) Dublin, Edinburgh, Lisbon, London',
          },
          {
            optionKey: 'Greenwich Standard Time',
            label: ' (UTC) Monrovia, Reykjavik',
          },
          {
            optionKey: 'W. Europe Standard Time',
            label:
              ' (UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
          },
          {
            optionKey: 'Central Europe Standard Time',
            label:
              ' (UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
          },
          {
            optionKey: 'Romance Standard Time',
            label: ' (UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
          },
          {
            optionKey: 'Central European Standard Time',
            label: ' (UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
          },
          {
            optionKey: 'W. Central Africa Standard Time',
            label: ' (UTC+01:00) West Central Africa',
          },
          {
            optionKey: 'Namibia Standard Time',
            label: ' (UTC+01:00) Windhoek',
          },
          { optionKey: 'Jordan Standard Time', label: ' (UTC+02:00) Amman' },
          {
            optionKey: 'GTB Standard Time',
            label: ' (UTC+02:00) Athens, Bucharest',
          },
          {
            optionKey: 'Middle East Standard Time',
            label: ' (UTC+02:00) Beirut',
          },
          { optionKey: 'Egypt Standard Time', label: ' (UTC+02:00) Cairo' },
          { optionKey: 'Syria Standard Time', label: ' (UTC+02:00) Damascus' },
          {
            optionKey: 'E. Europe Standard Time',
            label: ' (UTC+02:00) E. Europe',
          },
          {
            optionKey: 'South Africa Standard Time',
            label: ' (UTC+02:00) Harare, Pretoria',
          },
          {
            optionKey: 'FLE Standard Time',
            label: ' (UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
          },
          { optionKey: 'Turkey Standard Time', label: ' (UTC+02:00) Istanbul' },
          {
            optionKey: 'Israel Standard Time',
            label: ' (UTC+02:00) Jerusalem',
          },
          {
            optionKey: 'Kaliningrad Standard Time',
            label: ' (UTC+02:00) Kaliningrad (RTZ 1)',
          },
          { optionKey: 'Libya Standard Time', label: ' (UTC+02:00) Tripoli' },
          { optionKey: 'Arabic Standard Time', label: ' (UTC+03:00) Baghdad' },
          {
            optionKey: 'Arab Standard Time',
            label: ' (UTC+03:00) Kuwait, Riyadh',
          },
          { optionKey: 'Belarus Standard Time', label: ' (UTC+03:00) Minsk' },
          {
            optionKey: 'Russian Standard Time',
            label: ' (UTC+03:00) Moscow, St. Petersburg, Volgograd (RTZ 2)',
          },
          {
            optionKey: 'E. Africa Standard Time',
            label: ' (UTC+03:00) Nairobi',
          },
          { optionKey: 'Iran Standard Time', label: ' (UTC+03:30) Tehran' },
          {
            optionKey: 'Arabian Standard Time',
            label: ' (UTC+04:00) Abu Dhabi, Muscat',
          },
          { optionKey: 'Azerbaijan Standard Time', label: ' (UTC+04:00) Baku' },
          {
            optionKey: 'Russia Time Zone 3',
            label: ' (UTC+04:00) Izhevsk, Samara (RTZ 3)',
          },
          {
            optionKey: 'Mauritius Standard Time',
            label: ' (UTC+04:00) Port Louis',
          },
          {
            optionKey: 'Georgian Standard Time',
            label: ' (UTC+04:00) Tbilisi',
          },
          {
            optionKey: 'Caucasus Standard Time',
            label: ' (UTC+04:00) Yerevan',
          },
          {
            optionKey: 'Afghanistan Standard Time',
            label: ' (UTC+04:30) Kabul',
          },
          {
            optionKey: 'West Asia Standard Time',
            label: ' (UTC+05:00) Ashgabat, Tashkent',
          },
          {
            optionKey: 'Ekaterinburg Standard Time',
            label: ' (UTC+05:00) Ekaterinburg (RTZ 4)',
          },
          {
            optionKey: 'Pakistan Standard Time',
            label: ' (UTC+05:00) Islamabad, Karachi',
          },
          {
            optionKey: 'India Standard Time',
            label: ' (UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
          },
          {
            optionKey: 'Sri Lanka Standard Time',
            label: ' (UTC+05:30) Sri Jayawardenepura',
          },
          { optionKey: 'Nepal Standard Time', label: ' (UTC+05:45) Kathmandu' },
          {
            optionKey: 'Central Asia Standard Time',
            label: ' (UTC+06:00) Astana',
          },
          {
            optionKey: 'Bangladesh Standard Time',
            label: ' (UTC+06:00) Dhaka',
          },
          {
            optionKey: 'N. Central Asia Standard Time',
            label: ' (UTC+06:00) Novosibirsk (RTZ 5)',
          },
          {
            optionKey: 'Myanmar Standard Time',
            label: ' (UTC+06:30) Yangon (Rangoon)',
          },
          {
            optionKey: 'SE Asia Standard Time',
            label: ' (UTC+07:00) Bangkok, Hanoi, Jakarta',
          },
          {
            optionKey: 'North Asia Standard Time',
            label: ' (UTC+07:00) Krasnoyarsk (RTZ 6)',
          },
          {
            optionKey: 'China Standard Time',
            label: ' (UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
          },
          {
            optionKey: 'North Asia East Standard Time',
            label: ' (UTC+08:00) Irkutsk (RTZ 7)',
          },
          {
            optionKey: 'Singapore Standard Time',
            label: ' (UTC+08:00) Kuala Lumpur, Singapore',
          },
          {
            optionKey: 'W. Australia Standard Time',
            label: ' (UTC+08:00) Perth',
          },
          { optionKey: 'Taipei Standard Time', label: ' (UTC+08:00) Taipei' },
          {
            optionKey: 'Ulaanbaatar Standard Time',
            label: ' (UTC+08:00) Ulaanbaatar',
          },
          {
            optionKey: 'North Korea Standard Time',
            label: ' (UTC+08:30) Pyongyang',
          },
          {
            optionKey: 'Tokyo Standard Time',
            label: ' (UTC+09:00) Osaka, Sapporo, Tokyo',
          },
          { optionKey: 'Korea Standard Time', label: ' (UTC+09:00) Seoul' },
          {
            optionKey: 'Yakutsk Standard Time',
            label: ' (UTC+09:00) Yakutsk (RTZ 8)',
          },
          {
            optionKey: 'Cen. Australia Standard Time',
            label: ' (UTC+09:30) Adelaide',
          },
          {
            optionKey: 'AUS Central Standard Time',
            label: ' (UTC+09:30) Darwin',
          },
          {
            optionKey: 'E. Australia Standard Time',
            label: ' (UTC+10:00) Brisbane',
          },
          {
            optionKey: 'AUS Eastern Standard Time',
            label: ' (UTC+10:00) Canberra, Melbourne, Sydney',
          },
          {
            optionKey: 'West Pacific Standard Time',
            label: ' (UTC+10:00) Guam, Port Moresby',
          },
          { optionKey: 'Tasmania Standard Time', label: ' (UTC+10:00) Hobart' },
          { optionKey: 'Magadan Standard Time', label: ' (UTC+10:00) Magadan' },
          {
            optionKey: 'Vladivostok Standard Time',
            label: ' (UTC+10:00) Vladivostok, Magadan (RTZ 9)',
          },
          {
            optionKey: 'Russia Time Zone 10',
            label: ' (UTC+11:00) Chokurdakh (RTZ 10)',
          },
          {
            optionKey: 'Central Pacific Standard Time',
            label: ' (UTC+11:00) Solomon Is., New Caledonia',
          },
          {
            optionKey: 'Russia Time Zone 11',
            label: ' (UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky (RTZ 11)',
          },
          {
            optionKey: 'New Zealand Standard Time',
            label: ' (UTC+12:00) Auckland, Wellington',
          },
          {
            optionKey: 'UTC+12',
            label: ' (UTC+12:00) Coordinated Universal Time+12',
          },
          { optionKey: 'Fiji Standard Time', label: ' (UTC+12:00) Fiji' },
          {
            optionKey: 'Kamchatka Standard Time',
            label: ' (UTC+12:00) Petropavlovsk-Kamchatsky - Old',
          },
          {
            optionKey: 'Tonga Standard Time',
            label: " (UTC+13:00) Nuku'alofa",
          },
          { optionKey: 'Samoa Standard Time', label: ' (UTC+13:00) Samoa' },
          {
            optionKey: 'Line Islands Standard Time',
            label: ' (UTC+14:00) Kiritimati Island',
          },
        ],
      },
    ],
    AgcConfig: [
      {
        id: 'agcConfigThreshold1',
        title: context.t('threshold_1'),
        type: 'number',
      },
      {
        id: 'agcConfigThreshold2',
        title: context.t('threshold_2'),
        type: 'number',
      },
      {
        id: 'agcConfigThreshold3',
        title: context.t('threshold_3'),
        type: 'number',
      },
      {
        id: 'agcConfigConf',
        title: context.t('agc_config'),
        type: 'select',
        options: [
          { optionKey: 0, label: context.t('auto_gain') },
          { optionKey: 1, label: context.t('gain_1') },
          { optionKey: 2, label: context.t('gain_2') },
          { optionKey: 3, label: context.t('gain_3') },
          { optionKey: 4, label: context.t('gain_4') },
        ],
      },
    ],
    TimeSrc: [
      {
        id: 'timeSrc',
        title: context.t('set_time_source'),
        type: 'select',
        options: [
          { optionKey: 0, label: context.t('tlv_server_time'), disable: false },
          { optionKey: 1, label: context.t('cellular_time'), disable: false },
        ],
      },
    ],
    SetAPN: [
      {
        id: 'setApn',
        title: context.t('apn'),
        type: 'select',
        disabled: !props.user.g5AqsAdmin,
        options: [],
      },
    ],
    LeakValveMonitor: [
      {
        id: 'baselineMagnitudeConstant',
        title: context.t('base_magnitude'),
        type: 'number',
        attr: {
          defaultValue: 1.0,
          min: 1.0,
          max: 5.0,
          step: 0.1,
        },
      },
      {
        id: 'eventMagnitudeConstant',
        title: context.t('event_magnitude_constant'),
        type: 'number',
        attr: {
          defaultValue: 1.0,
          min: 1.0,
          max: 5.0,
          step: 0.1,
        },
      },
      {
        id: 'magnitudeThreshold',
        title: context.t('magnitude_threshold'),
        type: 'number',
        attr: {
          defaultValue: 1.5,
          min: 1.0,
          max: 20,
          step: 0.1,
        },
      },
      {
        id: 'leakMonitorIntervalSec',
        title: context.t('leak_monitor_interval_sec'),
        type: 'number',
        attr: {
          defaultValue: 300,
          min: 60,
          max: 600,
        },
      },
      {
        id: 'leakMonitorAlertSuspendIntervalSec',
        title: context.t('leak_monitor_alert_suspend_interval_sec'),
        type: 'number',
        attr: {
          defaultValue: 1800,
          min: 180,
          max: 3600,
        },
      },
      {
        id: 'sampleFrequencyHZ',
        title: context.t('sample_frequency_hz'),
        type: 'number',
        attr: {
          defaultValue: 2000,
          min: 2000,
          max: 2000,
          disabled: true,
        },
      },
      {
        id: 'leakMonitorLongSampleSec',
        title: context.t('leak_monitor_long_sample_sec'),
        type: 'number',
        attr: {
          defaultValue: 20,
          min: 10,
          max: 30,
        },
      },
    ],
    SetServer: [
      {
        id: 'server',
        title: context.t('domain'),
        type: 'select',
        disabled: !props.user.g5AqsAdmin,
        options: g5Servers.map(({ id, display_name }) => ({
          optionKey: id,
          label: display_name,
          disable: false,
        })),
        // options: [
        //   { optionKey: 'tlv.aqs-sys.com', label: context.t('TLV'), disable: false },
        //   { optionKey: 'tlv1.aqs-sys.com', label: context.t('TLV 1'), disable: false },
        //   { optionKey: 'tlv2.aqs-sys.com', label: context.t('TLV 2'), disable: false },
        // ]
      },
    ],
    SetFallbackServer: [
      {
        id: 'server',
        title: context.t('domain'),
        type: 'select',
        disabled: !props.user.g5AqsAdmin,
        options: g5Servers
          .filter((item) => item.is_fallback)
          .map(({ id, display_name }) => ({
            optionKey: id,
            label: display_name,
            disable: false,
          })),
      },
    ],
    Capabilities: [
      ...[
        'adhoc_cor',
        'second_keepalive',
        'replace_lost_sample',
        'active_eDRX',
        'secure_BLE',
        'random_backoff_connect',
        'send_high_proirity_cor',
        'simulation_mode',
        'block_ota',
        'Comm_Process',
        'disable_audio_in_bit',
        'valve_leak_monitoring',
        'Clock_PPS',
        'rms_quiet',
        'total_std',
        'total_rms',
        'noise_mask',
        'disable_toolkit',
      ].map((key) => ({
        id: key,
        title: key.replaceAll('_', ' '),
        type: 'checkbox',
      })),
      {
        id: 'minimal_noise',
        title: 'minimal noise',
        type: 'select',
        options: [
          {
            optionKey: 'rms_quiet',
            label: 'rms quiet',
          },
          {
            optionKey: 'total_std',
            label: 'total std',
          },
          {
            optionKey: 'total_rms',
            label: 'total rms',
          },
          {
            optionKey: 'rms_quiet_wnd',
            label: 'rms quiet wnd',
          },
        ],
        attr: {
          defaultValue: 'rms_quiet',
        },
      },
    ],
    TcpIpConfiguration: [
      {
        id: 'catm_packet_size',
        title: 'catm_packet_size',
        type: 'select',
        options: networkPacketSizeOptions.map(
          ({ optionKey, label, value }) => ({
            optionKey: `catm-packet-size-${optionKey}`,
            label,
            value,
          })
        ),
        attr: {
          // min: 250,
          // max: 4000,
          defaultValue: 4000,
          placeholder: 'optimal 708, maximum 4000',
        },
      },
      {
        id: 'catm_tx_timeout',
        title: 'catm_tx_timeout',
        type: 'number',
        attr: {
          min: 5,
          max: 60,
          defaultValue: 60,
        },
      },
      {
        id: 'catm_rx_timeout',
        title: 'catm_rx_timeout',
        type: 'number',
        attr: {
          min: 5,
          max: 60,
          defaultValue: 30,
        },
      },
      ...tcpIpFlags.map((flag) => ({
        id: `catm_${flag.optionKey}`,
        title: `catm ${flag.label}`,
        type: 'checkbox',
      })),
      {
        id: 'nbiot_packet_size',
        title: 'nbiot_packet_size',
        type: 'select',
        options: networkPacketSizeOptions.map(
          ({ optionKey, label, value }) => ({
            optionKey: `nbiot-packet-size-${optionKey}`,
            label,
            value,
          })
        ),
        attr: {
          // min: 250,
          // max: 4000,
          defaultValue: 4000,
          placeholder: 'optimal 708, maximum 4000',
        },
      },
      {
        id: 'nbiot_tx_timeout',
        title: 'nbiot_tx_timeout',
        type: 'number',
        attr: {
          min: 5,
          max: 60,
          defaultValue: 60,
        },
      },
      {
        id: 'nbiot_rx_timeout',
        title: 'nbiot_rx_timeout',
        type: 'number',
        attr: {
          min: 5,
          max: 60,
          defaultValue: 30,
        },
      },
      ...tcpIpFlags.map((flag) => ({
        id: `nbiot_${flag.optionKey}`,
        title: `nbiot ${flag.label}`,
        type: 'checkbox',
      })),
    ],
    LTEConfiguration: [
      {
        id: 'short_catm_timeout',
        title: 'short catm timeout',
        type: 'select',
        options: lteTimeoutOptions.map(({ optionKey, label, value }) => ({
          optionKey: `short-catm-timeout-${optionKey}`,
          label,
          value,
        })),
        attr: {
          defaultValue: 90,
        },
      },
      {
        id: 'long_catm_timeout',
        title: 'long catm timeout',
        type: 'select',
        options: lteTimeoutOptions.map(({ optionKey, label, value }) => ({
          optionKey: `long-catm-timeout-${optionKey}`,
          label,
          value,
        })),
        attr: {
          defaultValue: 90,
        },
      },
      {
        id: 'short_nbiot_timeout',
        title: 'short nbiot timeout',
        type: 'select',
        options: lteTimeoutOptions.map(({ optionKey, label, value }) => ({
          optionKey: `short-nbiot-timeout-${optionKey}`,
          label,
          value,
        })),
        attr: {
          defaultValue: 90,
        },
      },
      {
        id: 'long_nbiot_timeout',
        title: 'long nbiot timeout',
        type: 'select',
        options: lteTimeoutOptions.map(({ optionKey, label, value }) => ({
          optionKey: `long-nbiot-timeout-${optionKey}`,
          label,
          value,
        })),
        attr: {
          defaultValue: 90,
        },
      },
    ],
  };

  React.useEffect(() => {
    if (props.user.g5Admin) {
      initialFields.UpdateVersion = [
        {
          id: 'fwVersion',
          title: context.t('version'),
          type: 'select',
          options:
            props.fwVersions &&
            props.fwVersions.map((versionName) => ({
              optionKey: versionName,
              label: versionName,
            })),
        },
      ];

      setVersion(initialFields.UpdateVersion);
    }
  }, [props.fwVersions]);

  React.useEffect(() => {
    if (props.user.g5Admin) {
      initialFields.UpdateModemVersion = [
        {
          id: 'fwModemVersion',
          title: context.t('modem_version'),
          type: 'select',
          options:
            props.fwModemVersions &&
            props.fwModemVersions.map((versionName) => ({
              optionKey: versionName,
              label: versionName,
            })),
        },
      ];

      setModemVersion(initialFields.UpdateModemVersion);
    }
  }, [props.fwModemVersions]);

  const [fieldsScheduler, setSchedulerFields] = React.useState(
    initialFields.Scheduler
  );
  const [fieldsAudio, setAudioFields] = React.useState(initialFields.Audio);
  const [fieldsFmReceiver, setFmReceiverFields] = React.useState(
    initialFields.FmReceiver
  );
  const [fieldsFm2Receiver, setFmReceiver2Fields] = React.useState(
    initialFields.FmReceiver2
  );
  const [fieldsCorrelation, setCorrelationFields] = React.useState(
    initialFields.Correlation
  );
  const [fieldsFmScan, setFmScanFields] = React.useState(initialFields.FmScan);
  const [fieldsVersion, setVersion] = React.useState([]);
  const [fieldsModemVersion, setModemVersion] = React.useState([]);
  const [fieldsTimeZone, setTimeZone] = React.useState(initialFields.TimeZone);

  const [fieldsSetApn, setSetApn] = React.useState(initialFields.SetAPN);
  const [fieldsSetServer, setSetServer] = React.useState(
    initialFields.SetServer
  );
  const [fieldsSetTimeSrc, setSetTimeSrc] = React.useState(
    initialFields.TimeSrc
  );
  const [fieldsSetAgcConfig, setSetAgcConfig] = React.useState(
    initialFields.AgcConfig
  );
  const [fieldsSetFallbackServer, setSetFallbackServer] = React.useState(
    initialFields.SetFallbackServer
  );
  const [tcpIpConfiguration, setTcpIpConfiguration] = React.useState(
    initialFields.TcpIpConfiguration
  );
  const [fieldsLTEConfig, setLTEConfig] = React.useState(
    initialFields.LTEConfiguration
  );
  const [fieldsSetLeakValveMonitor, setFieldsSetLeakValveMonitor] =
    React.useState(initialFields.LeakValveMonitor);

  const [defaultCorr, setDefaultCorr] = React.useState(null);

  const appDispatch = useDispatch();
  const defaultValues = useSelector(
    (state) => state.devcieConfigurations.defaultProjectsValues
  );

  React.useEffect(() => {
    const schedulerValues = [];
    const audioValues = [];
    const fmReceiverValues = [];
    const fmReceiver2Values = [];
    const correlationValues = [];
    const fmScanValues = [];
    const timeZoneValues = [];
    const lteModeValues = [];
    const agcConfig = [];
    const timeSource = [];
    const apn = [];
    const fallbackServer = [];
    const leakValveMonitorValues = [];
    // const fallbackServer = [
    //   {
    //     id: 'server',
    //     title: context.t('Domain'),
    //     type: 'select',
    //     disabled: !props.user.g5AqsAdmin,
    //     options: g5Servers.map(({ id, display_name }) => ({
    //       optionKey: id,
    //       label: display_name,
    //       disable: false
    //     }))
    //   }
    // ];

    const correlationDefaultValues = {};
    const isEmptyObject = (obj) => {
      return Object.keys(obj).every(
        (key) => obj[key] === undefined || obj[key] === null || obj[key] === ''
      );
    };
    Object.entries(initialFields).forEach((confFieldsEntry) => {
      const [type, fields] = confFieldsEntry;
      switch (type) {
        case 'Scheduler':
          fields.forEach((field) => {
            switch (field.id) {
              case 'refTime':
                schedulerValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'SchedulerRefTime',
                    field
                  )
                );
                break;
              case 'corrNumSamples':
                schedulerValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'SchedulerCorrNumSamples',
                    field
                  )
                );
                break;
              case 'corrInterval':
                schedulerValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'SchedulerCorrInterval',
                    field
                  )
                );
                break;
              case 'noiseNumSamples':
                schedulerValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'SchedulerNoiseNumSamples',
                    field
                  )
                );
                break;
              case 'noiseInterval':
                schedulerValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'SchedulerNoiseInterval',
                    field
                  )
                );
                break;
              case 'noiseTxTime':
                schedulerValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'SchedulerNoiseTxTime',
                    field
                  )
                );
                break;
              case 'wakeUpBeforRadio':
                schedulerValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'SchdulerWakeUpBeforeRadio_sec',
                    field
                  )
                );
                break;
              case 'randomDelay':
                schedulerValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'SchedulerRandomDelay',
                    field
                  )
                );
                break;
              default:
                schedulerValues.push(field);
                break;
            }
          });
          break;

        case 'Audio':
          fields.forEach((field) => {
            switch (field.id) {
              case 'samplingFreq':
                audioValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'AudioSamplingFrequency',
                    field
                  )
                );
                break;
              case 'sampleDuration':
                audioValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'AudioRecordingDurationSec',
                    field
                  )
                );
                break;
              case 'notchFilter':
                audioValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'AudioNotchFilter',
                    field
                  )
                );
                break;
              case 'compressEnabled':
                audioValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'AudioCompressionEnabled',
                    field
                  )
                );
                break;
              case 'audioEnable':
                audioValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'AudioReportEnabled',
                    field
                  )
                );
                break;
              default:
                audioValues.push(field);
                break;
            }
          });
          break;

        case 'FmReceiver':
          fields.forEach((field) => {
            switch (field.id) {
              case 'channel10k':
                fmReceiverValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'FmReceiverChannel10k',
                    field
                  )
                );
                break;
              default:
                fmReceiverValues.push(field);
                break;
            }
          });
          break;
        case 'FmReceiver2':
          fields.forEach((field) => {
            switch (field.id) {
              case 'channel10k':
                fmReceiver2Values.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'FmReceiverChannel2_10k',
                    field
                  )
                );
                break;
              case 'conf':
                fmReceiver2Values.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'fmReceiverChannel2_Config',
                    field
                  )
                );
                break;
              default:
                fmReceiver2Values.push(field);
                break;
            }
          });
          break;
        case 'Correlation':
          fields.forEach((field) => {
            switch (field.id) {
              case 'audioDuration':
                correlationValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'CorrelationAudioRecordingDurationSec',
                    field
                  )
                );
                correlationDefaultValues[field.id] =
                  defaultValues['CorrelationAudioRecordingDurationSec'];
                break;
              case 'radioDuration':
                correlationValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'CorrelationRadioRecordingDurationSec',
                    field
                  )
                );
                correlationDefaultValues[field.id] =
                  defaultValues['CorrelationRadioRecordingDurationSec'];
                break;
              case 'audioFS2N':
                correlationValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'CorrelationAudioSamplingFrequency',
                    field
                  )
                );
                correlationDefaultValues[field.id] =
                  defaultValues['CorrelationAudioSamplingFrequency'];
                break;
              case 'samplingWindow':
                correlationValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'CorrelationRadioAndAudioWindowSec',
                    field
                  )
                );
                correlationDefaultValues[field.id] =
                  defaultValues['CorrelationRadioAndAudioWindowSec'];
                break;
              case 'audioNumBands':
                correlationValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'CorrelationAudioNumFrequencyBands',
                    field
                  )
                );
                correlationDefaultValues[field.id] =
                  defaultValues['CorrelationAudioNumFrequencyBands'];
                break;
              case 'audioNumCoff':
                correlationValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'CorrelationAudioNumCoefficients',
                    field
                  )
                );
                correlationDefaultValues[field.id] =
                  defaultValues['CorrelationAudioNumCoefficients'];
                break;
              // case 'audioBandMask1': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              // case 'audioBandMask2': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              // case 'audioBandMask3': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              // case 'audioBandMask4': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              case 'radioNumBands':
                correlationValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'CorrelationRadioNumFrequencyBands',
                    field
                  )
                );
                correlationDefaultValues[field.id] =
                  defaultValues['CorrelationRadioNumFrequencyBands'];
                break;
              case 'radioNumCoff':
                correlationValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'CorrelationRadioNumCoefficients',
                    field
                  )
                );
                correlationDefaultValues[field.id] =
                  defaultValues['CorrelationRadioNumCoefficients'];
                break;
              // case 'radioBandMask1': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              // case 'radioBandMask2': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              // case 'radioBandMask3': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              // case 'radioBandMask4': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              case 'compressEnabled':
                correlationValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'CorrelationCompressionEnabled',
                    field
                  )
                );
                correlationDefaultValues[field.id] =
                  defaultValues['CorrelationCompressionEnabled'];
                break;
              default:
                correlationValues.push(field);
                break;
            }
          });
          break;
        case 'FmScan':
          fields.forEach((field) => {
            switch (field.id) {
              case 'chnl-1':
                fmScanValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'FmScanChannel1_10k',
                    field
                  )
                );
                break;
              case 'chnl-2':
                fmScanValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'FmScanChannel2_10k',
                    field
                  )
                );
                break;
              case 'chnl-3':
                fmScanValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'FmScanChannel3_10k',
                    field
                  )
                );
                break;
              case 'chnl-4':
                fmScanValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'FmScanChannel4_10k',
                    field
                  )
                );
                break;
              case 'chnl-5':
                fmScanValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'FmScanChannel5_10k',
                    field
                  )
                );
                break;
              default:
                fmScanValues.push(field);
                break;
            }
          });
          break;
        case 'TimeZone':
          fields.forEach((field) => {
            switch (field.id) {
              case 'timeZone':
                timeZoneValues.push(
                  createFieldDefaultValue(defaultValues, 'TimeZone', field)
                );
                break;
              default:
                timeZoneValues.push(field);
                break;
            }
          });
          break;
        case 'AgcConfig':
          fields.forEach((field) => {
            switch (field.id) {
              case 'agcConfigThreshold1':
                agcConfig.push(
                  createFieldDefaultValue(defaultValues, 'AGCThreshold1', field)
                );
                break;
              case 'agcConfigThreshold2':
                agcConfig.push(
                  createFieldDefaultValue(defaultValues, 'AGCThreshold2', field)
                );
                break;
              case 'agcConfigThreshold3':
                agcConfig.push(
                  createFieldDefaultValue(defaultValues, 'AGCThreshold3', field)
                );
                break;
              case 'agcConfigConf':
                agcConfig.push(
                  createFieldDefaultValue(defaultValues, 'AGC_Config', field)
                );
                break;
            }
          });
          break;
        case 'TimeSrc':
          fields.forEach((field) => {
            switch (field.id) {
              case 'timeSrc':
                timeSource.push(
                  createFieldDefaultValue(defaultValues, 'AGC_Config', field)
                );
                break;
            }
          });
          break;
        case 'SetAPN':
          const apnField = createFieldDefaultValue(
            defaultValues,
            'APN',
            initialFields['SetAPN'][0]
          );
          apnField.options = [
            {
              optionKey: defaultValues['APN'],
              label: defaultValues['APN'],
              disable: false,
            },
          ];
          apn.push(apnField);
          break;

        case 'LeakValveMonitor':
          fields.forEach((field) => {
            switch (field.id) {
              case 'baselineMagnitudeConstant':
                leakValveMonitorValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'SetLeakValveBaselineMagnitudeConstant',
                    field
                  )
                );
                break;
              case 'eventMagnitudeConstant':
                leakValveMonitorValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'SetLeakValveEventMagnitudeConstant',
                    field
                  )
                );
                break;
              case 'magnitudeThreshold':
                leakValveMonitorValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'SetLeakValveMagnitudeThreshold',
                    field
                  )
                );
                break;
              case 'leakMonitorIntervalSec':
                leakValveMonitorValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'SetLeakValveLeakMonitorIntervalSec',
                    field
                  )
                );
                break;
              case 'leakMonitorAlertSuspendIntervalSec':
                leakValveMonitorValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'SetLeakValveLeakMonitorAlertSuspendIntervalSec',
                    field
                  )
                );
                break;
              case 'sampleFrequencyHZ':
                leakValveMonitorValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'SetLeakValveSampleFrequencyHZ',
                    field
                  )
                );
                break;
              case 'leakMonitorLongSampleSec':
                leakValveMonitorValues.push(
                  createFieldDefaultValue(
                    defaultValues,
                    'SetLeakValveLeakMonitorLongSampleSec',
                    field
                  )
                );
                break;
              default:
                leakValveMonitorValues.push(field);
                break;
            }
          });
          break;

        case 'SetFallbackServer':
          fallbackServer.push(
            createFieldDefaultValue(
              defaultValues,
              'FallbackServer',
              initialFields.SetFallbackServer[0]
            )
          );
          break;

        default:
          break;
      }
    });
    // Object.entries(defaultValues).forEach((entry) => {
    //   const [key, value] = entry;
    //   switch (key) {
    //     case "SchedulerRefTime": schedulerValues.push(createFieldDefaultValue(initialFields.Scheduler, 'refTime', value)); break;
    //     case "SchedulerCorrNumSamples": schedulerValues.push(createFieldDefaultValue(initialFields.Scheduler, 'corrNumSamples',value)); break;
    //     case "SchedulerCorrInterval": schedulerValues.push(createFieldDefaultValue(initialFields.Scheduler, 'corrInterval',value)); break;
    //     case "SchedulerNoiseNumSamples": schedulerValues.push(createFieldDefaultValue(initialFields.Scheduler, 'noiseNumSamples',value)); break;
    //     case "SchedulerNoiseInterval": schedulerValues.push(createFieldDefaultValue(initialFields.Scheduler, 'noiseInterval',value)); break;
    //     case "SchedulerNoiseTxTime": schedulerValues.push(createFieldDefaultValue(initialFields.Scheduler, 'noiseTxTime',value)); break;

    //     case "AudioSamplingFrequency": audioValues.push(createFieldDefaultValue(initialFields.Audio, 'samplingFreq', value)); break;
    //     case "AudioRecordingDurationSec": audioValues.push(createFieldDefaultValue(initialFields.Audio, 'sampleDuration', value)); break;
    //     case "AudioNotchFilter": audioValues.push(createFieldDefaultValue(initialFields.Audio, 'notchFilter', value)); break;
    //     case "AudioCompressionEnabled": audioValues.push(createFieldDefaultValue(initialFields.Audio, 'compressEnabled', value)); break;
    //     case "AudioReportEnabled": audioValues.push(createFieldDefaultValue(initialFields.Audio, 'audioEnable', value)); break;

    //     case "CorrelationAudioRecordingDurationSec": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'audioDuration', value)); break;
    //     case "CorrelationRadioRecordingDurationSec": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'radioDuration', value)); break;
    //     case "CorrelationAudioSamplingFrequency": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'audioFS2N', value)); break;
    //     case "CorrelationAudioNumFrequencyBands": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'audioNumBands', value)); break;
    //     case "CorrelationRadioAndAudioWindowSec": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'samplingWindow', value)); break;
    //     case "CorrelationAudioNumCoefficients": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'audioNumCoff', value)); break;
    //     // case "CorrelationAudioIndexBandToSend": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, '', value)); break;
    //     case "CorrelationRadioNumFrequencyBands": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'radioNumBands', value)); break;
    //     case "CorrelationRadioNumCoefficients": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'radioNumCoff', value)); break;
    //     // case "CorrelationRadioIndexBandToSend": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, '', value)); break;
    //     case "CorrelationCompressionEnabled": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'compressEnabled', value)); break;

    //     case "FmReceiverChannel10k": fmReceiverValues.push(createFieldDefaultValue(initialFields.FmReceiver, 'channel10k', value)); break;
    //     // case "FmReceiverRegionallyConstTime": break;
    //     // case "FmReceiverMuteSNRThreshold": break;
    //     // case "FmReceiverCompressionModeStatus": break;
    //     case "TimeZone": break;

    //     case "FmScanChannel1_10k": fmScanValues.push(createFieldDefaultValue(initialFields.FmScan, 'chnl-1', value)); break;
    //     case "FmScanChannel2_10k": fmScanValues.push(createFieldDefaultValue(initialFields.FmScan, 'chnl-2', value)); break;
    //     case "FmScanChannel3_10k": fmScanValues.push(createFieldDefaultValue(initialFields.FmScan, 'chnl-3', value)); break;
    //     case "FmScanChannel4_10k": fmScanValues.push(createFieldDefaultValue(initialFields.FmScan, 'chnl-4', value)); break;
    //     case "FmScanChannel5_10k": fmScanValues.push(createFieldDefaultValue(initialFields.FmScan, 'chnl-5', value)); break;
    //   }
    // });

    setSchedulerFields(schedulerValues);
    setAudioFields(audioValues);
    setFmReceiverFields(fmReceiverValues);
    setFmReceiver2Fields(fmReceiver2Values);
    setCorrelationFields(correlationValues);
    setFmScanFields(fmScanValues);
    setTimeZone(timeZoneValues);
    setSetAgcConfig(agcConfig);
    setSetTimeSrc(timeSource);
    setSetApn(apn);
    setSetFallbackServer(fallbackServer);
    setFieldsSetLeakValveMonitor(leakValveMonitorValues);

    if (isEmptyObject(correlationDefaultValues)) {
      setDefaultCorr(correlationValues);
    } else {
      setDefaultCorr(correlationDefaultValues);
    }
  }, [defaultValues]);

  function handleGetProjectDefaults() {
    props.getDefault().then((json) => {
      if (json.status) {
        const defaultValues = json.data[0] || {};

        appDispatch({
          type: DEVICE_CONFIGURATION.SET_DEFAULT_PROJECT_VALUES,
          payload: defaultValues,
        });
      }
    });
  }

  const classes = useStyles();

  const TypographyTitle = (props) => (
    <Typography
      component={props.component || 'h1'}
      // align='center'
      style={{
        margin: 0,
        fontSize: props.fontSize || 'large',
        ...(props.style || {}),
      }}
    >
      {props.children}
    </Typography>
  );
  // console.log('props',props)
  return (
    <Grid
      container
      className={classes.root}
      justify='center'
      direction={'row'}
      wrap='nowrap'
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          top: '95px',
          left: '630px',
          border: 'solid 1px #ccc',
          padding: '7px',
          borderRadius: '5px',
        }}
      >
        <TypographyTitle
          component='span'
          fontSize='medium'
          style={{ margin: '0px', marginRight: '10px' }}
        >
          {context.t('utc_time')}
        </TypographyTitle>
        <DigitalClock />
      </div>

      <Grid item>
        <Grid container direction='column'>
          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('reset')}</TypographyTitle>
            <Button
              variant='contained'
              color='inherit'
              onClick={handleGetProjectDefaults}
            >
              {context.t('get_project_defaults')}
            </Button>
            {/* <button onClick={handleGetProjectDefaults}>Get Project Defaults</button> */}
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('scheduler')}</TypographyTitle>
            <ConfigurationForm
              notify={props.notify}
              type={types.Scheduler}
              fields={fieldsScheduler}
              enable={props.enable && props.user.g5Admin}
              advancedOptions={props.user.g5Admin}
              updateConfig={(values) =>
                props.updateConfig(values, types.Scheduler, props.notify)
              }
              onSaveDefault={(values) =>
                props.updateDefaultConfig(values, types.Scheduler, props.notify)
              }
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('audio')}</TypographyTitle>
            <ConfigurationForm
              notify={props.notify}
              fields={fieldsAudio}
              enable={props.enable && props.user.g5Admin}
              advancedOptions={props.user.g5Admin}
              updateConfig={(values) =>
                props.updateConfig(values, types.Audio, props.notify)
              }
              onSaveDefault={(values) =>
                props.updateDefaultConfig(values, types.Audio, props.notify)
              }
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('fm_receiver')}</TypographyTitle>
            <ConfigurationForm
              notify={props.notify}
              fields={fieldsFmReceiver}
              enable={props.enable && props.user.g5Admin}
              advancedOptions={props.user.g5Admin}
              updateConfig={(values) =>
                props.updateConfig(values, types.FmReceiver, props.notify)
              }
              onSaveDefault={(values) =>
                props.updateDefaultConfig(
                  values,
                  types.FmReceiver,
                  props.notify
                )
              }
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('fm2_receiver')}</TypographyTitle>
            <ConfigurationForm
              notify={props.notify}
              fields={fieldsFm2Receiver}
              enable={props.enable && props.user.g5Admin}
              advancedOptions={props.user.g5Admin}
              updateConfig={(values) =>
                props.updateConfig(values, types.FmReceiver2, props.notify)
              }
              onSaveDefault={(values) =>
                props.updateDefaultConfig(
                  values,
                  types.FmReceiver2,
                  props.notify
                )
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction='column'>
          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('correlation')}</TypographyTitle>
            <ConfCorrelation
              fields={fieldsCorrelation}
              defaultsValues={defaultCorr}
              enable={props.enable && props.user.g5Admin}
              advancedOptions={props.user.g5Admin}
              updateConfig={(values) =>
                props.updateConfig(values, types.Correlation, props.notify)
              }
              onSaveDefault={(values) =>
                props.updateDefaultConfig(
                  values,
                  types.Correlation,
                  props.notify
                )
              }
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('time_zone')}</TypographyTitle>
            <ConfigurationForm
              notify={props.notify}
              fields={fieldsTimeZone}
              enable={props.enable && props.user.g5Admin}
              advancedOptions={props.user.g5Admin}
              updateConfig={(values) =>
                props.updateConfig(values, types.TimeZone, props.notify)
              }
              onSaveDefault={(values) =>
                props.updateDefaultConfig(values, types.TimeZone, props.notify)
              }
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('adhoc_correlation')}</TypographyTitle>
            <ConfigurationForm
              notify={props.notify}
              fields={[
                {
                  id: 'adhoc_datetime',
                  title: 'Date And Time',
                  type: 'datetime-local',
                },
                {
                  id: 'adhoc_number_of_correlation',
                  title: 'Number Of Correlation',
                  type: 'number',
                  attr: {
                    min: 0,
                  },
                },
                {
                  id: 'adhoc_interval',
                  title: 'Interval',
                  type: 'number',
                  attr: {
                    min: 0,
                  },
                },
                {
                  id: 'adhoc_wakeup_before_radio',
                  title: 'Wakeup Before Radio',
                  type: 'number',
                  attr: {
                    min: 0,
                  },
                },
              ]}
              enable={props.enable && props.user.g5Admin}
              updateConfig={(values) => {
                const bodyValues = {
                  ...values,
                  adhoc_datetime: new Date(
                    values['adhoc_datetime']
                  ).toISOString(),
                };
                // console.log('bodyValues', bodyValues);
                props.updateConfig(
                  bodyValues,
                  types.Adhoc_Correlation,
                  props.notify
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction='column'>
          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('control_buttons')}</TypographyTitle>
            <ControlButtons
              user={props.user}
              enable={props.enable && props.user.g5Admin}
              advancedOptions={props.user.g5Admin}
              sendCommand={(command) =>
                props.updateConfig(null, command, props.notify)
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction='column'>
          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('set_lte_mode')}</TypographyTitle>
            <ConfSetLTE
              countSelected={props.selectedItems.length}
              enable={props.enable && props.user.g5AqsAdmin}
              advancedOptions={props.user.g5AqsAdmin}
              updateConfig={(values) =>
                props.updateConfig(values, types.SetLTEMode, props.notify)
              }
              onSaveDefault={(values) =>
                props.updateDefaultConfig(
                  values,
                  types.SetLTEMode,
                  props.notify
                )
              }
            />
          </Grid>

          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('set_fm_scan')}</TypographyTitle>
            <ConfigurationForm
              notify={props.notify}
              fields={fieldsFmScan}
              enable={props.enable && props.user.g5AqsAdmin}
              advancedOptions={props.user.g5AqsAdmin}
              updateConfig={(values) =>
                props.updateConfig(values, types.SetFmScan, props.notify)
              }
              onSaveDefault={(values) =>
                props.updateDefaultConfig(values, types.SetFmScan, props.notify)
              }
            />
          </Grid>

          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('update_version')}</TypographyTitle>
            <ConfUpdateVersion
              type='fwVersion'
              countSelected={props.selectedItems.length}
              fields={fieldsVersion}
              enable={props.enable && props.user.g5AqsAdmin}
              updateConfig={(values) =>
                props.updateConfig(values, types.FirmwareUpdate, props.notify)
              }
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>
              {context.t('update_modem_version')}
            </TypographyTitle>
            <ConfUpdateVersion
              type='fwModemVersion'
              countSelected={props.selectedItems.length}
              fields={fieldsModemVersion}
              enable={props.enable && props.user.g5AqsAdmin}
              updateConfig={(values) =>
                props.updateConfig(
                  values,
                  types.ModemFirmwareUpdate,
                  props.notify
                )
              }
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('set_apn')}</TypographyTitle>
            <ConfSetAPN
              countSelected={props.selectedItems.length}
              fields={fieldsSetApn}
              enable={props.enable && props.user.g5AqsAdmin}
              updateConfig={(values) =>
                props.updateConfig(values, types.Cmd_SetApn, props.notify)
              }
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>
              {context.t('set_leak_valve_monitor')}
            </TypographyTitle>
            <ConfigurationForm
              notify={props.notify}
              type={types.SetLeakValveMonitor}
              fields={fieldsSetLeakValveMonitor}
              enable={props.enable && props.user.g5AqsAdmin}
              advancedOptions={props.user.g5AqsAdmin}
              updateConfig={(values) =>
                props.updateConfig(
                  values,
                  types.SetLeakValveMonitor,
                  props.notify
                )
              }
              onSaveDefault={(values) =>
                props.updateDefaultConfig(
                  values,
                  types.SetLeakValveMonitor,
                  props.notify
                )
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction='column'>
          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('set_time_source')}</TypographyTitle>
            <ConfigurationForm
              notify={props.notify}
              fields={fieldsSetTimeSrc}
              enable={props.enable && props.user.g5AqsAdmin}
              updateConfig={(values) =>
                props.updateConfig(
                  values,
                  types.Cmd_SetTimeSourcePref,
                  props.notify
                )
              }
            />
          </Grid>

          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('agc_config')}</TypographyTitle>
            <ConfigurationForm
              notify={props.notify}
              fields={fieldsSetAgcConfig}
              enable={props.enable && props.user.g5AqsAdmin}
              updateConfig={(values) =>
                props.updateConfig(values, types.Cmd_SetAGCConfig, props.notify)
              }
            />
          </Grid>

          <Grid item className={classes.section}>
            <TypographyTitle>{context.t('set_server')}</TypographyTitle>
            <ConfSetServer
              countSelected={props.selectedItems.length}
              fields={fieldsSetServer}
              enable={props.enable && props.user.g5AqsAdmin}
              updateConfig={(values) =>
                props.updateConfig(values, types.SetServer, props.notify)
              }
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>
              {context.t('set_secondary_server')}
            </TypographyTitle>
            {/* <ConfSetServer
              countSelected={props.selectedItems.length}
              fields={fieldsSetFallbackServer}
              enable={props.enable && props.user.g5AqsAdmin}
              updateConfig={(values) => props.updateConfig(values, types.SET_FALLBACK_SERVER, props.notify)}
            /> */}
            <ConfigurationForm
              notify={props.notify}
              fields={fieldsSetFallbackServer}
              enable={props.enable && props.user.g5AqsAdmin}
              advancedOptions={props.user.g5AqsAdmin}
              updateConfig={(values) =>
                props.updateConfig(
                  values,
                  types.SET_FALLBACK_SERVER,
                  props.notify
                )
              }
              onSaveDefault={(values) =>
                props.updateDefaultConfig(
                  values,
                  types.SET_FALLBACK_SERVER,
                  props.notify
                )
              }
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>
              {context.t('set_lte_configuration')}
            </TypographyTitle>
            <ConfigurationForm
              notify={props.notify}
              fields={fieldsLTEConfig}
              enable={props.enable && props.user.g5AqsAdmin}
              updateConfig={(values) =>
                props.updateConfig(
                  values,
                  types.SetLTEConnectionTimeout,
                  props.notify
                )
              }
              // onSaveDefault={(values) => props.updateDefaultConfig(values, types.SET_FALLBACK_SERVER, props.notify)}
            />
          </Grid>
        </Grid>
      </Grid>

      {props.user.g5AqsAdmin && (
        <Grid item>
          <Grid container direction='column'>
            <Grid item className={classes.section}>
              <TypographyTitle>{context.t('set_capability')}</TypographyTitle>
              <ConfigurationForm
                notify={props.notify}
                fields={initialFields.Capabilities}
                enable={props.enable && props.user.g5AqsAdmin}
                // advancedOptions={props.user.g5Admin}
                // onSaveDefault={(values) => props.updateDefaultConfig(values, types.SetFmScan, props.notify)}
                updateConfig={(values) => {
                  const finalValues = _.pickBy(values, (val) => val === true);
                  const selections = Object.keys(finalValues);
                  const minNoise = values['minimal_noise'];
                  props.updateConfig(
                    { selections, minNoise },
                    types.SET_CAPABILITY_REGISTER,
                    props.notify
                  );
                }}
              />
            </Grid>

            <Grid item className={classes.section}>
              <TypographyTitle>{context.t('set_tpcip_config')}</TypographyTitle>
              <ConfigurationForm
                notify={props.notify}
                fields={tcpIpConfiguration}
                enable={props.enable && props.user.g5AqsAdmin}
                // advancedOptions={props.user.g5Admin}
                // onSaveDefault={(values) => props.updateDefaultConfig(values, types.SetFmScan, props.notify)}
                // updateConfig={(values) => console.log(values)}
                updateConfig={(values) =>
                  props.updateConfig(
                    values,
                    types.TPC_IP_PACKET_CONFIGURATION,
                    props.notify
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

CommandsLayout.contextTypes = {
  t: PropTypes.func.isRequired,
};

CommandsLayout.propTypes = {
  enable: PropTypes.bool,
  fwVersions: PropTypes.arrayOf('string').isRequired,
  fwModemVersions: PropTypes.arrayOf('string').isRequired,
  getDefault: PropTypes.func.isRequired,
  notify: PropTypes.func,
  sendActionCommand: PropTypes.func,
  timeZone: PropTypes.object,
  updateConfig: PropTypes.func.isRequired,
  updateDefaultConfig: PropTypes.func.isRequired,
  user: PropTypes.object,
  fm2ConfigOptions: PropTypes.arrayOf('object').isRequired,
};

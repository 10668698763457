import { browserHistory } from 'react-router';
import { featuresTypes } from '../components/Map/Layers/Options';
import * as types from '../constants/ActionTypes';
import { handlePathChangeEvent } from "./actions";
import * as actionsAlerts from './AlertsActions';
import valveAlertsActions from './AlertsValveActions';
import * as actionsCouples from './CouplesActions';
import * as actionsCouplesCoverage from "./CouplesCoverageActions";
import * as actionsDMAs from './DmasActions';
import * as actionsInspect from './InspectActions';
import * as installActions from './InstallActions';
import * as actionsInterferences from './InterferencesActions';
import * as actionsMobileSamples from './MobileSamplesActions';
import * as actionsNoiseAlerts from './NoiseAlertsActions';
import * as actionsProjects from './ProjectsActions';
import * as actionsPressureAlerts from './PrsTransientAlertsActions';
import * as actionsSensors from './SensorsActions';
import { setFieldText } from "./setters";
import * as actionsSOPs from './SOPsActions';

const changeToPath = (path, projectId, dispatch) => {
  const pathParams = {
    pathname: path,
    query: { project: projectId },
    state: { fromMap: true }, // flag for not clear the selected item on the reducer.
  };

  browserHistory.push(pathParams);

  dispatch(handlePathChangeEvent(projectId));
};

export const handleLayerChange = (layerName, isSelected) => {
  const layerChangeAction = {
    type: types.ON_MAP_LAYER_CHANGE,
    layerName,
    isSelected,
  };
  if (isSelected && layerName == featuresTypes.COUPLES_COVERAGE) {
    return (dispatch, getState) => {
      const state = getState();
      const selectedProjectId = state.leaksList.selectedProject;
      const coupleCoverageData = state.leaksList.leaksByProject[selectedProjectId].couplesCoveragePaths;
      if (coupleCoverageData.isFetching == true
        || (coupleCoverageData != null
          && coupleCoverageData.paths != null
          && coupleCoverageData.paths.length > 0)) {
        dispatch(layerChangeAction);
      } else {
        dispatch(actionsCouplesCoverage.FetchCouplesCoveragePathsIfNeeded(true));
      }
    };
  } else {
    return (layerChangeAction);
  }
};

export const saveDrawnFeature = (points) => {
  return {
    type: types.SAVE_DRAWN_FEATURE,
    points,
  };
};

export const handleMapClick = (event, latLng) => {
  return (dispatch, getState) => {
    const state = getState();

  }
};

export const handleFeatureClick = (item) => {
  return (dispatch, getState) => {
    const state = getState();
    const path = state.routing.locationBeforeTransitions.pathname.replace("/", "") || 'alerts';
    const installContext = state.install.installContext;
    const selectedProject = state.leaksList.selectedProject;
    const projectState = state.leaksList.leaksByProject[selectedProject];

    switch (item.type) {
      case featuresTypes.PRESSURE_ALERT: {
        const alertsContext = state.leaksList.pressure.context;
        const prsAlerts = state.leaksList.pressure.transientAlerts.alerts;
        const indexMap = state.leaksList.pressure.transientAlerts.indexMap;
        const selectedPrsAlert = prsAlerts[indexMap[item.id]];
        if (path != 'alerts') {
          changeToPath('alerts', selectedProject, dispatch);
        }

        if (alertsContext !== 'prsAlerts') {
          dispatch(actionsPressureAlerts.changeAlertsContext("prsAlerts"));
        }

        dispatch(actionsPressureAlerts.selectPressureAlert(selectedPrsAlert));
        break;
      }
      case featuresTypes.ALERT: {
        const alertsContext = state.leaksList.pressure.context;
        if (path != 'alerts') {
          changeToPath('alerts', selectedProject, dispatch);
        }

        if (alertsContext !== 'alerts') {
          dispatch(actionsPressureAlerts.changeAlertsContext("alerts"));
        }

        const alerts = projectState.items;
        const indexMap = projectState.indexMap;
        const alert = alerts[indexMap[item.id]];
        dispatch(actionsAlerts.selectAlert(alert));
        break;
      }

      case featuresTypes.NOISE_ALERT: {
        const alertsContext = state.leaksList.pressure.context;

        if (path != 'alerts') {
          changeToPath('alerts', selectedProject, dispatch);
        }

        //TODO lia look here if valve status doesnt get updated cause sth is missing

        if (alertsContext !== 'noise') {
          dispatch(actionsPressureAlerts.changeAlertsContext("noise"));
        }

        const items = state.noiseAlerts.items;
        const selectedItem = items.find((itrItem) => itrItem.ID === item.id);
        if (selectedItem) {
          dispatch(actionsNoiseAlerts.selectNoiseAlert(selectedItem));
        } else {
          // console.log('not found noise alert with id:', item.id);
        }
        break;
      }

      case featuresTypes.VALVE_ALERT: {
        const alertsContext = state.leaksList.pressure.context;

        if (path !== 'alerts') {
          changeToPath('alerts', selectedProject, dispatch);
        }

        if (alertsContext !== 'valve') {
          dispatch(actionsPressureAlerts.changeAlertsContext('valve'));
        }

        const valveAlerts = state.valve.valveAlerts;
        const selectedValveAlert = valveAlerts.find((vAlert) => vAlert.id === item.id);
        if (selectedValveAlert) {
          dispatch(valveAlertsActions.selectValveAlert(selectedValveAlert));
        }
        break;
      }

      case featuresTypes.SENSOR_ON_COUPLE:
      case featuresTypes.SENSOR: {
        const sensors = projectState.sensors;
        const sensor = sensors.sensors[sensors.indexMap[item.id]];

        if (path != 'install' && path != 'sensors') {
          // click on sensor change the path to 'sensors'
          changeToPath('sensors', selectedProject, dispatch);
        } else {
          if (path == 'install' && installContext != 'Sensors') {
            // click on sensor in 'install mode' change the install context to 'Sensors'
            dispatch(installActions.changeContext('Sensors'));
          }
        }
        dispatch(actionsSensors.selectSensor(sensor));
        break;
      }

      case featuresTypes.COUPLES_COVERAGE:
      case featuresTypes.COUPLE_PATH: {
        const couples = projectState.couples;
        const couple = couples.couples[couples.indexMap[item.id]];

        if (path != 'couples') {
          changeToPath('couples', selectedProject, dispatch);
        }

        dispatch(actionsCouples.selectCoupleOrAssessment(couple));
        break;
      }

      case featuresTypes.ASSESSMENT: {
        const selectedCoupleId = (state.leaksList.leaksByProject.selectedFeatureType == 'couple') ? state.leaksList.leaksByProject.selectedFeature : null;
        if (selectedCoupleId != null) {
          const assessments = projectState.assessments[selectedCoupleId];
          const assessment = assessments.assessments[assessments.indexMap[item.id]];
          dispatch(actionsCouples.selectCoupleOrAssessment(assessment));
        }
        break;
      }

      case featuresTypes.SOP: {
        const sops = projectState.sops;
        const sop = sops.sops[sops.indexMap[item.id]];

        if (installContext != 'SOPs') {
          dispatch(installActions.changeContext('SOPs'));
        }
        if (path != 'install') {
          changeToPath('install', selectedProject, dispatch);
        }

        dispatch(actionsSOPs.selectSOP(sop));

        break;
      }

      case featuresTypes.INTERFERENCE: {
        // chenge the path and 'installContext' if needed for change the main table to Interferences Table:
        if (path != 'install') {
          changeToPath('install', selectedProject, dispatch);
        }
        if (installContext != 'Interferences') {
          dispatch(installActions.changeContext('Interferences'));
        }

        dispatch(actionsInterferences.selectInterference(item));
        break;
      }

      case featuresTypes.PROJECTS_COVERAGE: {
        const projects = state.leaksList.projectsList.items;
        const projectsIndexMap = state.leaksList.projectsList.projectIndexMap;
        const selectedProjectFromMap = projects[projectsIndexMap[item.id]];

        dispatch(actionsProjects.selectProject(selectedProjectFromMap.ID));
        dispatch(handleLayerChange(featuresTypes.PROJECTS_COVERAGE, false));
        break;
      }

      case featuresTypes.DMAS: {
        dispatch(actionsDMAs.getDmaData(item.id));
        dispatch(actionsDMAs.setDisplayDmaData(true));
        break;
      }

      case featuresTypes.INSPECT_ALERTS: {
        dispatch(actionsInspect.selectInspectAlert(item));
        break;
      }

      case featuresTypes.MOBILE_SAMPLES: {
        const { items, indexMap } = state.mobile.samples;
        const sample = items.find((s) => s.sample_uid === item.id);

        if (sample == null) {
          console.error('sample not found');
        } else {
          dispatch(actionsMobileSamples.setSelectedSample(sample));
        }

        break;
      }

      default:
        break;
    }
  };
};

// export const handleMapInterferenceCreating = (LatLang, feature) => {
//   return (dispatch, getState) => {
//     const newInstallItem = getState().install.newInstallItem;
//     if(newInstallItem == null || newInstallItem.polygon == null){
//       let point = LatLang;
//       if (feature != null) {
//         point = feature.geometry.getCoordinates();
//         console.log(feature);
//       }
//       // Interference Polygon Box - Radius 50 meters
//       const margin = 50;

//       let polygonPoints = [
//         [point[0] + margin, point[1]],
//         [point[0], point[1] + margin],
//         [point[0] - margin, point[1]],
//         [point[0], point[1] - margin],
//       ];

//       dispatch(setInterferencePolygonPoints(polygonPoints));
//     }
//   };
// };

const setInterferencePolygonPoints = (points) => {
  return {
    type: types.SET_INTERFERENCE_POLYGON_POINTS,
    points,
  };
};

const setProjectPolygonPoints = (points) => {
  return {
    type: types.SET_PROJECT_POLYGON_POINTS,
    points,
  };
};

export const onFeatureDrawned = (featureLngLatPoints) => {
  return (dispatch, getState) => {
    //console.log(feature.getGeometry().getCoordinates());
    const state = getState();
    const path = state.routing.locationBeforeTransitions.pathname.replace("/", "");

    switch (path) {
      case '':
      case 'alerts': {
        const tempState = state.temp;
        const lngLatAsArray = featureLngLatPoints[0];

        if (tempState.newManualAlert != null) {
          dispatch(saveDrawnFeature(featureLngLatPoints));
          // drawn feature in Alerts mode is 'new alert'
          // set the 'add-alert' form 'Coordinate' Field with the values:
          dispatch(setFieldText('add-alert', 'Coordinate', `${lngLatAsArray[1]}, ${lngLatAsArray[0]}`));
        } else if (tempState.actualLeakPosition.isOpen) {
          const coordinateStr = lngLatAsArray[1] + ' , ' + lngLatAsArray[0];
          dispatch(setFieldText('actual-leak-position', 'Coordinate', coordinateStr));
          dispatch(saveDrawnFeature(featureLngLatPoints));
        }
        break;
      }
      case 'install': {
        const installContext = state.install.installContext;
        if (installContext == 'Interferences') {
          if (featureLngLatPoints.length > 0) {
            // console.log("featureLngLatPoints: " + featureLngLatPoints);
            dispatch(setInterferencePolygonPoints(featureLngLatPoints));
          }
        }
        break;
      }
      case 'manage': {
        if (state.manage.managementContext === 'Projects')
          if (featureLngLatPoints.length > 0) {
            // console.log("featureLngLatPoints: " + featureLngLatPoints);
            dispatch(setProjectPolygonPoints(featureLngLatPoints));
            // dispatch(saveDrawnFeature(featureLngLatPoints));
          }
      }
    }
  };
};

import React from 'react';

import AlertsTabs from "../AlertsTabs/AlertsTabs";
import AlertsPressureTabs from "../AlertsPressureTabs/AlertsPressureTabs";
import SensorsTabs from "../SensorsTabs/SensorsTabs";
import CouplesTabs from "../CouplesTabs/CouplesTabs";
import ProjectsTabs from "../ProjectsTabs/ProjectsTabs";
import UsersTabs from "../UsersTabs/UsersTabs";
import NoiseAlertsTabs from "../AlertsNoiseTabs/NoiseAlertsTabs";
import CustomersTabs from "../CustomersTabs/CustomersTabs";
import SOPsTabs from "../SOP/SOPsTabs";
import InterferencesTabs from "../Interferences/InterferencesTabs";
import AlertsValveTabs from "../AlertsValveTabs/AlertsValveTabs";
import iQuariusSamplesTable from "../iQuariusTable/iQuariusSamplesTable";
import MobileSamplesTabs from '../Mobile/MobileTabs';
import MobileDevicesTabs from '../Mobile/MobileDevicesTabs';
require('fixed-data-table-2/dist/fixed-data-table.css');
require('./Tabs.scss');

export default class TabsSwitch extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var Tabs;
    var items;
    switch (this.props.path) {
      case 'alerts':
        switch (this.props.alertsContextMenu) {
          case 'alerts':
            Tabs = AlertsTabs;
            items = this.props.leaks;
            break;

          case 'prsAlerts':
            Tabs = AlertsPressureTabs;
            items = [];// this.props.leaks;
            break;

          case 'noise':
            Tabs = NoiseAlertsTabs;
            items = this.props.items;
            break;

          case 'valve':
            Tabs = AlertsValveTabs;
            items = this.props.items;
            break;

          default:
            break;
        }
        break;

      case 'sensors':
        Tabs = SensorsTabs;
        items = this.props.sensors ? this.props.sensors.sensors || [] : [];
        break;

      case 'couples':
        Tabs = CouplesTabs;
        items = this.props.couples ? this.props.couples.couples || [] : [];
        break;

      case 'mobile': {
        switch (this.props.modeState) {
          case 'Tasks':
            Tabs = MobileSamplesTabs;
            items = this.props.samples;
            break;
          case 'Devices':
            Tabs = MobileDevicesTabs;
            items = this.props.samples;
            break;
        }
        break;
      }

      case 'manage':
        switch (this.props.managementContext) {
          case 'Customers':
            Tabs = CustomersTabs;
            items = this.props.customers || [];
            break;
          case 'Projects':
            Tabs = ProjectsTabs;
            items = this.props.projects || [];
            break;
          case 'Users':
            Tabs = UsersTabs;
            items = this.props.users || [];
            break;
          default:
            break;
        }
        break;
      case 'install':
        if (this.props.installContext == 'SOPs') {
            Tabs = SOPsTabs;
        }
        else if (this.props.installContext == 'Sensors') {
          Tabs = SensorsTabs;
        }
        else if(this.props.installContext == 'Interferences'){
          Tabs = InterferencesTabs;
        }
        break;
      case 'iQuarius':
        Tabs = iQuariusSamplesTable;
        items = this.props.iQuariusSamples || [];
        break;
      default:
        break;
    }

    if (Tabs != null) {
      return (
        <Tabs
          items={items}
          {...this.props}
        />
      );
    } else {
      return null;
    }
  }

}

////@TODO write props types...
//TabsSwitch.propTypes = {
//  handleRowClick: PropTypes.func.isRequired
//};

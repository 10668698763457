import React from 'react';
import Plot from 'react-plotly.js';
import UIDateFormater, { DateType } from '../../containers/UIhelper/UIDateFormater'

const MAX_SHORT_VALUE = (2**16) - 1;

const getDate = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  return `${day}/${month}`
}

const getTime = (date) => {
  const hour = date.getHours();
  const minutes = date.getMinutes();
  return `${getValueAsTimeValue(hour)}:${getValueAsTimeValue(minutes)}`
}

const getValueAsTimeValue = (value) => {
  if (String(value).length === 1) {
    return `0${value}`;
  }
  return String(value);
}

const IntensityNoiseGraph = ({ data, sampleTime, timeZone }) => {
  const [plotData, setPlotData] = React.useState();
  const [plotXValues, setPlotXValues] = React.useState([]);
  const [layout, setLayout] = React.useState({});
  const plotRef = React.useRef();

  React.useEffect(() => {
    setPlotData(undefined);
    setPlotXValues([]);

    if (data?.length > 0) {
      const xValues = [];
      const graphDataSet = [];

      const tmpOnlyCurrentDayData = [data[0]];

      tmpOnlyCurrentDayData.forEach((itrData, ndx) => {
        const x = [];
        const y = [];

        if (itrData?.samples_data) {
          const timeZoneOffset = timeZone === 'local' ? 0 : new Date().getTimezoneOffset() * 60 * 1000;
          const intervalMillis = itrData.interval_samples * 60 * 1000;
          itrData.samples_data
          .split(',')
          .slice(0, itrData.num_samples)
          .forEach((val, index) => {
            const time = new Date(sampleTime + timeZoneOffset - ((index + 1) * intervalMillis));
            const yValue = Number(val);
            x.push(time);
            y.push(yValue === (MAX_SHORT_VALUE) ? NaN : yValue);

            if (ndx === 0) { // only in first iteration:
              xValues.push(time)
            }
          });
          const finalPlotData = {
            x,
            y,
            mode: 'lines+markers',
            type: 'scatter',
            name: getDate(new Date(itrData.TimeStamp)),
            line: {
              color: 'blue'
            },
            marker: {
              color: 'rgba(255, 255, 255, 100)',
              size: 7,
              line: {
                color: 'blue',
                width: 1
              }
            }
          };
          graphDataSet.push(finalPlotData);
        }

        if (itrData?.min_selected_ndx !== null && itrData.min_selected_ndx !== -1) {
          graphDataSet.push({
            x: [x[itrData.min_selected_ndx]],
            y: [y[itrData.min_selected_ndx]],
            mode: 'markers',
            type: 'scatter',
            marker: { size: 12 },
            name: `min ${getDate(new Date(itrData.TimeStamp))}`
          })
        }
        setPlotData(graphDataSet);
        setPlotXValues(xValues);
      })
    }
  }, [data, timeZone]);

  React.useEffect(() => {
    if (plotXValues) {
      const ticks = plotXValues.map((date) => UIDateFormater(date.getTime(), DateType.TIME, timeZone));
      // console.log('ticks', ticks);

      setLayout({
        autosize: false,
        width: 340,
        height: 230,
        margin: { l: 50, r: 30, b: 50, t: 30 },
        xaxis: {
          // autorange: 'reversed',
          range: [plotXValues.slice(-1)[0], plotXValues[0]],
          // tickmode: 'array',
          // tickvals: plotXValues,
          ticktext: ticks,
          title: {
            text: ('Time'),
            font: {
              color: '#8d8d8d'
            }
          }
        },
        yaxis: {
          title: {
            text: 'Noise Intensity Distribution',
            font: {
              color: '#8d8d8d'
            }
          }
        }
      })
    }
  }, [plotXValues, timeZone]);
  return (
    <Plot
      ref={plotRef}
      data={plotData}
      layout={layout}
    />
  )
}

export default IntensityNoiseGraph;

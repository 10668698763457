import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import WorkOrderForm from '../../components/WorkOrderModal/WorkOrderForm';
import UIDateFormater, {DateType} from "../UIhelper/UIDateFormater";

const mapStateToProps = (state, ownProps) => {
  const units = state.local.units;
  const distanceFactor = state.local.factor[units];
  const mainState = state.leaksList;
  const options = mainState.optionList.options;
  const projectId = mainState.selectedProject;
  const selectedProject = getSelectedProject(projectId, mainState.projectsList);

  const contextType = state.leaksList.pressure.context;
  const selectedAlertId = contextType === 'noise' ? state.noiseAlerts.selected.id : mainState.leaksByProject.selectedFeature;
  const selectedAlert = contextType === 'noise' ? state.noiseAlerts.selected : getSelectedAlert(selectedAlertId, mainState.leaksByProject[projectId]);

  const selectedAlertType = getOption(selectedAlert, options, 'AlertType');
  const selectedAlertStatus = getOption(selectedAlert, options, 'AlertState');
  const selectedAlertComment = selectedAlert.Comment;
  const SensorID = selectedAlert.SensorID;
  const createDate = selectedAlert.CreateDate != null ? UIDateFormater(selectedAlert.CreateDate, DateType.DATE) : '';
  const InsertDate = selectedAlert.InsertDate != null ? UIDateFormater(selectedAlert.InsertDate, DateType.DATE) : '';

  const initialValues = Object.assign({}, selectedAlert, {
    projectName: selectedProject.ShortName,
    alertTypeName: selectedAlertType,
    alertStateName: selectedAlertStatus,
    serviceProviderOptions: options.ContractorID,
    sensorID: SensorID,
    comment: selectedAlertComment,
    CreateDate: createDate,
    InsertDate: InsertDate,

  });

  return { initialValues, units, distanceFactor, selectedAlertId, selectedAlert, contextType, ...ownProps };
};

//const mapDispatchToProps = (dispatch, ownProps) => {
//  return {};
//};

const getSelectedProject = (id, projects) => {
  const map = projects.projectIndexMap;
  const project = projects.items[map[id]];
  return (project);
}

const getSelectedAlert = (id, projectState) => {
  const map = projectState.indexMap;
  const alert = projectState.items[map[id]];
  //const alert = projectState.details[id].data;
  return Object.assign({}, alert, projectState.details[id].data);
}

const getOption = (item, options, optionName) => {
  let opt = '';
  const optionsList = options[optionName];
  for (let i = 0; i < optionsList.length; i++) {
    if (item[optionName] == optionsList[i].value) {
      opt = optionsList[i].label;
      break;
    }
  }
  return (opt);
}

const CWorkOrderForm = connect(
  mapStateToProps,
  null
)(WorkOrderForm);

export default CWorkOrderForm

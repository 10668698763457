// import React from 'react';
import * as types from '../constants/ActionTypes';
import { END_POINT } from '../constants/EndPoints';
import { setSelectedCoupleSample } from './CouplesActions';
import { fetchMiddleware, setIndexes } from './MiddlewareActions';
import { fetchSensorNC } from './SensorsActions';

export function fetchNoiseAlerts(projectId) {

  return (dispatch, getState) => {
    const state = getState();
    const filters = state.leaksList.filters.noiseAlerts.filters;
    const url = `${END_POINT}/noise/${projectId}`;
    const filtersQuery =
      filters.length > 0 ? `?filters=${JSON.stringify(filters)}` : '';
    dispatch(requestNoiseAlerts(projectId));

    return fetchMiddleware(url + filtersQuery, {}, getState).then((json) => {
      if (!json.status) {
        return false;
      } else {
        const noiseAlertStatesOptions =
          state.leaksList.optionList.options.AlertStateNoise;
        const noiseAlerts = json.data;

        if (noiseAlertStatesOptions) {
          noiseAlerts.forEach((noiseAlert) => {
            const alertState = noiseAlertStatesOptions.find(
              (opt) => opt.value === noiseAlert.AlertState
            );
            const name = alertState ? alertState.DisplayName : '--';
            noiseAlert.AlertStateName = name;
          });
        }

        const sortedAlert = state.noiseAlerts.sortBy;

        let sortedNoiseAlerts = [];

        if (sortedAlert && sortedAlert.field && sortedAlert.dir) {
          sortedNoiseAlerts = [...noiseAlerts].sort((a, b) => {
            if (sortedAlert.dir.toLowerCase() === 'asc') {
              return a[sortedAlert.field] > b[sortedAlert.field] ? 1 : -1;
            } else if (sortedAlert.dir.toLowerCase() === 'desc') {
              return a[sortedAlert.field] < b[sortedAlert.field] ? 1 : -1;
            }
            return 0;
          });

        }
        let alarmItems = sortedNoiseAlerts.length ? sortedNoiseAlerts : noiseAlerts;

        const indexMap = setIndexes(sortedNoiseAlerts, 'ID');
        const payload = {
          items: alarmItems,
          indexMap,
        };
        return dispatch(receivetNoiseAlerts(projectId, payload));
      }
    });
  };
}

const requestNoiseAlerts = (projectId) => ({
  type: types.REQUEST_NOISE_ALERTS,
  project: projectId,
});
const receivetNoiseAlerts = (projectId, payload) => ({
  type: types.RECEIVE_NOISE_ALERTS,
  project: projectId,
  payload,
});

export function selectNoiseAlert(noiseAlert) {
  return (dispatch, getState) => {
    const state = getState();
    let selectedAlertId;

    if (noiseAlert == null) {
      selectedAlertId = '';
    } else {
      selectedAlertId = noiseAlert.ID;
    }

    if (!selectedAlertId) {
      return dispatch({ type: types.SELECT_NOISE_ALERT, payload: noiseAlert });
    } else {
      const projectId = state.leaksList.selectedProject;

      let noiseAlertItem; // when updating noiseAlert from drop down column of Noise Alerts Table - there is no 'SensorID' value.
      if (noiseAlert.SensorID) {
        noiseAlertItem = noiseAlert;
      } else {
        noiseAlertItem =
          state.noiseAlerts.items[state.noiseAlerts.indexMap[noiseAlert.ID]];
      }

      dispatch(setSelectedCoupleSample({}));
      dispatch({ type: types.SELECT_NOISE_ALERT, payload: noiseAlertItem });
      dispatch(fetchAlertHistory(selectedAlertId));

      return dispatch(
        fetchSensorNC(projectId, noiseAlertItem.SensorID, null, 0)
      );
    }
  };
}

export function updateNoiseAlert(noiseAlert) {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;

    const url = `${END_POINT}/noise/${projectId}/${noiseAlert.ID}`;
    return fetchMiddleware(
      url,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(noiseAlert),
      },
      getState
    ).then((json) => {
      if (json.status) {
        const state = getState();
        const projectId = state.leaksList.selectedProject;

        dispatch(fetchNoiseAlerts(projectId)).then((data) => {
          if (data.payload && data.payload.indexMap) {
            if (data.payload.indexMap[noiseAlert.ID] !== undefined) {
              dispatch(selectNoiseAlert(noiseAlert));
            }
          }
        });
      }
    });
  };
}

export function sortNoiseAlerts(field, dir) {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const noiseAlerts = state.noiseAlerts.items;

    const sortedItems = noiseAlerts.sort((first, second) => {
      const valueA =
        field === 'NoisePriority' ? first[field] || 'z' : first[field];
      const valueB =
        field === 'NoisePriority' ? second[field] || 'z' : second[field];

      let sortResult = 0;

      if (valueA > valueB) {
        sortResult = dir === 'asc' ? 1 : -1;
      } else if (valueA < valueB) {
        sortResult = dir === 'asc' ? -1 : 1;
      }
      return sortResult;
    });
    const newIndexMap = setIndexes(sortedItems, 'ID');
    const payload = {
      items: sortedItems,
      indexMap: newIndexMap,
    };

    dispatch(receivetNoiseAlerts(projectId, payload));
    dispatch(setSortDetails(field, dir));
  };
}

function setSortDetails(field, dir) {
  return {
    type: types.NOISE_ALERTS_SET_SORT_PROPS,
    payload: { field, dir },
  };
}

export function setAddingNewManualNoiseAlertMode(bIsInMode) {
  return {
    type: types.SET_ADDING_MANUAL_NOISE_ALERTS_MODE,
    bIsInMode,
  };
}

export function selectSensorForNoiseAlert(sensor) {
  return {
    type: types.SELECT_SENSOR_FOR_NEW_NOISE_ALERT,
    payload: sensor.id,
  };
}

export function createNewNoiseAlert(values, notify) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedSensorId =
      state.temp.newManualNoiseAlert && state.temp.newManualNoiseAlert.sensorId;
    if (!selectedSensorId) {
      notify('Please select a Sensor', 'error');
    } else {
      const url = `${END_POINT}/noise/${selectedSensorId}`;
      const options = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      };

      fetchMiddleware(url, options, getState).then((json) => {
        if (json.status) {
          const projectId = state.leaksList.selectedProject;

          dispatch(setAddingNewManualNoiseAlertMode(false));
          dispatch(fetchNoiseAlerts(projectId));

          notify('Success', 'success');
        } else {
          notify(JSON.stringify(json), 'error');
        }
      });
    }
  };
}

export function setFilters(filters) {
  return {
    type: types.SET_NOISE_ALERTS_FILTERS,
    filters,
  };
}

export function setDefFilters(filters) {
  return {
    type: types.SET_NOISE_ALERTS_DEF_FILTERS,
    filters,
  };
}

function fetchAlertHistory(alertId) {
  return (dispatch, getState) => {
    const url = `${END_POINT}/noise/history/${alertId}`;
    dispatch(requestAlertHistory());
    return fetchMiddleware(url, {}, getState).then((json) => {
      if (json.status) {
        dispatch(receiveAlertHistory(json.data));
      }
    });
  };
}

function requestAlertHistory() {
  return { type: types.REQUEST_NOISE_ALERTS_HISTORY };
}

function receiveAlertHistory(history) {
  return { type: types.RECEIVE_NOISE_ALERTS_HISTORY, payload: history };
}

export const notify = (distributionListId, mapImageBase64, cb) => {
  return async (dispatch, getState) => {
    const state = getState();
    let workOrderValues = {};
    let img;
    const originalHeight = 816;
    const originalWidth = 1137;
    const resizeFactor = parseFloat(process.env.REACT_APP_MAP_RESIZE_FACTOR || 0.7);

    delete workOrderValues.serviceProviderOptions;
    debugger;
    if (mapImageBase64) {
      let maxWidth = Math.round(originalWidth * resizeFactor);
      let maxHeight = Math.round(originalHeight * resizeFactor);
      img = await resizeBase64Image(mapImageBase64, maxWidth, maxHeight);
    }

    workOrderValues.mapImageBase64 = img;

    workOrderValues = Object.assign(
      workOrderValues,
      state.form.workorder.values
    );
    const selectedServiceProvider = workOrderValues.serviceProviderOptions.find(
      (sp) => sp.value === workOrderValues.serviceProvider
    );

    if (selectedServiceProvider != null) {
      workOrderValues.serviceProviderName = selectedServiceProvider.label;
    }

    delete workOrderValues.serviceProviderOptions;

    const alertId = state.noiseAlerts.selected.ID;
    const path = `${END_POINT}/noise/notify/${alertId}/${distributionListId}`;
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...workOrderValues }),
    };
    return fetchMiddleware(path, options, getState).then((json) => {
      if (json.status) {
        cb(null);
      } else {
        cb(json.data);
      }
    });
  };
};

function resizeBase64Image(base64, maxW, maxH) {
  return new Promise((resolve) => {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    // var input = document.getElementById('input');
    // var output = document.getElementById('file_output');
    // input.addEventListener('change', handleFiles);

    var img = new Image();
    img.onload = function () {
      var iw = img.width;
      var ih = img.height;
      var scale = Math.min(maxW / iw, maxH / ih);
      var iwScaled = iw * scale;
      var ihScaled = ih * scale;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
      const value = canvas.toDataURL('image/jpeg', 0.5);

      resolve(value);
    };
    img.src = base64;
  });
}

import PropTypes from 'prop-types';
import React from 'react';

import { Cell, Column, Table } from 'fixed-data-table-2';
import TextCell from '../../../components/TextCell/TextCell';
import { DateType } from '../../../containers/UIhelper/UIDateFormater';

import ReactPaginate from 'react-paginate';

const _ = require('lodash');

require('../SensorInfo/SensorInfo.scss');

class SensorSamplesTab extends React.Component {
  constructor(props) {
    super(props);

    this.handlePageClick = this.handlePageClick.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.rowClassNameGetter = this.rowClassNameGetter.bind(this);

    this.state = {
      currentPage: 1,
      rowForScrolling: null,
    };
  }

  onRowClick(event, rowIndex) {
    this.props.selectSensorSample(this.props.sensorsSamples[rowIndex]);
  }

  rowClassNameGetter(rowIndex) {
    const { selectedSensorSample, sensorsSamples } = this.props;
    return selectedSensorSample != null &&
      sensorsSamples[rowIndex] &&
      selectedSensorSample.ID == sensorsSamples[rowIndex].ID
      ? 'active-row'
      : '';
  }

  handlePageClick(event) {
    const self = this;
    this.setState(
      {
        currentPage: event.selected,
        rowForScrolling: 0, // scroll to top on changing pager number
      },
      () => {
        self.setState({
          rowForScrolling: null, // release the scrolling...
        });
      }
    );
    this.props.onPageIndexChange(event.selected, 'SensorSamplesTab');
  }

  render() {
    const {
      sensorsSamples,
      totalNoiseSamplesCount,
      noiseSamplesRequestedCount,
      uiInfo,
      timeZone,
      downloadFile,
    } = this.props;

    let pWidth = 0.395 * (uiInfo.pageWidth - 10);
    let pHeight = 0.494 * uiInfo.pageHeight - 8;

    const data =
      sensorsSamples != null && !_.isEmpty(sensorsSamples)
        ? sensorsSamples
        : [];

    return (
      <div>
        <Table
          rowsCount={data.length}
          width={pWidth}
          scrollToRow={this.state.rowForScrolling}
          onRowClick={this.onRowClick}
          rowClassNameGetter={this.rowClassNameGetter}
          maxHeight={pHeight - 117}
          headerHeight={46}
          rowHeight={30}
        >
          <Column
            columnKey='SampleTime'
            header={<Cell>{this.context.t('sample_time')}</Cell>}
            cell={
              <TextCell
                data={data}
                field='SampleTime'
                additional={timeZone}
                dateType={DateType.DATE_AND_TIME}
              />
            }
            width={60}
            flexGrow={3}
          />
          <Column
            columnKey='Nc1'
            header={
              <Cell
                title={this.context.t('minimal_noise_sampling_taken_by_the_sensor_prior_to_audio_recording')}
              >
                {this.context.t('min_noise')}
              </Cell>
            }
            cell={<TextCell data={data} field='Nc1' />}
            width={30}
            flexGrow={2}
            align='center'
          />
          <Column
            columnKey='Nc2'
            header={
              <Cell
                title={this.context.t('Noise sampling taken by the sensor during audio recording')}
              >
                {this.context.t('sample_noise')}
              </Cell>
            }
            cell={<TextCell data={data} field='Nc2' />}
            width={30}
            flexGrow={2}
            align='center'
          />
          <Column
            columnKey='Nc3'
            header={
              <Cell
                title={this.context.t('noise_sampling_taken_by_the_sensor_during_audio_recording')}
              >
                {this.context.t('filtered_noise')}
              </Cell>
            }
            cell={<TextCell data={data} field='Nc3' />}
            width={30}
            flexGrow={2}
            align='center'
          />

          <Column
            columnKey='Duration'
            header={
              <Cell title={this.context.t('duration')}>
                {this.context.t('duration_sec')}
              </Cell>
            }
            cell={<TextCell data={data} field='Duration' />}
            width={30}
            flexGrow={2}
            align='center'
          />

          <Column
            columnKey='nc3_gain'
            header={
              <Cell title={this.context.t('')}>{this.context.t('gain')}</Cell>
            }
            cell={<TextCell data={data} field='nc3_gain' />}
            width={30}
            flexGrow={2}
            align='center'
          />

          <Column
            columnKey='Audio'
            header={<Cell>{this.context.t('download')}</Cell>}
            cell={(props) => (
              <Cell {...props}>
                {data[props.rowIndex].FileName && (
                  <a
                    key='Audio'
                    className='btn-wav-download'
                    onClick={() => {
                      downloadFile(data[props.rowIndex].FileName, 'file');
                    }}
                    href='javascript:void(0);'
                  >
                    {this.context.t('audio')}
                  </a>
                )}
              </Cell>
            )}
            width={30}
            flexGrow={2}
          />
        </Table>

        <div className='center'>
          <ReactPaginate
            previousLabel={this.context.t('prev')}
            nextLabel={this.context.t('next')}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={Math.ceil(
              totalNoiseSamplesCount / noiseSamplesRequestedCount
            )}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            onPageActive={this.state.currentPage}
          />
        </div>
      </div>
    );
  }
}

SensorSamplesTab.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SensorSamplesTab;
